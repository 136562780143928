/**
 * Utility functions for dealing with detection data.
 */

/**
 * @description Retrieves the detection data for a given location and time range.
 * @param locationId The ID of the location whose data is to be retrieved.
 * @param startDate The start date of the data to be retrieved.
 * @param endDate The end date of the data to be retrieved.
 * @returns
 */
export async function getDetectionData(
  locationId: number,
  startDate: string,
  endDate: string
) {
  const url = `${process.env.REACT_APP_API_URL}/detection/get_full`;
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      location_id: locationId,
      start_date: startDate,
      end_date: endDate,
    }),
  });
  const data = await res.json();
  return data;
}

/**
 * @description Filters the detection data for a given location and time range.
 * @param data The detection data to be filtered.
 * @param isDriveThru Whether or not the data should be filtered for drive-thru or walk-in.
 * @param minTimeSpent The minimum time spent to be included in the filtered data.
 * @param maxTimeSpent The maximum time spent to be included in the filtered data.
 * @param modelIdsToExclude The model IDs to be excluded from the filtered data.
 * @returns
 */
export function filterDetections(
  data: any,
  isDriveThru: boolean = true,
  minTimeSpent: number = 30,
  maxTimeSpent: number = 2000,
  modelIdsToExclude: number[] = [-7]
) {
  return data.filter(
    (x) =>
      x.time_spent >= minTimeSpent &&
      x.time_spent <= maxTimeSpent &&
      x.is_drive_through === isDriveThru &&
      !modelIdsToExclude.includes(x.model_id)
  );
}

/**
 * @description Joins detection data with region data.
 * @param detections The detections to be joined with regions.
 * @param regions The regions to be joined with detections.
 * @returns
 */
export function joinDetectionAndRegions(detections: any, regions: any) {
  const joined = detections.map((detection: any) => {
    const regionMatches = regions.filter(
      (region: any) => region.detection_id === detection.id
    );

    let newDetection = {
      id: detection.id,
      first_seen_datetime_utc: detection.first_seen_datetime_utc,
      time_spent: detection.time_spent,
      valid_time_spent: detection.valid_time_spent,
    };

    regionMatches.forEach((region: any) => {
      newDetection[region.region] = region.time_spent;
    });

    return newDetection;
  });
  return sortData(joined, 'first_seen_datetime_utc');
}

/**
 * @description Sorts the data by a given key.
 * @param data The data to be sorted.
 * @param sortKey The key to sort the data by.
 * @returns The sorted data.
 */
export function sortData(data: any, sortKey: string) {
  const sorted = data.sort((a: any, b: any) => {
    if (a[sortKey] > b[sortKey]) {
      return -1;
    }
    return 1;
  });

  return sorted;
}

/**
 * @description Filters the joined detection and region data for a given region.
 * @param regions The regions to be filtered.
 * @param regionName The name of the region to be filtered for.
 * @returns
 */

export function filterRegions(
  regions: any,
  regionNames: string[] = [
    'order',
    'leave',
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    'customer_area',
    'order_area',
    'line_area',
  ]
) {
  return regions.filter((region: any) => regionNames.includes(region.region));
}
