/**
 * Utility functions for getting location data.
 */
import app from '../firebase';

/**
 * @description Gets the cusomter's location data.
 *
 * @returns {Promise} A promise that resolves to the customer's location data.
 */
export async function getCustomerLocationData(uid, cacheKey, getCacheData) {
  if (!uid || !cacheKey || !getCacheData) {
    return;
  }
  const url =
    'https://api-iaily4lvlq-uc.a.run.app/webapp2/get_customer_locations_data';
  // Try fetching the data from the cache.
  if (getCacheData) {
    const cacheData = getCacheData(cacheKey);
    if (cacheData) {
      return cacheData;
    }
  }

  // Fetch the data from the cloud.
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ uid: uid }),
  });
  const data = await res.json();
  return data;
}

/**
 * @description Gets the list of franchises based on the customer's location data.
 *
 * @param {Object} locationData The customer's location data.
 *
 * @returns {Array} The list of franchises based on the customer's location data.
 */
export function getFranchises(locationData) {
  if (!locationData) {
    return [];
  }
  console.log('locationData', locationData);
  let franchises = locationData.map((location) => location.franchise);
  // Remove franchises with duplicate ID's.
  let ids = [];
  franchises = franchises.filter((franchise) => {
    if (ids.includes(franchise.id)) {
      return false;
    }
    ids.push(franchise.id);
    return true;
  });
  // Sort the franchises.
  franchises.sort((a, b) => a.id - b.id);
  return franchises;
}

/**
 * @description Gets the cache key for the customer's location data.
 *
 * @returns {string} The cache key for the customer's location data.
 */
export function getCacheKey() {
  return 'customerLocationsData';
}

/**
 * @description Gets the customer's location preferences.
 * @param locationId
 * @param isDriveThru
 * @returns
 */
export async function getLocationPreferences(
  locationId: number,
  isDriveThru: boolean
) {
  const db = app.firestore();
  const doc = await db.collection('location').doc(locationId.toString()).get();

  if (!doc.exists) {
    return;
  }

  const data = doc.data();

  let returnData = {};

  if (isDriveThru) {
    returnData['regionPreferences'] = data['drive_thru_regions2'];
  } else {
    returnData['regionPreferences'] = data['lobby_regions2'];
  }

  // Set region display prefrences if they exist.
  if (Object.keys(data).includes('drive_thru_region_displays')) {
    returnData['regionDisplays'] = data['drive_thru_region_displays'];
  }

  return returnData;
}
