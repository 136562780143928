/**
 * This widget takes all the comparison locations, and shows a bar chart
 * for each location (on the x axis) with grouped bars (one bar for each period)
 * for their total average servivce time
 *
 * This widget has no further selections to be made
 */

import React, { useState, useEffect } from "react";
import { useDataContext } from "../../contexts/DataContext";
import { DateTime } from "luxon";
import { dateToString, formatTime } from "./analyticsHelpers";
// import ReactLoading from 'react-loading';
import Loader from "../../utils/Loader";
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	Label,
	ResponsiveContainer,
} from "recharts";

export default function LocationComparisonWidget({
	// allLocations,
	// franchise,
	selectedLocations,
	dtToggle,
	period1,
	period2,
	periodToggle,
}) {
	const [p1Data, setP1Data] = useState();
	const [p2Data, setP2Data] = useState();
	const [legendDict, setLegendDict] = useState({
		p1: "Period 1",
		p2: "Period 2",
	});

	const { routes, getCacheData, setCache } = useDataContext();

	useEffect(() => {
		dataSetup();
	}, []);

	useEffect(() => {
		// setP1Data(undefined);
		// setP2Data(undefined);
		dataSetup();
	}, [selectedLocations, dtToggle, period1, period2]);

	// Update the Legend Dict
	useEffect(() => {
		let obj = Object.assign({}, legendDict);
		if (period1 != undefined) {
			let d1 = DateTime.fromJSDate(period1[0]);
			let d2 = DateTime.fromJSDate(period1[1]);
			obj["p1"] = `${d1.toLocaleString(
				DateTime.DATE_MED
			)} to ${d2.toLocaleString(DateTime.DATE_MED)}`;
		}
		if (period2 != undefined) {
			let d1 = DateTime.fromJSDate(period2[0]);
			let d2 = DateTime.fromJSDate(period2[1]);
			obj["p2"] = `${d1.toLocaleString(
				DateTime.DATE_MED
			)} to ${d2.toLocaleString(DateTime.DATE_MED)}`;
		}
		setLegendDict(obj);
	}, [period1, period2]);

	const dataSetup = async () => {
		if (!allLoaded()) {
			return;
		}

		let [p1d, p2d] = await Promise.all([getData(period1), getData(period2)])

		// // Get data for each period
		// let p1d = await getData(period1);
		// let p2d = await getData(period2);

		setP1Data(p1d);
		setP2Data(p2d);
	};

	/**
	 * Verify that all the props are loaded (not undefined)
	 */
	const allLoaded = () => {
		return (
			// allLocations != undefined &&
			// franchise != undefined &&
			selectedLocations != undefined &&
			dtToggle != undefined &&
			period1 != undefined &&
			period2 != undefined
		);
	};

	/**
	 * Querry for the appropriate data
	 */
	const getData = async (p) => {
		// Check cache first
		let key = `comparison-location-graph-${dateToString(p[0])}-${dateToString(p[1])}-${dtToggle}-${selectedLocations.map(x => x.id).join('-')}`;
		let cacheData = getCacheData(key);
		if ((cacheData != undefined)){
			return cacheData;
		}


		let params = {
			start_date: dateToString(p[0]),
			end_date: dateToString(p[1]),
			locations: selectedLocations.map((x) => x.id),
			is_drive_through: dtToggle,
			which: ["detection"],
		};

		let url = routes["comparisonData"];
		let data = await fetch(url, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(params),
		})
			.then((res) => res.json())
			.then((data) => {
				let dat = JSON.parse(data["detection"]);

				// save to cache
				setCache(key, dat);

				return dat;
			})
			.catch((err) => {
				console.warn(err);
				return undefined;
			});

		return data;
	};

	

	const renderGraph = () => {
		if (p1Data == undefined || p2Data == undefined) {
			return <Loader type='bars'/>
		}

		const getVal = (data, lid) => {
			let match = data.filter((x) => x.location_id == lid);
			return match.length == 0 ? 0 : match[0].avg;
		};

		// Organize Data for Plotting
		let plotData = [];
		selectedLocations.forEach((loc) => {
			let obj = {};
			obj["name"] = loc.display_name;
			obj["lid"] = loc.id;
			obj["p1"] = getVal(p1Data, loc.id);
			obj["p2"] = getVal(p2Data, loc.id);
			plotData.push(obj);
		});

		// Make the graph
		return (
			<ResponsiveContainer width="100%" height="100%">
				<BarChart
					width={500}
					height={300}
					data={plotData}
					margin={{
						top: 10,
						right: 30,
						left: 20,
						bottom: 10,
					}}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis
						dataKey="name"
						angle={-20}
						textAnchor="end"
						interval={0}
						tick={{ fontSize: "10px" }}
					/>
					<YAxis
						tickFormatter={(v) => formatTime(v)}
						// domain={[0, "dataMax"]}
						style={{ fontSize: "10px" }}
						tickCount={10}>
						<Label
							value={"Average Service Time"}
							position="left"
							angle={-90}
							style={{ textAnchor: "middle", fontSize: "12px" }}
						/>
					</YAxis>
					<Tooltip
						formatter={(val, name, props) => [
							formatTime(val),
							legendDict[name],
						]}
						wrapperStyle={{fontSize:'12px', opacity:'.95'}}
					/>
					<Legend
						formatter={(val, entry, index) => legendDict[val]}
						verticalAlign="top"
						wrapperStyle={{ fontSize: "12px", top:'-.1px' }}
					/>
					{
						periodToggle == 'both' || periodToggle == 'period1' ? 
						<Bar dataKey="p1" fill="#54BFF0" /> : null
					}
					{
						periodToggle == 'both' || periodToggle == 'period2' ? 
						<Bar dataKey="p2" fill="#FFA695" /> : null
					}
					{/* <Bar dataKey="p1" fill="#54BFF0" />
					<Bar dataKey="p2" fill="#FFA695" /> */}
				</BarChart>
			</ResponsiveContainer>
		);
	};

	return (
		<div className="bg-white rounded-lg shadow-lg p-4 w-full">
			Location Comparison
			<div className='h-96'>{renderGraph()}</div>
		</div>
	);
}
