import React, { useState } from "react";
import { isCompositeComponent } from "react-dom/test-utils";
import ModalBasic from '../actions/ModalBasic';
import ToggleSwitch from "../actions/ToggleSwitch";

export default function GraphSelector({
	displayWidgets,
	setDisplayWidgets,
	componentInfo
}) {

	const [graphModalOpen, setGraphModalOpen] = useState(false);

	/**
	 * Helper to toggle a graph in displayWidgets form the modal
	 * Tkaes in the graph name, and a boolean indicating if we should
	 * include it or not, then updates displayWidgets with setDisplayWidgets
	 * to reflect new option
	 * @param {String} which name in component info of the graph
	 * @param {Boolean} bool indicates if should include or not 
	 */
	const graphToggleHelper = (which, bool) => {
		console.log(which, bool);
		if (bool){
			// Add it
			setDisplayWidgets([...displayWidgets, which])
		}
		else{
			setDisplayWidgets(displayWidgets.filter(x => x != which));
		}
	}

	return (
		<div>
			<br/>
			<button
				className="btn focus-none border-gray-200 hover:bg-gray-50 hover:text-blue-500"
				onClick={(e) => {
					e.stopPropagation();
					setGraphModalOpen(true);
				}}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="icon icon-tabler icon-tabler-apps"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					strokeWidth="1.5"
					stroke="#9e9e9e"
					fill="none"
					strokeLinecap="round"
					strokeLinejoin="round">
					<path stroke="none" d="M0 0h24v24H0z" fill="none" />
					<rect x="4" y="4" width="6" height="6" rx="1" />
					<rect x="4" y="14" width="6" height="6" rx="1" />
					<rect x="14" y="14" width="6" height="6" rx="1" />
					<line x1="14" y1="7" x2="20" y2="7" />
					<line x1="17" y1="4" x2="17" y2="10" />
				</svg>
				<span className="ml-2">Choose Graphs</span>
			</button>
			<ModalBasic
				modalOpen={graphModalOpen}
				setModalOpen={setGraphModalOpen}
				title="Choose Visualizations">
				<div className='p-3'>
					{
						componentInfo.map(comp => {
							// determine if currently selected
							let selected = displayWidgets.includes(comp.name);
							// console.log(selected);
							// console.log(displayWidgets);
							// console.log(comp.name);
							return (
								<div className='p-2 mt-3 border-b border-gray-200'
									key={`graphSelector-${comp.name}`}
									id={`graphSelector-${comp.name}`}>
									<div className='flex justify-between'>
										<div className='text-it'>
											{comp.displayName}
										</div>
										<div>
											<ToggleSwitch 
												toggle={selected}
												setToggle={(b) => graphToggleHelper(comp.name, b)}
												// setToggle={(b) => console.log(comp.name, b)}
												switchId={`graphSwitch-${comp.name}`}
												/>
										</div>
									</div>
									<div className='text-xs pr-8 text-gray-400'>
										{comp.description}
									</div>
								</div>
							)
						})
					}
				</div>
			</ModalBasic>
		</div>
	);
}
