// import firebase from 'firebase/app';
import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/firestore";
import "firebase/messaging";
import "firebase/analytics";

var app;

try {
	app =  firebase.initializeApp({
		apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
		authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
		databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL ,
		projectId:  process.env.REACT_APP_FIREBASE_PROJECT_ID ,
		storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
		messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
		appId: process.env.REACT_APP_FIREBASE_APP_ID,
		measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
	});
} catch(err){
	if (!/already exists/.test(err.message)) {
		console.error('Firebase initialization error', err.stack)
	}
	app = firebase.app();
}
 

export const auth = app.auth();

// let firebaseAnalytics = firebase.analytics.Analytics;
export const analytics = firebase.analytics();

// export const messaging = app.messaging();
// messaging.getToken()
//    .then(function(token) {
//      // print the token on the HTML page
//      console.log(token)
//    })
//    .catch(function (err) {
//    console.log("Unable to get permission to notify.", err);
//  });

 /*
 curl -X POST -H "Authorization: key=AAAAhtCUeac:APA91bHHEpqz-tbq4jAKtQbtu5U2JxiGwbFDwNNtktrhmXbbYfLsgp_WwfxRDuoHQjtztoHsi_BhtMLOVuzslcDkPu6QTwrv87eJu4T5LAo0AugPvre4fqiZMIQqs9Ud3DqjKG7vrqX4" -H "Content-Type: application/json" -d '{
  "data": {
    "status": "Portugal vs. Denmark",
    "body": "5 to 1",
    "icon": "firebase-logo.png",
    "click_action": "http://localhost:8081"
  },
  "to": "cM1O6iA0kSqzoFelUuGUy_:APA91bEKz88m5UzLJikBkjyw3_eKOECOcyNqtkjgZWJ0kiqcGTPhmwVnOadtr4fKdLwnuCGs8nPZgLwxGlVs1CVZ5UgDG474VEhC1EWgsf75l16JhxSvKayQoGfrj3lnUHK5I19NjJun"
}' "https://fcm.googleapis.com/fcm/send"
 */

export default app;