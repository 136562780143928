import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useDataContext } from '../contexts/DataContext';
import { useQuery } from '@tanstack/react-query';

import { getCustomerLocations } from '../api/customer';
import SingleDatepicker from '../partials/actions/SingleDatepicker';
import DropdownMenu from '../partials/actions/DropdownMenu';
import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import Loader from '../utils/Loader';
import DetectionOrderWidget from '../partials/pos/DetectionOrderWidget';
import WeekBarGraphWidget from '../partials/pos/WeekBarGraphWidget';
import Orders24Graph from '../partials/pos/Orders24Graph';

export default function POS() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [date, setDate] = useState(new Date());
  const [franchises, setFranchises] = useState();
  const [franchise, setFranchise] = useState();
  const [havePOS, setHavePOS] = useState(false);
  const [location, setLocation] = useState();

  // Contexts
  const { currentUser } = useAuth();
  const { routes, getCacheData, setCache, oldFranchises } = useDataContext();

  // Querry
  const {
    data: customerLocations,
    reftech: refetchCustomerLocations,
    isLoading: customerLocationsIsLoading,
    isFetching: customerLocationsIsFetching,
  } = useQuery(
    ['customerLocations', currentUser.uid, routes['customerLocations']],
    getCustomerLocations,
    {
      enabled: !!currentUser.uid,
      retry: 3,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (customerLocations == undefined) {
      setFranchises(undefined);
      setFranchise(undefined);
      return;
    }
    if (customerLocations.length == 0) {
      setFranchises([]);
    }

    // Check to see if any locations have POS
    let anyHavePOS = customerLocations.reduce(
      (acc, cur) => acc || cur.has_pos,
      false
    );

    // Find out which franchises have POS
    let franchiseList = Array.from(
      new Set(
        customerLocations.filter((x) => x.has_pos).map((x) => x.franchise)
      )
    );
    let _ids = [];
    franchiseList = franchiseList.reduce((acc, cur) => {
      if (!_ids.includes(cur.id)) {
        acc.push(cur);
        _ids.push(cur.id);
      }
      return acc;
    }, []);
    if (franchiseList.length == 0) {
      setHavePOS(false);
      return;
    }

    // else, we do have some POS, so lets truncate the franchise list for that
    franchiseList.sort((a, b) => a.id - b.id);
    // console.log(franchiseList);
    setFranchises(franchiseList);
    setHavePOS(anyHavePOS);

    // Handle local storage for the selected franchise
    let sf = localStorage.getItem('ordersFranchise');
    let fran;
    if (sf) {
      sf = parseInt(sf);
      if (franchiseList.map((x) => x.id).includes(sf)) {
        fran = franchiseList.filter((x) => x.id == sf)[0];
        setFranchise(fran);
      } else {
        fran = franchiseList[0];
        setFranchise(fran);
      }
    } else {
      fran = franchiseList[0];
      setFranchise(fran);
    }
    console.log(fran);

    // Handle default location
    let lclLoc = localStorage.getItem('ordersPageLocation-selected');
    if (lclLoc) {
      lclLoc = parseInt(lclLoc);
      if (customerLocations.map((x) => x.id).includes(lclLoc)) {
        setLocation(customerLocations.filter((x) => x.id == lclLoc)[0]);
      } else {
        // get franchise locations
        setLocation(
          customerLocations.filter(
            (x) => x.franchise.id === fran.id && x.has_pos
          )[0]
        );
      }
    } else {
      setLocation(
        customerLocations.filter(
          (x) => x.franchise.id === fran.id && x.has_pos
        )[0]
      );
    }
    console.log('HERE');
  }, [customerLocations]);

  useEffect(() => {
    if (franchise !== undefined) {
      localStorage.setItem('ordersFranchise', franchise.id);

      // update location to make sure it matches
      if (location !== undefined) {
        console.log(location);
        //check to see if franchise matches
        if (location.franchise.id !== franchise.id) {
          // set new location for franchise
          let loc = customerLocations.filter(
            (x) => x.franchise.id === franchise.id && x.has_pos
          )[0];
          setLocation(loc);
          localStorage.setItem('orderPageLocation-selected', loc.id);
        }
      }
    }
  }, [franchise]);

  const setFranchiseHelper = (name) => {
    let match = franchises.filter((x) => x.name == name)[0];
    setFranchise(match);
  };

  const renderFranchiseFilter = () => {
    if (franchises == undefined) {
      // Render a loader
      return <div className="animate-pulse bg-blue-400 rounded w-56 h-2"></div>;
    } else if (franchises.length <= 1) {
      return;
    }

    // Else we have multiple franchises, so lets make a dropdown
    return (
      <DropdownMenu
        options={franchises.map((x) => x.name)}
        setOptions={setFranchiseHelper}
        selected={franchise.name}
      />
    );
  };

  const locationHelper = (display_name) => {
    let loc = customerLocations.filter(
      (x) => x.franchise.id === franchise.id && x.display_name === display_name
    )[0];
    setLocation(loc);
    localStorage.setItem(`ordersPageLocation-selected`, loc.id);
  };

  const renderLocationSelect = () => {
    console.log(franchise);
    console.log(customerLocations);
    console.log(customerLocationsIsFetching);
    console.log(customerLocationsIsFetching);
    console.log(location);
    if (
      franchise == undefined ||
      customerLocations == undefined ||
      customerLocationsIsLoading ||
      customerLocationsIsFetching ||
      location === undefined
    ) {
      return null;
    }

    // Else we have locations
    let options = customerLocations.filter(
      (x) => x.franchise.id === franchise.id && x.has_pos
    );
    console.log(options);
    if (options.length == 1) {
      return null; // nothing to choose
    }
    return (
      <DropdownMenu
        options={options.map((x) => x.display_name)}
        setOptions={locationHelper}
        selected={location.display_name}
      />
    );
  };

  const renderContent = () => {
    // If loading requests to find out what we have, show loader
    if (customerLocationsIsLoading || customerLocationsIsFetching) {
      return <Loader />;
    }

    // Not loading
    if (havePOS) {
      return (
        <>
          <div className="sm:flex sm:justify-between sm:items-center mb-8">
            <div className="mb-4 grid grid-flow-col gap-2">
              {renderFranchiseFilter()}
              {renderLocationSelect()}
            </div>

            <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2 mb-4">
              <SingleDatepicker setDate={setDate} defaultDate={new Date()} />
            </div>
          </div>

          <div className="w-full grid grid-cols-12 gap-2">
            <Orders24Graph
              customerLocationData={customerLocations}
              franchise={franchise}
              location={location}
              date={date}
            />

            <WeekBarGraphWidget
              customerLocationData={customerLocations}
              franchise={franchise}
              location={location}
              date={date}
            />
          </div>

          <DetectionOrderWidget
            customerLocationData={customerLocations}
            franchise={franchise}
            location={location}
            date={date}
            isDriveThru={true}
          />
        </>
      );
    } else {
      return (
        <div className="text-xl text-gray-400 w-full grid justify-items-center">
          <div className="text-center max-w-md">
            It appears none of your locations have POS Integration, please
            contact sales to learn more!
          </div>
        </div>
      );
    }
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          title="Orders"
        />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {renderContent()}
          </div>
        </main>
      </div>
    </div>
  );
}
