import React from "react";

import DirectionsWalkIcon from "@material-ui/icons/DirectionsWalk";
import DriveEtaIcon from "@material-ui/icons/DriveEta";

export default function DriveThruToggle({ isDriveThru, setIsDriveThru }) {
	return (
		<div className="inline-flex">
			<button
				className={`btn ${!isDriveThru ? "bg-white shadow-sm" : "bg-gray-100"} focus:outline-none border border-gray-100`}
				onClick={() => setIsDriveThru(false)}>
				<DirectionsWalkIcon />
			</button>
			<button
				className={`btn ${isDriveThru ? "bg-white shadow-sm" : "bg-gray-100"} focus:outline-none border-gray-100`}
				onClick={() => setIsDriveThru(true)}>
				<DriveEtaIcon />
			</button>
		</div>
	);
}
