import React, { useState, useEffect } from 'react';
import { useDataContext } from "../../contexts/DataContext";
import {dateToString} from "./analyticsHelpers";
import { useQuery } from '@tanstack/react-query';
import Loader from '../../utils/Loader';
import TrendGraph from './TrendGraph';
import { getTrendData } from '../../api/analytics';

export default function TrendWidget({
    selectedLocations,
    dtToggle,
    dates,
    colors
}){

    const { routes, getCacheData, setCache } = useDataContext();
    const [showTimes, setShowTimes] = useState(true);
    const [showVolumes, setShowVolumes] = useState(false);


    const {data, refetch, isLoading, isFetching} = useQuery(
        // [
        //     'analytics_trend', 
        //     selectedLocations.map(x => x.id), 
        //     dtToggle, 
        //     dates.map(x => dateToString(x)), 
        //     routes['analyticsTrends']
        // ],
        [
            'analytics_trend', 
            // [27, 28, 98], // [99, 100, 125, 97] 
            selectedLocations.map(x => x.id).join(','),
            dtToggle, 
            dates.map(x => dateToString(x)).join("T"), 
            // ['2022-10-01', '2022-11-13'],
            routes['analyticsTrends']
        ],
        getTrendData,
        {
            enabled: !!(!!selectedLocations & (dtToggle != undefined) & !!dates),
            retry: 0,
            refetchOnWindowFocus: false,
            cacheTime: 1000 * 60 * 15,
        }
    );


    return (
        <div className='bg-white rounded-lg shadow-lg p-4 w-full' id='outerWidget'>
            <div className='w-full'>
                Trend Chart
                <span className='float-right'>
                    <button 
                        className={`btn ${showTimes ? "bg-white" : "bg-gray-100"} focus:outline-none border border-gray-200`}
                        onClick={() => setShowTimes(!showTimes)}>
                        Times
                    </button>
                    <button 
                        className={`btn ${showVolumes ? "bg-white" : "bg-gray-100"} focus:outline-none border border-gray-200`}
                        onClick={() => setShowVolumes(!showVolumes)}>
                        Volumes
                    </button>
                </span>
            </div>
            {
                isLoading || isFetching ? (
                    <Loader />
                ) : (
                    <TrendGraph 
                        data={JSON.parse(data)}
                        showTimes={showTimes}
                        showVolumes={showVolumes}
                        colors={colors}/>
                )
            }
        </div>
    )
}