import React, { useState, useEffect } from 'react';
import app from '../../firebase';
import ReactLoading from 'react-loading';
// import { useSpring, animated, config } from 'react-spring';


export default function LiveCountFollower({location, setLocationLiveCount}){

    const [count, setCount] = useState();
    const [color, setColor] = useState('');
    const [updated, setUpdated] = useState();


    useEffect(() => {
        if (location !== null && location !== undefined){
            // Make a listener
            // console.log(location);
            const db = app.firestore();
            let unsub = db.collection("location").doc(String(location.id))
                .onSnapshot(doc => {
                    // console.log("Getting data");
                    let data = doc.data();
                    if (Object.keys(data).includes("current_volume")){
                        setCount(data.current_volume);
                    }
                    if (Object.keys(data).includes("last_volume_update")){
                        setUpdated(data.last_volume_update.toDate());
                    }
                })

            // return clear listener
            return unsub;
        }
    }, [location])

    useEffect(() => {
        // on render set color to red
        setColor('text-blue-400 font-bold');

        // Update high level tracker
        setLocationLiveCount(location.id, count == '--' ? null : count);

        // set timeout to go back to ''
        let tid = setTimeout(() => setColor(''), 1000);

        // Set a timeout to clear if no updates in 10 minutes
        const TEN_MINUTES = 1000 * 60 * 10; // in ms
        let tid2 = setTimeout(() => {
            setCount("--");
        }, TEN_MINUTES);
    
        // clear timeout on exit
        return () => {
            clearTimeout(tid);
            clearTimeout(tid2);
        }
    }, [count]);

    useEffect(() => {
        // If updated is a date, then check to see if too old
        if (updated instanceof Date){
            let now = new Date();
            let diff = now - updated;
            let diffMins = diff / (1000 * 60);
            if (diffMins >= 10){
                setCount("--");
                setLocationLiveCount(location.id, null);
            }
        }
    }, [updated])



    /** If no location return a placeholder */
    if (location === null && location === undefined && count === undefined){
        return <ReactLoading type='cylon' color='#39A3F2' />
    }
    return (
        <span className={`transition duration-200 ${color}`}>
            {count}
        </span>
    )
}