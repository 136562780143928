/**
    __                     __  _                  _____      __  __  _                 
   / /   ____  _________ _/ /_(_)___  ____  _____/ ___/___  / /_/ /_(_)___  ____ ______
  / /   / __ \/ ___/ __ `/ __/ / __ \/ __ \/ ___/\__ \/ _ \/ __/ __/ / __ \/ __ `/ ___/
 / /___/ /_/ / /__/ /_/ / /_/ / /_/ / / / (__  )___/ /  __/ /_/ /_/ / / / / /_/ (__  ) 
/_____/\____/\___/\__,_/\__/_/\____/_/ /_/____//____/\___/\__/\__/_/_/ /_/\__, /____/  
                                                                         /____/        
 * 
 * Partial to handle lcation settings, i.e., day parts
 */

import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useDataContext } from '../../contexts/DataContext';
import app from '../../firebase';

import LocationAccordion from './LocationAccordion';

// import ReactGA from 'react-ga';
// ReactGA.initialize('UA-185771165-2');

export default function LocationsSettings(props){

    // Contexts
    const { currentUser } = useAuth();
    const { routes } = useDataContext();

    // States
    const [customerState, setCustomerState] = useState();
    const [locationsState, setLocationsState] = useState([]);


    // Get data on load
    useEffect(async () => {
        // Get teh data on the customer
		let customerData = await getCustomerData();

		// Get the locaiton data
		let locationData = await getLocationData(customerData.locations);

		// Get the names for it with API query
		let apiData = await getLocationDBData(customerData.locations);
		console.log(apiData);

		// Stitch the location names into the locationData
		console.log(locationData);
		locationData.forEach(d => {
			let id = d.location_id;
			let match = apiData.filter(x => x.id == id);
			let address;
			let displayName;
			if (match.length >= 1){
				address = match[0].address;
				displayName = match[0].display_name;
			}
			else{
				address = id;
				displayName = id;
			}
			// d['name'] = name;
			d['address'] = address;
			d['displayName'] = displayName;
		});

		setCustomerState(customerData);
		setLocationsState(locationData);

		// ReactGA.pageview("LocationSettiongs");
    }, []);

    const getCustomerData = async () => {
		// Setup firestore connection
		const db = app.firestore();

		// Get the locations for the customer
		let customerData = await db.collection('customer').doc(currentUser.uid).get();
		customerData = customerData.data();

		return customerData;
	}

	const getLocationData = async (locations) => {
		// Setup firestore connection
		const db = app.firestore();

		let locationData = [];
		let e = 0;
		while (e < locations.length){
			let locationQuery = await db.collection('location')
				.where('location_id', 'in', locations.slice(e, e+10)).get();
			locationQuery.forEach(doc => locationData.push(doc.data()));
			e = e + 10;
		}
		return locationData;
		
	}

    const getLocationDBData = async (lids) => {
		let params = JSON.stringify({ids:lids});
		console.log(params);
		let token = await currentUser.getIdToken();
		let url = routes['getLocations'];
		// Can we use at the end of location logic the update/<location_id>
		// and just pass it {'display_name': newVal} ? I think we can
		const r = await fetch(url, {
			method: 'POST',
			body: params,
			headers:{
				'Content-Type': 'application/JSON',
				'Authorization': `${token}`
			}
		})
		let dat = await r.json();
		
		return dat;
	}

    /**
	 * Saves the passed day part prefernces for ALL the user's locations
	 * @param {Array[Object]} newData 
	 */
	const parentSaveForAll = (newData) => {
		// Save the passed day part data for EACH location
		const db = app.firestore();

		// Setup a batch update
		let batch = db.batch();
		locationsState.map(x => x.location_id).forEach(lid => {
			let ref = db.collection('location').doc(lid.toString());
			batch.update(ref, {
					day_part_preferences: newData,
					location_id: lid
				});
		});

		// Commit the updates
		batch.commit().then(async () => {
			window.location.reload(false);
			// Done, so pull the data and reset state
			// let pulledData = await getLocationData(customerState.locations);
			// console.log(pulledData);
			// setLocationsState(pulledData);
		})
	}

    const buildLocationAccordions = () => {

		if (locationsState.length == 0){
            return (
                <>
                    {
                        [1,2,3,4].map(x => {
                            return(
                                <div className='w-100 shadow-lg rounded-lg border-gray-200 p-2'>
                                    <div className='w-100 m-3 h-1 bg-blue-400 animate-pulse'>
    
                                    </div>
                                </div>
                            )
                        })
                    }
                </>
            )
		}

		
		// let allLocations = locationsState.map(x => x.location_id);
		return (
			<>
			{
				locationsState.sort((a,b) => a.location_id - b.location_id)
					.map((d,i) => <LocationAccordion 
								key={d.location_id}
								location_id={d.location_id}
								name={d.name}
								address={d.address}
								displayName={d.displayName}
								dayPartData={d.day_part_preferences}
								allSaveFn={parentSaveForAll}/> )
			}
			</>
		)
	}


    return(
        <>
            {buildLocationAccordions()}
        </>
    )
}