import React, { useState, useEffect } from 'react';
import { useDataContext } from '../../contexts/DataContext';
import TableLoader from '../dashboard/TableLoader';
import LiveCountFollower from './LiveCountFollower';
import { Link } from 'react-router-dom';

export default function RankTable({
  customerLocationData,
  franchise,
  filteredLocations,
  date,
  isDriveThru,
  showCounts,
}) {
  const [tableData, setTableData] = useState();
  const columns = [
    'Rank',
    'Location',
    'Average',
    'Customer Count',
    'Difference From Best Day',
    'WoW Change',
    'Rank Change',
  ];
  const [sortDirection, setSortDirection] = useState(-1);
  const [sortColumn, setSortColumn] = useState();
  const [showMiddle, setShowMiddle] = useState(false);
  const [customerCounts, setCustomerCounts] = useState();

  const [ExpandThreshold, setExpandThreshold] = useState(15);

  const { getCacheData, setCache, routes } = useDataContext();

  useEffect(() => {
    const signalController = new AbortController();
    if ((franchise != undefined) & (customerLocationData != undefined)) {
      getTableData({ controller: signalController });
    }

    // Set interval to refresh every 5 minutes
    const id = setInterval(refreshData, 5 * 60 * 1000);
    return () => {
      clearInterval(id);
      signalController.abort();
    };
  }, [franchise, customerLocationData, date, isDriveThru]);

  const getCacheKey = () => {
    let locs = customerLocationData
      .filter((x) => x.franchise.id == franchise.id)
      .map((x) => x.id);
    let dateString = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
    let key = `ranktable-${locs.join('-')}-${dateString}-${isDriveThru}`;
    return key;
  };

  const getTableData = ({
    override = false,
    controller = new AbortController(),
  }) => {
    // Check cache or override
    let key = getCacheKey();
    let cacheData = getCacheData(key);
    if ((cacheData != undefined) & !override) {
      setTableData(cacheData);
      return;
    }

    // Clear it out will activate loader
    setTableData(undefined);

    // Fetch
    let locations = customerLocationData
      .filter((x) => x.franchise_id == franchise.id)
      .map((x) => x.id);
    // console.log(date);
    let sendDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;
    let params = {
      locations: locations,
      // date: date,
      date: sendDate,
      is_drivethru: isDriveThru,
    };
    // console.log(params);
    // console.log(JSON.stringify(params));
    let url = routes['rankTable'];
    // fetch("http://localhost:5001/webapp2/getRankData", {
    fetch(url, {
      signal: controller.signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/JSON',
        // "Access-Control-Allow-Headers" : "Content-Type",
        // "Access-Control-Allow-Origin": "*", // Allow from anywhere
        // "Access-Control-Allow-Methods": "POST" // Allow only GET request
      },
      body: JSON.stringify(params),
    })
      .then((res) => res.json())
      .then((data) => {
        data = augmentLocations(data);
        setCache(getCacheKey(), data);
        setTableData(data);
      })
      .catch((err) => console.warn(err));
  };

  /**
   * Takes in location data, and adds to it any locations that have no data
   */
  const augmentLocations = (data) => {
    let missing = customerLocationData
      .filter((x) => !data.map((x) => x.Location).includes(x.display_name))
      .filter((x) => x.franchise_id == franchise.id);
    // .filter(x => x.has_drive_through == isDriveThru)

    if (isDriveThru) {
      missing = missing.filter((x) => x.has_drive_through);
    }

    let lastRank = Math.max(...data.map((x) => x.Rank));
    lastRank = lastRank == -Infinity ? 0 : lastRank;
    missing.forEach((dat) => {
      lastRank += 1;
      data.push({
        Rank: lastRank,
        Location: dat.display_name,
        Average: NaN,
        'Difference From Best Day': 0,
        'Rank Change': 0,
      });
    });
    return data;
  };

  const refreshData = () => {
    if ((customerLocationData != undefined) & (franchise != undefined)) {
      getTableData({ override: true });
    }
  };

  const formatSeconds = (s) => {
    if (isNaN(s)) {
      return '--';
    }
    let minutes = Math.floor(s / 60);
    let seconds = Math.round(s % 60);
    return `${minutes}m ${seconds < 10 ? '0' + seconds : seconds}s`;
  };

  const renderTimeDelta = (s) => {
    if (s == 0 || s == null) {
      // today is the best day
      return '--';
    }
    let time = formatSeconds(Math.abs(s));
    let upArrow = <>&uarr;</>;
    let downArrow = <>&darr;</>;
    return (
      <div className={`${s > 0 ? 'text-green-500' : 'text-red-500'}`}>
        {time}
        {s < 0 ? upArrow : downArrow}
      </div>
    );
  };

  const renderRankChange = (r) => {
    if (r == 0) {
      return '--';
    }
    let upArrow = <>&uarr;</>;
    let downArrow = <>&darr;</>;
    return (
      <div className={`${r > 0 ? 'text-green-500' : 'text-red-500'}`}>
        {Math.abs(r)}
        {r < 0 ? downArrow : upArrow}
      </div>
    );
  };

  /**
   * Handles when a column was clicked.
   * Cases:
   *  first click on this column: set as sort column and set direction
   *  second click: keep sort column, flip direction
   *  third click: unset as sort column
   *
   * As we know this is the order, we can use the sort direction to help
   * us determine which number click it is
   * @param {String} col column name that was clicked
   */
  const colClick = (col) => {
    // Disable for Live Count untill we get data structure right
    // if (col == 'Live Count'){
    //     return
    // }

    if (sortColumn != col) {
      // First click
      setSortColumn(col);
      setSortDirection(-1);
      return;
    }

    // Else, this is already the sort column, so rely on the sort direction
    if (sortDirection == -1) {
      // this is the second click
      setSortDirection(1);
    } else {
      // This is the third click--reset all
      setSortColumn();
      setSortDirection(-1);
    }
  };

  const setLocationLiveCount = (lid, count) => {
    // customerCounts, setCustomerCounts
    let allCounts = { ...customerCounts };
    allCounts[lid] = count;
    setCustomerCounts(allCounts);
  };

  const isEmpty = (val) => {
    if (typeof val == 'string') {
      return false;
    }
    return val == undefined || val == null || isNaN(val);
  };

  const sortData = (data) => {
    // Base case, sort by rank
    if (sortColumn == undefined) {
      return data.sort((a, b) => a.Rank - b.Rank);
    }

    // hack to get column right
    let col = sortColumn;
    if (sortColumn == 'WoW Change') {
      col = 'wow_delta';
    }
    // TODO sorting by Location isn't working?---because it is NaN....
    // Column specifics
    return data.sort((a, b) => {
      if (col == 'Live Count') {
        a = customerCounts[a.location_id];
        b = customerCounts[b.location_id];
      } else {
        a = a[col];
        b = b[col];
      }

      // always push undefined to the bottom
      if (isEmpty(a) || isEmpty(b)) {
        return isEmpty(a) - isEmpty(b);
      }

      let dir = a > b ? 1 : -1;
      return dir * sortDirection;
    });
  };

  const renderBigTableBody = (top5, middle, bottom5, colsLength) => {
    return (
      <>
        <tbody className="divide-y divide-gray-200 text-xs">
          {top5.map((data) => renderTableBody(data))}
        </tbody>

        <tbody className="divide-y divide-gray-200 text-xs h-12">
          {showMiddle ? (
            middle.map((data) => renderTableBody(data))
          ) : (
            <tr
              onClick={() => setShowMiddle(true)}
              className="hover:text-blue-400 hover:bg-gray-100 cursor-pointer"
            >
              <td colSpan={colsLength}>
                <p className="text-center">Show all locations</p>
              </td>
            </tr>
          )}
        </tbody>

        <tbody className="divide-y divide-gray-200 text-xs">
          {bottom5.map((data) => renderTableBody(data))}
        </tbody>
      </>
    );
  };

  const renderTableBody = (data) => {
    // get location id
    let location = customerLocationData.filter(
      (x) => x.franchise.id == franchise.id && x.display_name == data.Location
    );
    // console.log(location);
    if (location.length > 0) {
      location = location[0];
    } else {
      // console.log("We have a non-match");
      // console.log("customerLocationData", customerLocationData);
      // console.log("Franchise", franchise);
      // console.log("filtered", filteredTableData);
      location = undefined;
      return <tr></tr>;
    }

    return (
      <tr
        className="hover:bg-gray-100 cursor-default my-4"
        key={`row-${data.Location}`}
      >
        <td className="p-2">{data.Rank}</td>
        <td className="p-2">
          {/* {data.Location} */}
          <Link to={`/applications?lid=${location.id}`} target="_blank">
            {data.Location}
          </Link>
        </td>
        <td className="p-2">{formatSeconds(data.Average)}</td>
        {(franchise.id == 9 ||
          franchise.id == 28 ||
          franchise.id == 30 ||
          franchise.id == 33 ||
          franchise.id == 38) &&
        location !== undefined ? (
          <td className="p-2 text-center">
            <LiveCountFollower
              location={location}
              setLocationLiveCount={setLocationLiveCount}
            />
          </td>
        ) : null}
        {showCounts ? (
          <td className="p-2 text-center">{data['Customer Count']}</td>
        ) : null}

        <td className="p-2 text-center">
          {renderTimeDelta(data['Difference From Best Day'])}
        </td>
        <td className="p-2 text-center">
          {renderTimeDelta(data['wow_delta'])}
        </td>
        <td className="p-2 text-center">
          {renderRankChange(data['Rank Change'])}
        </td>
      </tr>
    );
  };

  const renderTable = () => {
    if (
      tableData == undefined ||
      franchise == undefined ||
      filteredLocations == undefined ||
      filteredLocations == undefined
    ) {
      return <TableLoader columns={columns} />;
    }

    // Filter based on filterdLocations
    let names = filteredLocations.map((x) => x.display_name);
    let filteredTableData = tableData.filter((x) => names.includes(x.Location));
    filteredTableData = sortData(filteredTableData);

    if (filteredTableData.length > ExpandThreshold) {
      var top5 = filteredTableData.slice(0, 5);
      var bottom5 = filteredTableData.slice(filteredTableData.length - 5);
      var middle = filteredTableData.slice(5, filteredTableData.length - 5);
    }

    if (filteredTableData.length == 0) {
      return <div className="w-full text-center text-gray-400">No Data</div>;
    }
    let renderCols = [...columns];
    if (!showCounts) {
      renderCols = columns.filter((x) => x != 'Customer Count');
    }
    if (
      showCounts &&
      (franchise.id == 9 ||
        franchise.id == 28 ||
        franchise.id == 30 ||
        franchise.id == 33 ||
        franchise.id == 38))
    {
      renderCols.splice(3, 0, 'Live Count');
    }
    return (
      <table className="table-auto w-full divide-y divide-gray-200">
        <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
          <tr>
            {renderCols.map((col) => {
              return (
                <th
                  onClick={() => colClick(col)}
                  className="p-2 sticky top-0 bg-gray-50 cursor-pointer select-none"
                  key={col}
                >
                  <div className="text-left">
                    {col}
                    {col === sortColumn ? (
                      <span className="ml-1">
                        {sortDirection == -1 ? <>&#9650; </> : <>&#9660;</>}
                      </span>
                    ) : null}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        {filteredTableData.length > ExpandThreshold
          ? renderBigTableBody(top5, middle, bottom5, renderCols.length)
          : filteredTableData.map((data) => {
              return (
                <tbody className="divide-y divide-gray-200 text-xs">
                  {renderTableBody(data)}
                </tbody>
              );
            })}
      </table>
    );
  };

  return (
    <>
      <div className="flex flex-col col-span-full bg-white shadow-lg rounded-lg border border-gray-200 mb-8">
        <header className="px-5 py-4 border-b border-gray-100">
          <h2 className="font-semibold text-gray-800 inline">Location Ranks</h2>
          <span className="float-right">
            {showMiddle ? (
              <button
                className="button bg-transparent hover:bg-blue-400 
								text-blue-400 hover:text-white rounded-full 
								border border-blue-400 py-1 px-2 text-xs mr-2"
                onClick={() => setShowMiddle(false)}
              >
                Hide locations
              </button>
            ) : (
              <></>
            )}

            <button
              className="button bg-transparent hover:bg-blue-400 
							text-blue-400 hover:text-white rounded-full 
							border border-blue-400 py-1 px-2 text-xs"
              onClick={() => refreshData()}
            >
              Refresh
            </button>
          </span>
        </header>
        <div className="p-3">
          {/* Table */}
          <div className="overflow-x-auto max-h-96">{renderTable()}</div>
        </div>
      </div>
    </>
  );
}
