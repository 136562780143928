/**
    ____        __        ______            __            __ 
   / __ \____ _/ /_____ _/ ____/___  ____  / /____  _  __/ /_
  / / / / __ `/ __/ __ `/ /   / __ \/ __ \/ __/ _ \| |/_/ __/
 / /_/ / /_/ / /_/ /_/ / /___/ /_/ / / / / /_/  __/>  </ /_  
/_____/\__,_/\__/\__,_/\____/\____/_/ /_/\__/\___/_/|_|\__/  
															 

 * 
 * Kenneth Lipke
 * This context is used not to help with local (client side)
 * cacheing and to standardized querry routes to it is easy 
 * to change between hitting a local endpoint and the cloud
 */

import React, { useContext, useState } from 'react';
import { useAuth } from './AuthContext';
import app from '../firebase';

const DataContext = React.createContext();

// Needed for class components
export default DataContext;

export function useDataContext() {
  return useContext(DataContext);
}

/**
 * The "DataProvider" is just a convenient way to wrap other elemnts
 * and pass the context as props, the key here is that we pass "values" as
 * the value prop to expose the context, and we still keep the "children"
 * note, this is a functional component
 * @param {Things} param0
 */
export function DataProvider({ children }) {
  const [cacheDict, updateCacheDict] = useState({});
  const { currentUser } = useAuth(); // so we can get current uid to check cache

  /**
   * Helper to get ALL of the cache at once
   */
  function getAllCacheData() {
    return cacheDict;
  }

  /**
   * Helper to get how many minutes between two dates
   * @param {Date} d1
   * @param {Date} d2
   */
  function minuteDiff(d1, d2) {
    return Math.abs((d1.getTime() - d2.getTime()) / 60000);
  }

  /**
   * Returns either the cache data for the given key or undefined
   * will return the desired data only if:
   *  + it exists (has been cached)
   *  + the cache is still valid (i.e., given in last 5 minutes)
   *  + was cached by the same user asking for it
   * @param {String} which cacheKey
   */
  function getCacheData(which) {
    // Check if cache has entry
    if (!Object.keys(cacheDict).includes(which)) {
      // Does not have it
      return undefined;
    }

    // Has it, check if current user
    let dat = cacheDict[which];
    if (dat.uid !== currentUser.uid) {
      return undefined;
    }

    // Check if in time zone
    if (minuteDiff(dat.ts, new Date()) > 5) {
      return undefined;
    }

    // Finally, looks good, send the data
    return cacheDict[which]['data'];
  }

  /**
   * Function that sets cache data,
   * takes in a cacheKey and the data,
   * internally marks down the time at which the data came in
   * as well as the user id for the person that sent it
   * @param {String} which cacheKey
   * @param {Anything} data data to cache
   */
  function setCache(which, data) {
    let date = new Date();
    let uid = currentUser.uid;
    let obj = {};
    let d = {
      ts: date,
      uid: uid,
      data: data,
    };
    cacheDict[which] = d;
    updateCacheDict(cacheDict);
  }

  /**
   * Gets firebase preferences for the current user
   */
  const getFBPreferences = async () => {
    const db = app.firestore();
    let doc = await db.collection('customer').doc(currentUser.uid).get();
    if (doc.exists) {
      let data = doc.data();
      return data;
    }

    return {};
  };

  /**
   * Sets a specific prefernce in the current user's firebase store preferences
   * @param {string} key
   * @param {*} val
   */
  const setFBPreference = async (store_key, val) => {
    // console.log(store_key);
    // console.log(val);
    let obj = {};
    obj[store_key] = val;
    const db = app.firestore();
    const ref = db.collection('customer').doc(currentUser.uid);
    const res = await ref.update(obj);
  };

  // Local host routes
  let remoteCloudRun = 'https://api-iaily4lvlq-uc.a.run.app/';
  let lambdaProd =
    'https://z95gc4an16.execute-api.us-west-2.amazonaws.com/Prod/';

  let pre = remoteCloudRun;
  let lambdaPre = lambdaProd;

  const routes = {
    locationPage: `${pre}webapp2/get_data`,
    locationGet: `${pre}location/get/`,
    getLocations: `${pre}location/getLocations`,
    playlist: `${pre}video/playlist`,
    setDisplayName: `${pre}location/update2`,
    rankTable: `${lambdaPre}webapp/dashboard/rankTable`,
    customerLocations: `${pre}webapp2/get_customer_locations_data`,
    dayPartTable: `${lambdaPre}webapp/dashboard/dayPartTable`, // prod stack
    comparisonData: `${pre}analytics/get_comparison_data`,
    deviceIP: `${pre}reboot_camera/get_local_ip`,
    dashboardRegionData: `${pre}webapp2/getRegionData`,
    fullDetectionData: `${pre}detection/get_full`,
    ordersTable: `${pre}orders/getRange`,
    ordersRangeTotals: `${pre}orders/get_range_total`,
    analyticsTrends: `${lambdaPre}webapp/analytics/trend`,
    analyticsRegions: `${lambdaPre}webapp/analytics/regions`,
    analyticsDayPartTrends: `${lambdaPre}webapp/analytics/dayParts`,
  };
  // web routes

  const oldLocations = [3, 8, 9, 10, 11, 12, 13, 19];
  const oldFranchises = [1, 3, 5]; // old franchises that shouldn't see granular dashboard lobby data
  const awsLocations = [
    24, 25, 27, 28, 38, 40, 41, 44, 45, 47, 54, 56, 72, 76, 82, 87,
  ];
  const nonAwsLocations = [
    3, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 19, 20, 21, 22, 32, 36, 55, 71, 79,
  ];

  // Object to store the functions and objects
  // consumers of the context should expect
  const value = {
    getAllCacheData,
    getCacheData,
    setCache,
    routes,
    oldLocations,
    oldFranchises,
    awsLocations,
    nonAwsLocations,
    getFBPreferences,
    setFBPreference,
  };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
}
