import React from 'react';
import FranchiseSelector from '../analytics/FranchiseSelector';
import PeriodPicker from '../analytics/PeriodPicker';
import DriveThruToggle from '../dashboard2/DriveThruToggle';
import DropDownFilter from '../actions/DropDownFilter';
import UserGuide from './UserGuide';
import LocationSelector from './LocationSelector';


export default function AnalyticsToolbar({
    locations,
    franchise,
    setFranchise,
    selectedLocations,
    setSelectedLocations,
    dates,
    setDates,
    dtToggle,
    setDtToggle
}){

    const renderDTToggle = () => {
        let franchiseLocations = locations.filter(x => x.franchise.id == franchise.id);
		if (franchiseLocations === undefined){return null}
		let haveDT = franchiseLocations.map(x => x.has_drive_through).reduce((acc, cur) => {
			return acc || cur
		}, false);
        let haveLobby = franchiseLocations.map(x => x.has_drive_through).reduce((acc, cur) => {
			return acc || !cur
		}, false);
		if (haveDT && haveLobby){
			return (
				<div>
					<span className="text-xs text-gray-600">Drive-Thru</span> <br />
					<DriveThruToggle isDriveThru={dtToggle} setIsDriveThru={setDtToggle} />
				</div>
			)
		}
		else{
            // setDtToggle(false);
            setDtToggle(haveDT);
            // Only have one of the other, so only show one or the other

			return null;
		}
	}

    const renderLocationFilter = () => {
        if (franchise == undefined || locations == undefined){
            return null;
        }

        return (
            <LocationSelector
                options={locations.filter(x => x.franchise.id == franchise.id)}
                selected={selectedLocations}
                setSelected={setSelectedLocations} />
        )

        let filterFunc = (opt, val) => {
            let l = opt.display_name.toLowerCase();
            // console.log(l, val);
            return l.includes(val)
        }

        return (
            <div>
                <span className='text-xs text-gray-600'>
                    Locations
                </span>
                <br />
                <DropDownFilter
                    options={locations.filter(x => x.franchise.id == franchise.id)}
                    selected={selectedLocations}
                    setSelected={setSelectedLocations}
                    filterFunc={filterFunc}
                    selectedDisplayFunc={(x) => x.display_name}
                    optionKey={"id"}
                    maxSelected={10}
                    />
            </div>
        )
    }

    let franchises = locations.reduce((agg, cur) => {
        let x =!agg.map(x => x.id).includes(cur.franchise.id) ? agg.push(cur.franchise) : null;
        return agg;
    }, []);

    return (
        <div className="flex flex-wrap gap-4 mb-8 p-3 bg-white border-gray-100 rounded-lg shadow-lg">
            <FranchiseSelector
                franchises={franchises}
                franchise={franchise}
                setFranchise={setFranchise}/>

            {renderLocationFilter()}
            


            <PeriodPicker
                name={'Dates'}
                dates={dates}
                setDates={setDates}
                />

            {renderDTToggle()}

            <UserGuide />

        </div>
    )
}