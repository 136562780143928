import React from 'react';
import Datepicker from '../actions/Datepicker';

export default function PeriodPicker({name, dates, setDates}){

	if (dates == undefined){
		return <div className="animate-pulse bg-blue-400 rounded w-56 h-2"></div>;
	}

	return (
		<div>
			<span className='text-xs text-gray-600'>{name}</span> <br/>
			<Datepicker 
				dates={dates}
				setDates={setDates}
				/>
		</div>
	)
}