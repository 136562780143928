import React, { useRef, useEffect } from 'react';
import Flatpickr from 'react-flatpickr';
import confirmDate from 'flatpickr/dist/plugins/confirmDate/confirmDate';

function Datepicker(props) {

  const fpref = useRef();

  // How to set the date shown via code.... can use
  // this in a useEffect with props to override when we get
  // a change (e.g., when we load prefs from firebase)
  const set = () => {
    console.log(fpref.current)
    // return;
    let d1 = new Date();
    let d2 = new Date();
    d1.setDate(d1.getDate()-50);
    d2.setDate(d2.getDate()-100);
    fpref.current.flatpickr.setDate([d1, d2]);
  }

  /**
   * Set the dates whenever they change externally
   */
  useEffect(() => {
    if (props.dates == undefined){return}
    fpref.current.flatpickr.setDate(props.dates.map(x => new Date(x)));
  }, [props.dates])

  const options = {
    mode: 'range',
    static: true,
    monthSelectorType: 'static',
    dateFormat: 'M j, Y',
    plugins: [new confirmDate({confirmText: 'Confirm'})],
    // defaultDate: [new Date().setDate(new Date().getDate() - 6), new Date()],
    // defaultDate: [props.defaultDate, props.defaultDate],
    // defaultDate: props.defaultDate,
    prevArrow: '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
    nextArrow: '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    onReady: (selectedDates, dateStr, instance) => {
      instance.element.value = dateStr.replace('to', '-');
    },
    onChange: (selectedDates, dateStr, instance) => {
      if (selectedDates.length == 2){
        props.setDates(selectedDates);
      }
    }
    // onChange: (selectedDates, dateStr, instance) => {
    //   console.log(selectedDates);
    //   if (selectedDates.length == 2){
    //     props.setDates(selectedDates);
    //   }
    //   instance.element.value = dateStr.replace('to', '-');
    // },
  }

  return (
    <div className="relative">
      <Flatpickr className="form-input pl-9 text-gray-500 hover:text-gray-600 font-medium focus:border-gray-300 w-60" options={options} ref={fpref}/>
      <div className="absolute inset-0 right-auto flex items-center pointer-events-none">
        <svg className="w-4 h-4 fill-current text-gray-500 ml-3" viewBox="0 0 16 16">
          <path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
        </svg>
      </div>
      {/* <button className='btn' onClick={set}>Set Mannually</button> */}
    </div>
  );
}

export default Datepicker;
