/**
 * Utility functions for dealing with time.
 */

/**
 * @function utcStringToLocalDate
 * @description Converts a UTC date string to a local date string.
 * */
export const utcStringToLocalDate = (
  utcString: string,
  timeZone: string
): Date => {
  // Split
  let dt = utcString.split(/[: T-]/).map(parseFloat);

  let d = Date.UTC(dt[0], dt[1] - 1, dt[2], dt[3], dt[4], dt[5]);
  let localString = new Date(d).toLocaleString('en-US', {
    timeZone: timeZone,
  });

  // Now back to a datetimeobject that is tz wrong, but all else good
  let nd = new Date(localString);
  return nd;
};

/**
 * @function formatSeconds
 * @description Formats an integer number of seconds into a string in minutes:seconds format.
 */
export const formatSeconds = (numSeconds: number) => {
  if (numSeconds == null || isNaN(numSeconds)) {
    return '--';
  }
  let minutes = Math.floor(numSeconds / 60);
  let seconds = Math.round(numSeconds % 60);
  if (minutes === 0) {
    return `${seconds < 10 ? '0' + seconds : seconds}s`;
  }
  return `${minutes}m ${seconds < 10 ? '0' + seconds : seconds}s`;
};

/**
 *
 * @param date
 * @returns string
 */
export const formatDateForRequest = (date: Date) => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};
