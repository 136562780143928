/**
 * 
	 ___                               __  _____      __  __  _                 
   /   | ______________  __  ______  / /_/ ___/___  / /_/ /_(_)___  ____ ______
  / /| |/ ___/ ___/ __ \/ / / / __ \/ __/\__ \/ _ \/ __/ __/ / __ \/ __ `/ ___/
 / ___ / /__/ /__/ /_/ / /_/ / / / / /_ ___/ /  __/ /_/ /_/ / / / / /_/ (__  ) 
/_/  |_\___/\___/\____/\__,_/_/ /_/\__//____/\___/\__/\__/_/_/ /_/\__, /____/  
																 /____/        
 * 
 * Partial Component for Account Settings
 */

import React, { useState, useEffect, useRef, useReducer } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useDataContext } from "../../contexts/DataContext";
import app from "../../firebase";
import DropdownMenu from "../actions/DropdownMenu";
import NotificationSettings from "./NotificationSettings";

// import ReactGA from 'react-ga';
// ReactGA.initialize('UA-185771165-2');

const updateSelected = (state, action) => {
    switch (action.type) {
        case 'init':
            return action.data;
		case 'update':
			return action.data;
        case 'add':
            return [...state, action.data]
		case 'remove':
			return action.data;
        default:
            return state;
    }
}

export default function AccountSettings(props) {
	const { currentUser, updateEmail, logout, resetPassword } = useAuth();
	const { routes } = useDataContext();

	const emailNewRef = useRef();
	const emailNewConfRef = useRef();
	const [loadingEmailReset, setLoadingEmailReset] = useState(false);
	const [emailResetMessage, setEmailResetMessage] = useState("");

	const [loadingReset, setLoadingReset] = useState(false);
	const [resetMessage, setResetMessage] = useState("");

	const [customerData, setCustomerData] = useState();
	const [locationData, setLocationData] = useState();
	const [selectedLocations, setSelectedLocations] = useReducer(updateSelected, []);


	useEffect(async () => {
		let customerData = await getCustomerData();
		let locationData = await getLocationDBData(customerData.locations);
		console.log(customerData);
		console.log(locationData);
		setCustomerData(customerData);
		setLocationData(locationData);
		var selected_not = customerData['loc_notifications'].map(x => locationData.find(l => l.id === x));
		setSelectedLocations({type: 'init', data: selected_not})
		// ReactGA.pageview("AccountSettings");
	}, []);

	const submitUpdateEmail = async () => {
		// Extract values
		let newEmail = emailNewRef.current.value;
		let confEmail = emailNewConfRef.current.value;
		console.log("new", newEmail);
		console.log("conf", confEmail);

		// Checks
		if (newEmail === currentUser.email) {
			console.log("New email is same as current one");
			setEmailResetMessage("New email is the same as the current one");
			return;
		}
		if (newEmail !== confEmail) {
			console.log("Email addresses do not match");
			setEmailResetMessage("Email addresses do not match");
			return;
		}

		try {
			setEmailResetMessage("");
			setLoadingEmailReset(true);
			await updateEmail(newEmail);
			console.log("Reset email");
		} catch {
			setEmailResetMessage("Unable to reset email, please try again");
		}
		setLoadingEmailReset(false);
	};

	const handleReset = async () => {
		resetPassword(currentUser.email);
		setResetMessage("Check your email for instructions");
	};

	const getCustomerData = async () => {
		// Setup firestore connection
		const db = app.firestore();

		// Get the locations for the customer
		let customerData = await db
			.collection("customer")
			.doc(currentUser.uid)
			.get();
		customerData = customerData.data();

		return customerData;
	};

	const handleNotificationChange = async (locs) => {

		setSelectedLocations({
			type: 'update',
			data: locs
		})
		// Setup firestore connection
		const db = app.firestore();

		await db
			.collection("customer")
			.doc(currentUser.uid)
			.update({
				loc_notifications: locs.map(loc => loc.id),
			});
	};

	const getLocationDBData = async (lids) => {
		let params = JSON.stringify({ ids: lids });
		console.log(params);
		let token = await currentUser.getIdToken();
		let url = routes["getLocations"];
		const r = await fetch(url, {
			method: "POST",
			body: params,
			headers: {
				"Content-Type": "application/JSON",
				Authorization: `${token}`,
			},
		});
		let dat = await r.json();

		return dat;
	};

	const updatePrimaryLocation = (address) => {
		console.log(address);
		// Get Id for address
		// let id = locationData.filter((x) => x.address == address)[0].id;
		let match = locationData.find(d => d.address === address);
		if (match == undefined){
			match = locationData.find(d => d.display_name === address);
		}
		let id = match.id;
		console.log(id);

		// Update Firebase
		setCustomerData(undefined);
		const db = app.firestore();
		db.collection("customer")
			.doc(currentUser.uid)
			.update({
				primary_location: id,
			})
			.then(async () => {
				console.log("Primary Location Updated");
				let customerDataNew = await getCustomerData();
				setCustomerData(customerDataNew);
			});
	};

	const renderPrimaryLocationSelect = () => {
		if (customerData == undefined || locationData == undefined) {
			return (
				<button className="btn justify-between min-w-56 bg-white border-gray-200 hover:border-gray-300 text-gray-500 hover:text-gray-600 focus:outline-none">
					<div className="h-1 bg-gray-400 animate-pulse rounded w-full"></div>
				</button>
			);
		} else {
			// we have the data so render it
			let options = locationData.map(x => x.display_name || x.address);
			let selected = locationData.filter((x) => x.id == customerData.primary_location)[0];
			selected = selected.display_name || selected.address;
			return (
				<DropdownMenu
					// options={locationData.map((x) => x.address)}
					options={options}
					setOptions={updatePrimaryLocation}
					selected={selected}
					// selected={
					// 	locationData.filter((x) => x.id == customerData.primary_location)[0]
					// 		.address
					// }
				/>
			);
		}
	};

	const renderNotificationLocationSelect = () => {
		if (customerData == undefined || locationData == undefined || selectedLocations == undefined) {
			return (
				<button className="btn justify-between min-w-56 bg-white border-gray-200 hover:border-gray-300 text-gray-500 hover:text-gray-600 focus:outline-none">
					<div className="h-1 bg-gray-400 animate-pulse rounded w-full"></div>
				</button>
			);
		} else {			
			return (
				<NotificationSettings
					allLocations={locationData}
					selectedLocations={selectedLocations}
					handleNotificationChange={handleNotificationChange}
				/>
			);
		}
	};

	return (
		<>
			<section>
				<h2 className="text-xl text-gray-800 font-bold mb-1">Account</h2>
				<div className="text-sm">Email Address: {currentUser.email}</div>

				<div className="mt-5 border-t pt-2">
					<h3 className="font-bold">Change Account Email Address</h3>
					<div
						className={`w-full text-sm rounded-lg bg-blue-200 p-3 m-5 shadow-md ${
							emailResetMessage == "" ? "hidden" : ""
						}`}>
						{emailResetMessage}
						<button
							className="float-right"
							onClick={() => setEmailResetMessage("")}>
							X
						</button>
					</div>
					<div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
						<div className="sm:w-1/3">
							<label className="block text-sm font-medium mb-1" htmlFor="name">
								New Email
							</label>
							<input
								id="name"
								className="form-input w-full"
								type="email"
								ref={emailNewRef}
							/>
						</div>
						<div className="sm:w-1/3">
							<label
								className="block text-sm font-medium mb-1"
								htmlFor="business-id">
								Confirm New Email
							</label>
							<input
								id="business-id"
								className="form-input w-full"
								type="email"
								ref={emailNewConfRef}
							/>
						</div>
						<div className="sm:w-1/3">
							<button
								className="btn mt-7 hover:bg-blue-400 bg-white hover:text-white border-blue-400 text-blue-400 focus:outline-none"
								onClick={submitUpdateEmail}
								disabled={loadingEmailReset}>
								Update
							</button>
						</div>
					</div>
				</div>

				<div className="mt-5 border-t pt-2">
					<h3 className="font-bold">Set Default Location</h3>
					<div className="text-sm mb-2">
						This is the location that will be your default on the Cameras page
					</div>
					{renderPrimaryLocationSelect()}
				</div>

				<div className="mt-5 border-t pt-2">
					<h3 className="font-bold">Set Locations Notifications</h3>
					<div className="text-sm mb-2">
						You will receive push notification for every location on this list
					</div>
					{renderNotificationLocationSelect()}
				</div>

				<div className="mt-5 border-t">
					<div className="text-sm mt-8">
						If you would like to reset your password, you can click below, and
						you will be sent an email with further instructions
					</div>
					<div
						className={`w-full text-sm rounded-lg bg-red-200 p-3 my-2 shadow-md ${
							resetMessage == "" ? "hidden" : ""
						}`}>
						{resetMessage}
						<button className="float-right" onClick={() => setResetMessage("")}>
							X
						</button>
					</div>
					<button
						className="btn border-red-300 text-red-300 hover:text-white hover:bg-red-400"
						disabled={loadingReset}
						onClick={handleReset}>
						Reset Password
					</button>
					<div className="mt-8">
						<button
							className="btn border-yellow-300 text-yellow-500 hover:bg-yellow-300 hover:text-white"
							onClick={() => logout()}>
							Logout
						</button>
					</div>
				</div>
			</section>
		</>
	);
}
