// Helper functions that can be used anywhere in Analytics sub-pages

const dateToString = (d: Date) => {
  return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
};

const formatTime = (v: number) => {
  let m = Math.floor(v / 60);
  let s = Math.round(v % 60);
  return `${m}m ${s < 10 ? '0' + s : s}s`;
};

export { dateToString, formatTime };
