import React, { useEffect } from "react";

export default function CamerasPerPage({ numSlots, setNumSlots, allCameras }) {

	/**
	 * On load just make sure it isn't display a number that is ridiculous,
	 * i.e., more cameras than there are possible
	 */
	// useEffect(() => {
	// 	if (allCameras.length < numSlots){
	// 		setNumSlots(allCameras.length);			
	// 	}
	// }, [allCameras])

	const up = () => {
		setNumSlots(Math.min(allCameras.length, numSlots + 1));
	};

	const down = () => {
		setNumSlots(Math.max(1, numSlots - 1));
	};

	return (
		<>
			<div class="custom-number-input h-10 w-32 mt-3">
				
				<div class="flex flex-row h-10 w-full rounded-lg relative bg-transparent border border-gray-200">
					<button
						onClick={down}
						class=" bg-gray-100 text-gray-600 hover:text-gray-700 hover:bg-gray-300 h-full w-20 rounded-l cursor-pointer outline-none">
						<span class="m-auto text-2xl font-thin">−</span>
					</button>
					<input
						type="number"
						class="border-none focus:outline-none text-center w-full text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700 "
						name="custom-input-number"
						value={numSlots}></input>
					<button
						onClick={up}
						class="bg-gray-100 text-gray-600 hover:text-gray-700 hover:bg-gray-300 h-full w-20 rounded-r cursor-pointer">
						<span class="m-auto text-2xl font-thin">+</span>
					</button>
				</div>
				{/* <label
					for="custom-input-number"
					class="w-full text-gray-500 text-xs">
					Cameras Per Page
				</label> */}
			</div>
		</>
	);
}
