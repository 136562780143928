import React from 'react';
import Tooltip from '@mui/material/Tooltip';

export default function CountToggle({
  val,
  setVal,
}: {
  val: string;
  setVal: React.Dispatch<React.SetStateAction<string>>;
}) {
  return (
    <div className="inline-flex">
      <Tooltip title="Customer Counts" arrow placement="top">
        <button
          className={`btn p-1 ${
            val == '_count' ? 'bg-white shadow-sm' : 'bg-gray-100'
          } focus:outline-none border border-gray-100`}
          onClick={() => setVal('_count')}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-walk"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#9e9e9e"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <circle cx="13" cy="4" r="1" />
            <line x1="7" y1="21" x2="10" y2="17" />
            <path d="M16 21l-2 -4l-3 -3l1 -6" />
            <path d="M6 12l2 -3l4 -1l3 3l3 1" />
          </svg>
        </button>
      </Tooltip>
      <Tooltip title="Service Times" arrow placement="top">
        <button
          className={`btn p-1 ${
            val == '' ? 'bg-white shadow-sm' : 'bg-gray-100'
          } focus:outline-none border-gray-100`}
          onClick={() => setVal('')}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-clock"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#9e9e9e"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <circle cx="12" cy="12" r="9" />
            <polyline points="12 7 12 12 15 15" />
          </svg>
        </button>
      </Tooltip>
      <Tooltip title="Deviation from Target" arrow placement="top">
        <button
          className={`btn p-1 ${
            val == '_target_delta' ? 'bg-white shadow-sm' : 'bg-gray-100'
          } focus:outline-none border-gray-100`}
          onClick={() => setVal('_target_delta')}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-target"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#9e9e9e"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <circle cx="12" cy="12" r="1" />
            <circle cx="12" cy="12" r="5" />
            <circle cx="12" cy="12" r="9" />
          </svg>
        </button>
      </Tooltip>
    </div>
  );
}
