import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useDataContext } from "../contexts/DataContext";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import AnalyticsToolbar from "../partials/analytics2/AnalyticsToolbar";
import Loader from "../utils/Loader";
import TrendWidget from "../partials/analytics2/TrendWidget";
import * as d3 from 'd3';
import RegionWidget from "../partials/analytics2/RegionWidget";
import DayPartWidget from "../partials/analytics2/DayPartWidget";

// import ComparisonAnalytics from "../partials/analytics/ComparisonAnalytics";

export default function Analytics2() {
	const [sidebarOpen, setSidebarOpen] = useState(false);

	const [locations, setLocations] = useState();
	const [franchise, setFranchise] = useState();
	const [selectedLocations, setSelectedLocations] = useState();
	const [dates, setDates] = useState();
	const [dtToggle, setDtToggle] = useState();
    const [locationColorMap, setLocationColorMap] = useState()

	const MAX_LOCATIONS = 10;

	// Contexts
	const { currentUser } = useAuth();
	const { routes, getCacheData, setCache, getFBPreferences, setFBPreference } =
		useDataContext();

	useEffect(async () => {
		// Initial Load
		let allLocationsData = await getAllLocations();
		let prefs = await getFBPreferences();

		// Set initial States----------------
		setLocations(allLocationsData);
		// Franchise
		let franchises = allLocationsData.reduce((agg, cur) => {
			let x = !agg.map((x) => x.id).includes(cur.franchise.id)
				? agg.push(cur.franchise)
				: null;
			return agg;
		}, []);
		franchises.sort((a, b) => a.id - b.id);
		let _selectedFranchise;
		if (prefs["default_franchise"]) {
			_selectedFranchise = prefs["default_franchise"];
			if (franchises.map((x) => x.id).includes(_selectedFranchise.id)) {
				_selectedFranchise = franchises.find(
					(x) => x.id == _selectedFranchise.id
				);
				setFranchise(_selectedFranchise);
			} else {
				// Just pick one (the first)
				_selectedFranchise = franchises[0];
				setFranchise(_selectedFranchise);
			}
		} else {
			// Just pick one (the first)
			_selectedFranchise = franchises[0];
			setFranchise(_selectedFranchise);
		}

		// Selected Locations
		if (prefs["analytics_locations"]) {
			let selectedIds = prefs["analytics_locations"];
			let overlapLocations = allLocationsData.filter(
				(x) =>
					x.franchise.id == _selectedFranchise.id && selectedIds.includes(x.id)
			);
			if (overlapLocations.length > 0) {
				console.log(overlapLocations);
				setSelectedLocations(overlapLocations);
			} else {
				let overlapLocations = allLocationsData.filter(
					(x) => x.franchise.id == _selectedFranchise.id
				);
				setSelectedLocations(overlapLocations.slice(0, MAX_LOCATIONS));
			}
		} else {
			// Take the first 10 for the franchise
			let overlapLocations = allLocationsData.filter(
				(x) => x.franchise.id == _selectedFranchise.id
			);
			setSelectedLocations(overlapLocations.slice(0, MAX_LOCATIONS));
		}

		// Dates
		if (prefs["analytics_dates"]) {
			let startDate = loadDateString(prefs["analytics_dates"][0]);
			let endDate = loadDateString(prefs["analytics_dates"][1]);
			setDates([startDate, endDate]);
		} else {
			let startDate = new Date();
			startDate.setDate(startDate.getDate() - 1);
			let endDate = new Date();
			endDate.setDate(endDate.getDate() - 30);
			setDates([startDate, endDate]);
		}

		// Dt Toggle
		Object.keys(prefs).includes("analytics_dt");
		if (Object.keys(prefs).includes("analytics_dt")) {
			setDtToggle(prefs["analytics_dt"]);
		} else {
			setDtToggle(false);
		}
	}, []);

	// Hooks for when the states change so we can update firstore ---------------
	useEffect(() => {
		if (franchise != undefined && selectedLocations != undefined) {
			setFBPreference("default_franchise", franchise);

			// if not selected locations overlap, reset them
			let overlap = selectedLocations.filter(
				(x) => x.franchise.id == franchise.id
			);
			if (overlap.length == 0) {
				setSelectedLocations(
					locations
						.filter((x) => x.franchise.id == franchise.id)
						.slice(0, MAX_LOCATIONS)
				);
			}
		}
	}, [franchise]);

	useEffect(() => {
		if (selectedLocations != undefined) {
			setFBPreference(
				"analytics_locations",
				selectedLocations.map((x) => x.id)
			);

            // Define new color mapping for locations
            let ids = selectedLocations.map(x => x.id);
            ids.sort();
            let colors = {};
            ids.forEach((id, i) => colors[id] = d3.schemeCategory10[i]);
            setLocationColorMap(colors);
		}
	}, [selectedLocations]);

	useEffect(() => {
		if (dates != undefined) {
			let d1 = dates[0].toISOString().slice(0, 10);
			let d2 = dates[1].toISOString().slice(0, 10);
			setFBPreference("analytics_dates", [d1, d2]);
		}
	}, [dates]);

	useEffect(() => {
		if (dtToggle != undefined) {
			setFBPreference("analytics_dt", dtToggle);
		}
	}, [dtToggle]);

	const loadDateString = (ds) => {
		ds = ds.split("-").map((s) => parseInt(s));
		let d = new Date(ds[0], ds[1] - 1, ds[2]);
		return d;
	};

	const getAllLocations = async () => {
		let key = `customerLocationsData-analytics`;
		let cacheData = getCacheData(key);
		if (cacheData != undefined) {
			return cacheData;
		}

		// Else, make querry
		let url = routes["customerLocations"];
		let resp = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/JSON",
			},
			body: JSON.stringify({ uid: currentUser.uid }),
		}).catch((err) => {
			console.warn(err);
			return undefined;
		});

		// Catch any error
		if (resp == undefined || !resp.ok) {
			console.warn("Issue getting data");
			return [];
		}

		// Process the response if good
		let data = await resp.json();

		setCache(key, data);
		return data;
	};

	let statesLoaded = !(
		locations == undefined ||
		franchise == undefined ||
		dates == undefined ||
		selectedLocations == undefined ||
		dtToggle == undefined
	);

	return (
		<div className="flex h-screen overflow-hidden">
			{/* Sidebar */}
			<Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

			{/* Content area */}
			<div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden" id="analyticsPage">
				{/*  Site header */}
				<Header
					sidebarOpen={sidebarOpen}
					setSidebarOpen={setSidebarOpen}
					title="Analytics"
				/>

				<main>
					{/* This is where tab nav would go for Comparison and Trend */}
					{/* <ComparisonAnalytics /> */}
					{!statesLoaded ? (
						<Loader />
					) : (
						<div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
							<AnalyticsToolbar
								locations={locations}
								franchise={franchise}
								setFranchise={setFranchise}
								selectedLocations={selectedLocations}
								setSelectedLocations={setSelectedLocations}
								dates={dates}
								setDates={setDates}
								dtToggle={dtToggle}
								setDtToggle={setDtToggle}
							/>

							{/* <div className="grid xl:grid-cols-2 grid-cols-1 gap-4"> */}
                            <div className='grid gap-4 grid-cols-1'>
								{/* <div className='bg-white rounded-lg shadow-lg p-4 w-full'>
                                    Place Holder
                                </div> */}
                                <TrendWidget 
                                    selectedLocations={selectedLocations}
                                    dtToggle={dtToggle}
                                    dates={dates}
                                    colors={locationColorMap}/>

                                <RegionWidget
                                    selectedLocations={selectedLocations}
                                    dtToggle={dtToggle}
                                    dates={dates}
                                    colors={locationColorMap} />

                                <DayPartWidget
                                    selectedLocations={selectedLocations}
                                    dtToggle={dtToggle}
                                    dates={dates}
                                    colors={locationColorMap} />
							</div>
						</div>
					)}
				</main>
			</div>
		</div>
	);
}
