import React, { useState, useEffect } from 'react';
import { useDataContext } from '../../contexts/DataContext';
import DropdownMenu from '../actions/DropdownMenu';
import TableLoader from '../dashboard/TableLoader';
import dateFormat from 'dateformat';
import app from '../../firebase';
import { utcStringToLocalDate, formatSeconds } from '../../helpers/time';
import TimeArrivalLink from './TimeArrivalLink';
import DownloadButton from '../../components/buttons/DownloadButton';
import DownloadCsvModal from '../../components/modals/DownloadCsvModal';

// For now we are just going to do a single location, but later
// we will want a location drop down....

export default function CarLineTable({
  customerLocationData,
  franchise,
  date,
  isDriveThru,
  selectedLocation,
  setDate,
  setIsLive,
}) {
  const [location, setLocation] = useState();
  const [detectionData, setDetectionData] = useState();
  const [regionData, setRegionData] = useState();
  const [locationPreferences, setLocationPreferences] = useState();
  const [regionDisplayPrefs, setRegionDisplayPrefs] = useState();
  const [sortDirection, setSortDirection] = useState(-1);
  const [sortColumn] = useState('first_seen_datetime_utc');
  const [lastClickedRowId, setLastClickedRowId] = useState();
  const [isDownloading, setIsDownloading] = useState(false);

  const { routes } = useDataContext();

  console.log('selectedLocation', selectedLocation);
  console.log('location', location);

  useEffect(() => {
    // On load, set location
    let possibleLocations = customerLocationData.filter(
      (x) => x.franchise.id === franchise.id
    );
    if (selectedLocation) {
      setLocation(selectedLocation);
    } else {
      setLocation(possibleLocations[0]);
    }
  }, []);

  // When selected franchise changes, upate location dropdown and selected
  useEffect(() => {
    let possibleLocations = customerLocationData.filter(
      (x) => x.franchise.id === franchise.id
    );
    if (selectedLocation) {
      setLocation(selectedLocation);
    } else {
      setLocation(possibleLocations[0]);
    }
  }, [franchise]);

  // When location changes, get data
  useEffect(() => {
    const abortController = new AbortController();
    let requested = false;
    if (location !== undefined && date !== undefined) {
      requested = true;
      getTableData2({ controller: abortController });
    }
    const id = setInterval(refreshData, 5 * 60 * 1000);
    return () => {
      clearInterval(id);
      if (requested) {
        abortController.abort();
      }
    };
  }, [location, date, isDriveThru]);

  /**
   * Handles when a column was clicked.
   * Cases:
   *  first click on this column: set as sort column and set direction
   *  second click: keep sort column, flip direction
   *  third click: unset as sort column
   *
   * As we know this is the order, we can use the sort direction to help
   * us determine which number click it is
   * @param {String} col column name that was clicked
   */
  const colClick = (col) => {
    // Else, this is already the sort column, so rely on the sort direction
    if (sortDirection === -1) {
      // this is the second click
      setSortDirection(1);
    } else {
      // This is the third click--reset all
      setSortDirection(-1);
    }
  };

  const sortData = (data) => {
    console.log(data);
    console.log(sortColumn);
    // Base case, sort by rank
    if (sortColumn == undefined) {
      return data.sort((a, b) => a.day_avg - b.day_avg);
    }

    // Column specific
    return data.sort((a, b) => {
      a = a[sortColumn];
      b = b[sortColumn];

      // always push undefined to the bottom
      if (a == undefined || a == null || b == undefined || b == null) {
        return (a == undefined || a == null) - (b == undefined || b == null);
      }

      let dir = a > b ? 1 : -1;
      return dir * sortDirection;
    });
  };

  const getTableData2 = async ({
    override = false,
    controller = new AbortController(),
  }) => {
    // Clear out existsing
    setDetectionData(undefined);
    setRegionData(undefined);
    setLocationPreferences(undefined);

    // Fetch new
    let sendDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;
    let params = {
      location_id: location.id,
      start_date: sendDate,
      end_date: sendDate,
    };

    let url = routes['fullDetectionData'];
    let resp = await fetch(url, {
      // signal: controller.signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/JSON',
      },
      body: JSON.stringify(params),
    });

    let data = await resp.json();

    // get Preferences
    let regionPrefs;
    let regionDisplays;
    const db = app.firestore();
    let doc = await db.collection('location').doc(location.id.toString()).get();
    if (doc.exists) {
      let prefData = doc.data();

      if (isDriveThru) {
        regionPrefs = prefData['drive_thru_regions2'];
      } else {
        regionPrefs = prefData['lobby_regions2'];
      }

      // Get drive thru region if it has them
      if (Object.keys(prefData).includes('drive_thru_region_displays')) {
        regionDisplays = prefData['drive_thru_region_displays'];
        setRegionDisplayPrefs(regionDisplays);
      } else {
        setRegionDisplayPrefs(undefined);
      }
    }

    // // Set states
    setDetectionData(data.detection.filter((x) => x.model_id != -7));
    setRegionData(data.regions);
    setLocationPreferences(regionPrefs);
  };

  const refreshData = () => {
    if (location !== undefined) {
      getTableData2({ override: true });
    }
  };

  const setLocationHelper = (display_name) => {
    // find matching location
    let loc = customerLocationData
      .filter((x) => x.franchise.id === franchise.id)
      .filter((x) => x.display_name == display_name)[0];
    setLocation(loc);
  };

  const renderDropdown = () => {
    if (customerLocationData != undefined && location != undefined) {
      // filter out locations.... idk if we should or not?

      return (
        <DropdownMenu
          options={customerLocationData
            .filter((x) => x.franchise.id === franchise.id)
            .map((x) => x.display_name)}
          setOptions={setLocationHelper}
          selected={location.display_name}
        />
      );
    }
  };

  const renderTable = () => {
    if (detectionData === undefined || regionData === undefined) {
      return (
        <TableLoader
          columns={[
            franchise.id === 6 ? 'Departure Time' : 'Arrival Time',
            'Order Time',
            'Pickup Time',
            'Total Time',
          ]}
        />
      );
    }

    if (detectionData.length === 0) {
      return <div className="w-full text-center text-gray-400">No Data</div>;
    }

    if (isDriveThru) {
      return renderDTTable();
    } else {
      return renderLobbyTable();
    }
  };

  const renderDTTable = () => {
    if (locationPreferences == undefined) {
      return (
        <TableLoader
          columns={[
            franchise.id === 6 ? 'Departure Time' : 'Arrival Time',
            'Order Time',
            'Pickup Time',
            'Total Time',
          ]}
        />
      );
    }

    // Determine table columns
    let columns;
    let columnKeys;
    if (regionDisplayPrefs === undefined) {
      // We use the default drive thru columns
      columns = ['Arrival Time', 'Order Time', 'Pickup Time'];
      columnKeys = ['order', 'leave'];
    } else {
      // We use the display columns
      columns = ['Arrival Time'].concat(
        regionDisplayPrefs.map((x) => {
          // find matching locationPref region
          let match = locationPreferences.find((l) => l.key == x);
          if (match === undefined) {
            console.log('No match');
            return null;
          }
          return match['display_name'];
        })
      );
      columnKeys = regionDisplayPrefs;
    }
    columns = columns.concat(['Total Time']);

    // Make table data
    let tableData = [];
    detectionData
      .filter(
        (x) =>
          x.time_spent >= 30 &&
          x.time_spent <= 2000 &&
          x.is_drive_through === isDriveThru &&
          x.model_id !== -7
      )
      .forEach((x) => {
        // get all matching regions
        let regionMatches = regionData.filter((r) => r.detection_id === x.id);
        let d = {
          id: x.id,
          first_seen_datetime_utc: x.first_seen_datetime_utc,
          time_spent: x.time_spent,
        };
        columnKeys.forEach((k) => {
          let regMatch = regionMatches.find((x) => x.region === k);
          if (regMatch === undefined) {
            // no such region for this detection, set to 0
            d[k] = '--';
          } else {
            d[k] = regMatch.time_spent;
          }
        });
        tableData.push(d);
      });

    // Handle data sorting
    let sorted = sortData(tableData);

    return (
      <div className="max-h-96 overflow-y-auto">
        <table className="table-fixed w-full divide-y divide-gray-200 min-w-98">
          <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
            <tr>
              {columns.map((col) => {
                return (
                  <th
                    className="p-2 w-24 sticky top-0 bg-gray-50"
                    key={`header-${col}`}
                    onClick={() => colClick(col)}
                  >
                    <div className="text-left cursor-pointer select-none">
                      {col}
                      {col === 'Arrival Time' ? (
                        <span className="ml-1">
                          {sortDirection === -1 ? <>&#9650; </> : <>&#9660;</>}
                        </span>
                      ) : null}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 text-xs">
            {sorted.map((x) => {
              let className = 'hover:bg-gray-100 cursor-default my-4';
              if (x.id === lastClickedRowId) {
                className += ' bg-gray-200';
              }
              return (
                <tr className={className} key={x.id}>
                  <td className="p-1">
                    <TimeArrivalLink
                      utcTime={x.first_seen_datetime_utc}
                      location={location}
                      setDate={setDate}
                      handleClick={() => {
                        setLastClickedRowId(x.id);
                      }}
                      setIsLive={setIsLive}
                    />
                  </td>
                  {columnKeys.map((k, i) => {
                    return (
                      <td className={`p-1`} key={`${x.id}-${i}`}>
                        {formatSeconds(x[k])}
                      </td>
                    );
                  })}
                  <td className="p-1">{formatSeconds(x['time_spent'])}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const renderLobbyTable = () => {
    if (locationPreferences == undefined) {
      return (
        <TableLoader
          columns={[
            franchise.id === 6 ? 'Departure Time' : 'Arrival Time',
            'Order Time',
            'Pickup Time',
            'Total Time',
          ]}
        />
      );
    }

    let columns = ['Arrival Time']
      .concat(locationPreferences.map((x) => x.display_name))
      .concat(['Total']);

    return (
      <div className="max-h-96 overflow-y-auto">
        <table className="table-fixed w-full divide-y divide-gray-200 min-w-98">
          <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
            <tr>
              {columns.map((col) => {
                return (
                  <th
                    className="p-2 w-24 sticky top-0 bg-gray-50"
                    key={`header-${col}`}
                  >
                    <div className="text-left">{col}</div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 text-xs">
            {detectionData
              .filter(
                (x) =>
                  x.time_spent >= 30 &&
                  x.time_spent <= 2000 &&
                  x.is_drive_through === isDriveThru &&
                  x.model_id != -7
              )
              .map((x) => {
                // get matching regions
                let regionMatches = regionData.filter(
                  (r) => r.detection_id == x.id
                );

                let className = 'hover:bg-gray-100 cursor-default my-4';
                if (x.id === lastClickedRowId) {
                  className += ' bg-gray-200';
                }

                return (
                  <tr className={className} key={x.id}>
                    <td className="p-1">
                      <TimeArrivalLink
                        utcTime={x.first_seen_datetime_utc}
                        location={location}
                        setDate={setDate}
                        handleClick={() => {
                          setLastClickedRowId(x.id);
                        }}
                        setIsLive={setIsLive}
                      />
                    </td>
                    {locationPreferences.map((reg, i) => {
                      // find matching data for this region
                      let match = regionMatches.filter(
                        (r) => r.region == reg.key
                      );
                      if (match.length === 0) {
                        // no region, so return an empty
                        return (
                          <td className="p-1" key={`${x.id}-${i}`}>
                            --
                          </td>
                        );
                      }
                      match = match[0];
                      return (
                        <td className="p-1" key={`${x.id}-${i}`}>
                          {formatSeconds(match.time_spent)}
                        </td>
                      );
                    })}
                    <td className="p-1">{formatSeconds(x.time_spent)}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  };

  const download = () => {
    if (isDriveThru) {
      downloadDT();
    } else {
      downloadLobby();
    }
  };

  // Downloads table as csv if we have the data
  // TODO update this logic for region maddness
  const downloadDT = () => {
    if (detectionData === undefined || regionData === undefined) {
      return;
    }

    let columns;
    let columnKeys;
    if (regionDisplayPrefs === undefined) {
      // We use the default drive thru columns
      columns = ['Store', 'Arrival Time', 'Order Time', 'Pickup Time'];
      columnKeys = ['order', 'leave'];
    } else {
      // We use the display columns
      columns = ['Store', 'Arrival Time'].concat(
        regionDisplayPrefs.map((x) => {
          // find matching locationPref region
          let match = locationPreferences.find((l) => l.key == x);
          if (match === undefined) {
            return null;
          }
          return match['display_name'];
        })
      );
      columnKeys = regionDisplayPrefs;
    }
    columns = columns.concat(['Total Time']);

    // Make table data
    let data = [columns];
    detectionData
      .filter(
        (x) =>
          x.time_spent >= 30 &&
          x.time_spent <= 2000 &&
          x.is_drive_through === isDriveThru &&
          x.model_id !== -7
      )
      .forEach((x) => {
        // get all matching regions
        let date = utcStringToLocalDate(
          x.first_seen_datetime_utc,
          location.time_zone
        );
        let formatted = dateFormat(date, 'mediumTime');
        let regionMatches = regionData.filter((r) => r.detection_id === x.id);
        let d = [location.display_name, formatted];
        columnKeys.forEach((k) => {
          let regMatch = regionMatches.find((x) => x.region === k);
          if (regMatch === undefined) {
            // no such region for this detection, set to 0
            d.push(null);
          } else {
            // d[k] = regMatch.time_spent;
            d.push(regMatch.time_spent);
          }
        });
        d.push(x.time_spent);
        data.push(d);
      });
    console.log(data);

    let csvContent = 'data:text/csv;charset=utf-8,';

    data.forEach(function (rowArray) {
      let row = rowArray.join(',');
      csvContent += row + '\r\n';
    });

    let encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  };

  const downloadLobby = () => {
    if (
      detectionData === undefined ||
      regionData === undefined ||
      locationPreferences === undefined
    ) {
      return;
    }

    // create data
    let data = [
      ['Store', 'Arrival Time']
        .concat(locationPreferences.map((x) => x.display_name))
        .concat(['Total']),
    ];
    detectionData
      .filter(
        (x) =>
          x.time_spent >= 30 &&
          x.time_spent <= 2000 &&
          x.is_drive_through === isDriveThru &&
          x.model_id !== -7
      )
      .forEach((d) => {
        let regionMatches = regionData.filter((r) => r.detection_id == d.id);
        let date = utcStringToLocalDate(
          d.first_seen_datetime_utc,
          location.time_zone
        );
        let formattedDate = dateFormat(date, 'mediumTime');
        let row = [location.display_name, formattedDate];
        locationPreferences.forEach((reg) => {
          let match = regionMatches.filter((r) => r.region == reg.key);
          if (match.length === 0) {
            row.push('');
          } else {
            row.push(match[0].time_spent);
          }
        });
        row.push(d.time_spent);
        data.push(row);
      });

    // create download
    let csvContent = 'data:text/csv;charset=utf-8,';

    data.forEach(function (rowArray) {
      let row = rowArray.join(',');
      csvContent += row + '\r\n';
    });

    // Download
    let encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  };

  return (
    <>
      <div className="flex flex-col col-span-full bg-white shadow-lg rounded-lg border border-gray-200 mb-8">
        <header className="px-5 py-4 border-b border-gray-100">
          <div>
            <h2 className="font-semibold text-gray-800 inline">Data</h2>

            <div className="flex flex-wrap gap-2 items-center justify-center float-right">
              {!selectedLocation && renderDropdown()}
              <DownloadButton
                handleDownload={() => {
                  setIsDownloading(true);
                }}
              />
              <DownloadCsvModal
                isOpen={isDownloading}
                onClose={() => {
                  setIsDownloading(false);
                }}
                customerLocations={customerLocationData}
              />
              <button
                className="button bg-transparent hover:bg-blue-400
								text-blue-400 hover:text-white rounded-full
								border border-blue-400 py-1 px-2 text-xs"
                style={{ maxHeight: '2.5em' }}
                onClick={() => refreshData()}
              >
                Refresh
              </button>
            </div>
          </div>
        </header>
        <div className="p-3">
          {/* Table */}
          <div className="overflow-x-auto">{renderTable()}</div>
        </div>
      </div>
    </>
  );
}
