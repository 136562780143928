import React, { useState } from 'react';
import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';

import CameraOptions from '../partials/Hidden/CameraOptions_hidden';
import VideoGrid from '../partials/Hidden/VideoGrid_hidden';

export default function Hidden() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // States
  let today = new Date();
  let yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  yesterday.setHours(12);
  yesterday.setMinutes(30);

  const [videoDateTime, setVideoDateTime] = useState(yesterday);
  const [isLive, setIsLive] = useState(false);
  const customerLocations = [
    {
      address: '3939 Crenshaw Blvd.',
      address_2: 'M-4',
      city: 'Los Angeles',
      country: 'USA',
      franchise_id: 1,
      has_drive_through: false,
      id: 2,
      reference: null,
      state: 'CA',
      time_zone: 'America/Los_Angeles',
      zipcode: '90008',
    },
  ];
  const [currentLocation, setCurrentLocation] = useState(customerLocations[0]);
  // const currentLocation = customerLocations[0];

  /**
   * Takes in string address and sets the current location
   * with the full location data (not just the string address)
   * @param {STring} address
   */
  const updateCustomerLocation = (address) => {
    // find the piece of data with matching address
    let match = customerLocations.find((d) => d.address === address);
    setCurrentLocation(match);
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          title="Secret Video &#128520;"
        />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="bg-white shadow-lg rounded-lg mb-2">
              <div className="flex flex-row">
                <CameraOptions
                  videoDateTime={videoDateTime}
                  setVideoDateTime={setVideoDateTime}
                  isLive={isLive}
                  setIsLive={setIsLive}
                  customerLocations={customerLocations}
                  currentLocation={currentLocation}
                  updateCustomerLocation={updateCustomerLocation}
                />
              </div>
            </div>

            {/* Camera Grid Display */}
            <div className="mt-2">
              <VideoGrid
                currentLocation={currentLocation}
                videoDateTime={videoDateTime}
                setVideoDateTime={setVideoDateTime}
                isLive={isLive}
                setIsLive={setIsLive}
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
