/**
	__                     __  _                        
   / /   ____  _________ _/ /_(_)___  ____              
  / /   / __ \/ ___/ __ `/ __/ / __ \/ __ \             
 / /___/ /_/ / /__/ /_/ / /_/ / /_/ / / / /             
/_____/\____/\___/\__,_/\__/_/\____/_/ /_/___           
		  /   | ______________  _________/ (_)___  ____ 
		 / /| |/ ___/ ___/ __ \/ ___/ __  / / __ \/ __ \
		/ ___ / /__/ /__/ /_/ / /  / /_/ / / /_/ / / / /
	   /_/  |_\___/\___/\____/_/   \__,_/_/\____/_/ /_/ 
														
 * Kenneth Lipke
 * Internal widget that builds the accordion to set day part preferences for
 * a given location.
 * Takes in the day part preferences, and has inputs to change the start
 * and end times, as well as the name for the day parts
 * We also have the ability to add and remove day parts
 * THere is a button to save the current work, which makes a call to firestore
 */

import React, { useState, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import app from "../../firebase";
import uuid from "react-uuid";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { createTheme } from "@material-ui/core/styles";
import { useDataContext } from "../../contexts/DataContext";
import { useAuth } from "../../contexts/AuthContext";

// import {addDayPart} from "./dayPartHelpers";

const smallTheme = createTheme({
	typography:{
		fontSize: 10,
	},
});

export default function LocationAccordion(props) {
	let dpd = props.dayPartData.map((a) => Object.assign({}, a));
	dpd.forEach((d) => {
		d["id"] = uuid();
	});
	const [dayPartData, setDayPartData] = useState(dpd);
	const [loading, setLoading] = useState(false);
	const [displayName, setDisplayName] = useState(props.displayName);
	const displayNameRef = useRef();

	const { routes } = useDataContext();
	const { currentUser } = useAuth();

	/**
	 * Test function which shows how to update a document in firestore
	 */
	const addTest = () => {
		console.log("Adding test location");
		let newArray = dayPartData.map((a) => Object.assign({}, a));

		// strip the ID's
		newArray.forEach((d) => delete d["id"]);

		const db = app.firestore();
		db.collection("location").doc("-1").update({
			day_part_preferences: newArray,
			location_id: -1,
		});
	};

	const addDayPart = () => {
		console.log("adding day part");
		let defaultVals = {
			name: "Day Part Name",
			from: "01:00",
			to: "02:00",
			time: "300",
			percentage: "50",
		};
		let newArray = dayPartData.map((a) => Object.assign({}, a));
		newArray.push(defaultVals);
		setDayPartData(newArray);
	};

	/**
	 * Removes the day part with the passed data
	 * Uses the index to remove it
	 * @param {int} iRemove index to be removed
	 */
	const removeDayPart = (iRemove) => {
		let newArray = dayPartData.map((a) => Object.assign({}, a));
		newArray = newArray.filter((d, i) => i != iRemove);
		setDayPartData(newArray);
	};

	const updateValue = (index, which, event) => {
		// Create new data to slot updated value in
		let newArray = dayPartData.map((a) => Object.assign({}, a));

		// Deal with time entries
		if (which == "to" || which == "from") {
			let t = event.target.valueAsNumber / (60 * 60 * 1000);
			let hour = Math.floor(t);
			let min = Math.round(60 * (t % 1));
			if (min < 10) {
				min = "0" + min;
			}
			if (hour < 10){
				hour = "0" + hour;
			}
			newArray[index][which] = `${hour}:${min}`;
			console.log(`${hour}:${min}`);
		} else {
			// Deal with standard entries
			newArray[index][which] = event.target.value;
		}

		// Update the state
		setDayPartData(newArray);
	};

	const timeStringToFloat = (s) => {
		let l = s.split(":");
		let t = l[0] * 1 + l[1] / 60;
		return t;
	};

	/**
	 * Takes an array of values, and returns a sub array of only values
	 * that have been duplicated
	 * @param {Array} l array of values
	 */
	const getDuplicatedValues = (l) => {
		let uniq = l
			.map((name) => {
				return {
					count: 1,
					name: name,
				};
			})
			.reduce((a, b) => {
				a[b.name] = (a[b.name] || 0) + b.count;
				return a;
			}, {});

		let duplicates = Object.keys(uniq).filter((a) => uniq[a] > 1);

		return duplicates;
	};

	const isDuplicateName = (name) => {
		let names = dayPartData.map((x) => x.name);
		let dupNames = getDuplicatedValues(names);
		return true ? dupNames.includes(name) : false;
	};

	/**
	 * Check to see if there is overlap with the given time period
	 * with any others in the list.
	 * Returns TRUE if there is overlap (i.e., show an error)
	 * @param {String} from
	 * @param {String} to
	 */
	const isBadTime = (from, to, id) => {
		/**
		 * There are three different scenarios
		 * (1)
		 * totally contained within another, from > dFrom and to < dTo
		 *
		 * (2)
		 * starts before but ends within (to > dFrom and to < dTo)
		 *
		 * (3)
		 * starts within, from > dFrom and from < dTo
		 */
		let overlap = false;
		let message = "";
		let fromTime = timeStringToFloat(from);
		let toTime = timeStringToFloat(to);

		dayPartData.forEach((d) => {
			let dFrom = timeStringToFloat(d.from);
			let dTo = timeStringToFloat(d.to);
			let dId = d.id;

			if (dId == id) {
				// Looking at self, so ignore
				return;
			}

			// (1)
			if ((fromTime > dFrom) & (toTime < dTo)) {
				overlap = true;

				// edge case when this straddles midnight
				if ((fromTime >= 21) & (toTime <= 4)) {
					overlap = false;
				}
			}

			// (2)
			if ((toTime > dFrom) & (toTime < dTo)) {
				overlap = true;
			}

			// (3)
			if ((fromTime > dFrom) & (fromTime < dTo)) {
				overlap = true;
			}
		});

		// Check to see if works with self
		let selfConflict = false;
		if ((fromTime >= 21) & (toTime <= 4)) {
			// Case where tick past midnight is chill
			selfConflict = false;
		} else {
			// standard rules apply
			if (fromTime > toTime) {
				selfConflict = true;
			}
		}

		// Determine the message
		let msg = "";
		if (overlap) {
			msg = "Cannot overlap with another day part";
		}
		if (selfConflict) {
			msg = "Start time must be before end time";
		}

		// return overlap;
		return [overlap || selfConflict, msg];
	};

	/**
	 * Checks the current state of the prefs
	 * NOT USED
	 */
	const checkPrefs = () => {
		// Checks if dupliate name
		let names = dayPartData.map((x) => x.name);
		let dupNames = getDuplicatedValues(names);

		// For the duplicate names, change the error state
		let newArray = dayPartData.map((a) => Object.assign({}, a));
		newArray.forEach((d) => {
			if (dupNames.includes(d.name)) {
				d["name_error"] = true;
			}
		});

		setDayPartData(newArray);
	};

	const runSaveChecks = () => {
		// Iterate over all and check times and names
		let isError = false;
		dayPartData.forEach(d => {
			let nameError = isDuplicateName(d.name);
			let timeError = isBadTime(d.from, d.to, d.id)[0];
			isError = isError || (nameError || timeError);
		});
		if (isError){
			return false;
		}

		// Made it this far, all good
		return true
	}

	const save = () => {
		console.log("saving");

		let checkStatus = runSaveChecks();
		if (!checkStatus){
			return 
		}

		// Disable buttons so they cant cluck up things while we are working
		setLoading(true);

		// Reorder
		let newArray = dayPartData.map((a) => Object.assign({}, a));
		newArray.sort((a, b) => {
			let at = timeStringToFloat(a.from);
			let bt = timeStringToFloat(b.from);
			return at - bt;
		});

	
		// strip the ID's
		newArray.forEach((d) => delete d["id"]);

		// Ensure, that time and percentage are in fact numbers
		newArray.forEach((d) => {
			d['time'] = parseInt(d['time']);
			d['percentage'] = parseInt(d['percentage']);
		});

		// Make the DB call
		const db = app.firestore();
		// console.log(props.location_id);
		db.collection("location")
			.doc(props.location_id.toString())
			.update({
				day_part_preferences: newArray,
				location_id: props.location_id,
			})
			.then(() => {
				setLoading(false)
			});

		// re-add an id
		newArray.forEach((d) => {
			d["id"] = uuid();
		});

		// Set new state
		setDayPartData(newArray);
	};

	/**
	 * Does prep to save preferences for ALL locations
	 * Locally (in this component) runs the checks (as in normal save)
	 * 
	 * If all the checks pass, calls the parentSaveForAll method
	 * that is passed as a prop, which runs the data up the flag pole
	 * and saves it for all locations (which should trigger a re-render)
	 * and update for everyone. 
	 */
	const saveForAll = () => {
		// Run the checks
		let checkStatus = runSaveChecks();
		if (!checkStatus){
			return 
		}

		// Disable buttons so they cant cluck up things while we are working
		setLoading(true);

		// Reorder
		let newArray = dayPartData.map((a) => Object.assign({}, a));
		newArray.sort((a, b) => {
			let at = timeStringToFloat(a.from);
			let bt = timeStringToFloat(b.from);
			return at - bt;
		});

		// strip the ID's
		newArray.forEach((d) => delete d["id"]);

		props.allSaveFn(newArray);

		setLoading(false);
	}

	const renderNamePrefs = () => {

		return(
			<>
				<div>
					Address: {props.address}
				</div>
				
				<div className='mt-1 mb-1'>
					Display Name <input 
						type='text' 
						ref={displayNameRef}
						placeholder={displayName}
						className={`form-input
							p-2 text-xs`}/>
					<button className='btn border-blue-400 text-blue-400 hover:text-white hover:bg-blue-400 text-xs ml-3'
						onClick={updateDisplayName}>
						Update Display Name
					</button>
				</div>
			</>
		)
	}

	const updateDisplayName = async () => {
		// Takes whatever is in the ref and updates (unless it is blank)
		let newVal = displayNameRef.current.value;
		console.log(newVal);
		if ( (newVal === '') || (newVal === displayName) ){
			// do nothing
			return;
		}
		let params = {
			'location_id': props.location_id,
			'display_name': newVal
		}
		let url = routes['setDisplayName'];
		let token = await currentUser.getIdToken();
		fetch(url, {
			method: "POST",
			body: JSON.stringify(params),
			headers: {
				"Content-Type": "application/JSON",
				Authorizations: `${token}`
			}
		})
		.then((resp) => resp.json())
		.then((data) => {
			console.log(data);
			setDisplayName(newVal);
		})
		.catch(err => {
			window.alert("There was an error updating this location's display name");
		});
		
	}

	const displayTime = (s) => {
		let time = s.split(":");
		let hours = time[0];
		let minutes = time[1];
		hours = hours.length < 2 ? '0' + hours : hours;
		return `${hours}:${minutes}`;
	}


	/**
	 * Need to figure out how to sort (on each render) by the start time,
	 * so they always show up in order
	 * then we need to think about how we go about saving
	 * We also need some validation, we want to make sure that
	 * the end time is AFTER the start time, and we do not have any overlap in
	 * the day parts... do we also want to make sure that we do not have any
	 * repeat names
	 */

	/**
	 * Helper function to render the prefernces for the location
	 * from props.dayPartData
	 */
	const renderPrefs = () => {
		return dayPartData.map((d, i) => (
			<div
				className='grid grid-cols-1 sm:grid-cols-6 mb-2 rounded-sm even:bg-gray-100'
				key={d.id}>
				<div className='m-1'>
					<TextField
						key={`name_${d.id}`}
						label="Day Part Name"
						defaultValue={d.name}
						onChange={(event) => updateValue(i, "name", event)}
						error={isDuplicateName(d.name)}
						size='small'
						helperText={isDuplicateName(d.name) ? "duplicate name" : ""}
					/>
				</div>
				<div className='m-1'>
					<TextField
						key={`from_${d.id}`}
						id="from"
						label="Start Time"
						type="time"
						// defaultValue={d.from}
						defaultValue={displayTime(d.from)}
						onChange={(event) => updateValue(i, "from", event)}
						error={isBadTime(d.from, d.to, d.id)[0]}
						helperText={isBadTime(d.from, d.to)[1]}
					/>
				</div>
				<div className='m-1'>
					<TextField
						key={`to_${d.id}`}
						id="to"
						label="End Time"
						type="time"
						// defaultValue={d.to}
						defaultValue={displayTime(d.to)}
						onChange={(event) => updateValue(i, "to", event)}
						error={isBadTime(d.from, d.to, d.id)[0]}
						helperText={isBadTime(d.from, d.to)[1]}
					/>
				</div>
				<div className='m-1'>
					<TextField
						key={`target_${d.it}`}
						label="Target Time"
						defaultValue={d.time}
						type="number"
						InputProps={{
							endAdornment: <InputAdornment position="end">s</InputAdornment>,
						}}
						onChange={(event) => updateValue(i, "time", event)}
					/>
				</div>
				<div className='m-1'>
					<TextField
						key={`pct_${d.id}`}
						label="Target %"
						defaultValue={d.percentage}
						type="number"
						InputProps={{
							endAdornment: <InputAdornment position="end">%</InputAdornment>,
						}}
						onChange={(event) => updateValue(i, "percentage", event)}
					/>
				</div>
				<div className="d-flex align-content-center justify-content-end">
					<button 
						className='btn border-red-500 text-red-500 hover:bg-red-500 hover:text-white rounded-full text-xs p-1 ml-3 mt-2'
						variant="outline-danger" 
						onClick={() => removeDayPart(i)}>
						Remove
					</button>
				</div>
			</div>
		));
	};

	return (
		<>
			{
				<div>
					<div className="text-xs">
						<Accordion key={props.location_id} className="mb-2">
								<AccordionSummary
									// as={Button}
									variant="link"
									eventKey={props.location_id}>
									{displayName}
								</AccordionSummary>
								<AccordionDetails eventKey={props.location_id}>
										{/* <Container fluid> */}
											<div>
												<div className='mb-2'>
													{renderNamePrefs()}
												</div>
												<div className='mb-2'>
													{renderPrefs()}
												</div>
												<div className="flex justify-center gap-x-2">
													<button
														variant="danger"
														className='btn border-blue-400 text-blue-400 hover:bg-blue-400 hover:text-white text-xs'
														onClick={addDayPart}
														disabled={loading}>
														Add Day Part
													</button>
													<button
														className='btn border-green-400 text-green-400 hover:bg-green-400 hover:text-white text-xs'
														variant="success"
														onClick={save}
														disabled={loading}>
														Save
													</button>
													{/* <button
														className='btn border-purple-500 text-purple-500 hover:bg-purple-500 hover:text-white text-xs'>
														Apply to All Locations
													</button> */}
												</div>
											</div>
										{/* </Container> */}
								</AccordionDetails>
						</Accordion>
					</div>
				</div>
			}
		</>
	);
}

