import React, { useState, useEffect } from 'react';
import { useDataContext } from "../../contexts/DataContext";
import {dateToString} from "./analyticsHelpers";
import { useQuery } from '@tanstack/react-query';
import Loader from '../../utils/Loader';
import { getRegionData } from '../../api/analytics';
import RegionGraph from './RegionGraph';

export default function RegionWidget({
    selectedLocations,
    dtToggle,
    dates,
    colors
}){

    const { routes} = useDataContext();


    const {data, refetch, isLoading, isFetching} = useQuery(
        [
            'analytics_trend', 
            selectedLocations.map(x => x.id).join(','),
            dtToggle, 
            dates.map(x => dateToString(x)).join("T"), 
            routes['analyticsRegions']
        ],
        getRegionData,
        {
            enabled: !!(!!selectedLocations & (dtToggle != undefined) & !!dates),
            retry: 0,
            refetchOnWindowFocus: false,
            cacheTime: 1000 * 60 * 15,
        }
    );


    return (
        <div className='bg-white rounded-lg shadow-lg p-4 w-full'>
            <div className='w-full'>
                Stations
            </div>
            {
                isLoading || isFetching ? (
                    <Loader />
                ) : (
                    <RegionGraph 
                        data={data}
                        colors={colors}/>
                )
            }
        </div>
    )
}