const getTrendData = async ({queryKey}) => {
    let [_key, lids, dtToggle, dates, url] = queryKey;

    dates = dates.split("T");
    lids = lids.split(',').map(x => parseInt(x));
    console.log(dates);
    console.log(lids);


    let startDate = dates[0];
    let endDate = dates[1]

    let body = {
        start_date: startDate,
        end_date: endDate, 
        locations: lids,
        is_drivethru: dtToggle
    }

    // console.log(body);

    const response = await fetch(url, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body)
    });
    if (!response.ok){
        throw new Error("Analytics Trend Data Querry Error");
    }

    let data = response.json();
    return data;
}

const getRegionData = async ({queryKey}) => {
    let [_key, lids, dtToggle, dates, url] = queryKey;

    dates = dates.split("T");
    lids = lids.split(',').map(x => parseInt(x));

    let startDate = dates[0];
    let endDate = dates[1]

    let body = {
        start_date: startDate,
        end_date: endDate, 
        locations: lids,
        is_drivethru: dtToggle
    }

    const response = await fetch(url, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body)
    });
    if (!response.ok){
        throw new Error("Analytics Trend Data Querry Error");
    }

    // let data = response.json();
    let j = await response.json();
    j['daily'] = JSON.parse(j['daily'])
    j['period'] = JSON.parse(j['period']);
    console.log(j);

    // React query demands a promise be returned
    let prom = new Promise((res, rej) => {
        res(j);
    });
    return prom;
}

const getDayPartData = async ({queryKey}) => {
    let [_key, lids, dtToggle, dates, url] = queryKey;

    dates = dates.split("T");
    lids = lids.split(',').map(x => parseInt(x));

    let startDate = dates[0];
    let endDate = dates[1]

    let body = {
        start_date: startDate,
        end_date: endDate, 
        locations: lids,
        is_drivethru: dtToggle
    }

    const response = await fetch(url, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body)
    });
    if (!response.ok){
        throw new Error("Day Part Analytics Trend Data Querry Error");
    }

    // let data = response.json();
    let j = await response.json();
    j['daily'] = JSON.parse(j['daily'])
    j['period'] = JSON.parse(j['period']);

    // React query demands a promise be returned
    let prom = new Promise((res, rej) => {
        res(j);
    });
    return prom;
}


export {
    getTrendData as getTrendData,
    getRegionData as getRegionData,
    getDayPartData as getDayPartData,
}