/**
 _    ___     __           ______     _     __
| |  / (_)___/ /__  ____  / ____/____(_)___/ /
| | / / / __  / _ \/ __ \/ / __/ ___/ / __  / 
| |/ / / /_/ /  __/ /_/ / /_/ / /  / / /_/ /  
|___/_/\__,_/\___/\____/\____/_/  /_/\__,_/   
											  
 * 
 * Creates the grid for the videos, controls which cameras we will
 * be showing for each location and passes down any relevant props
 * (analog is VideoManager in legacy app)
 */

import React, { useState, useEffect } from "react";
import CameraViewer from "./CameraViewer";
import VideoControls from "./VideoControls";
import { useDataContext } from "../../contexts/DataContext";
import AWSCameraViewer from "./AWSCameraViewer";
import AWSCameraViewer2 from "./AWSCameraViewer2";

export default function VideoGrid(props) {
	// const newLocations = [2, 20, 21];
	const { oldLocations, awsLocations, nonAwsLocations } = useDataContext();

	// define states
	// const [cameras, setCameras] = useState(['output']);
	const [isPlaying, setIsPlaying] = useState(false);
	const [rewindCount, setRewindCount] = useState(0);
	const [fastForwardCount, setFastForwardCount] = useState(0);
	const [speed, setSpeed] = useState(1);
	const [fullScreen, setFullScreen] = useState();
	const realTimeSpeedFactor = 0.2;
	const [grid, setGrid] = useState(true);
	const [canPlayArray, setCanPlayArray] = useState([false]);
	const [resizeCounter, setResizeCounter] = useState(0);
	const [page, setPage] = useState();

	// Note: videojs has a fluid mode: https://docs.videojs.com/tutorial-layout.html

	/**
	 * On Location Change, check if DT and update the list
	 * of video prefixes to show
	 */
	useEffect(() => {
		// if (props.currentLocation != undefined){
		// 	if (props.currentLocation.id == 2){
		// 		// console.log("Crenshaw Camera Hack")
		// 		// Hard code in crenshaw for now
		// 		setCameras([0, 1, 2, 3]);
		// 		setCanPlayArray([false, false, false, false]);
		// 		// setCameras([0])
		// 	}
		// 	else if (props.currentLocation.id == 20){
		// 		setCameras([0, 1, 2, 3]);
		// 		setCanPlayArray([false, false, false, false]);
		// 	}
		// 	else if (props.currentLocation.has_drive_through){
		// 		setCameras(['output', 'entry', 'exit']);
		// 		setCanPlayArray([false, false, false]);
		// 	}
		// 	else{
		// 		setCameras(['output']);
		// 		setCanPlayArray([false]);
		// 	}
		// }
		let arr = [];
		for (let i = 0; i < props.cameras.length; i++) {
			arr.push(false);
		}
		setCanPlayArray(arr);

		setIsPlaying(true); // when locatin changes automatically plays
	}, [props.currentLocation]);

	/**
	 * On live force the playback rate to 1X
	 */
	useEffect(() => {
		setSpeed(1);
	}, [props.isLive]);

	useEffect(() => {
		// Reduce to see if all ready to play
		let ready = canPlayArray.reduce((acc, cur) => acc & cur);
		// console.log("Can Play Status:", ready);
	}, [canPlayArray]);

	useEffect(() => {
		// if cameras change reset speed
		setSpeed(1);
		setResizeCounter(resizeCounter + 1);
	}, [props.cameras]);

	/**
	 * Uses the speed set by the control slider to get
	 * the speed at which the video is to be played back
	 * (not 1:1 as 1X needs to be slowed down)
	 */
	const getPlaybackSpeed = () => {
		if (speed == 1) {
			// if (props.currentLocation.id == 2){
			// if (newLocations.indexOf(props.currentLocation.id) != -1){
			if (oldLocations.indexOf(props.currentLocation.id) == -1) {
				// console.log("Crenshaw Speed Hack");
				return 1;
			} else {
				return realTimeSpeedFactor;
			}
		} else {
			return speed;
		}
		// return speed == 1 ? realTimeSpeedFactor : speed;
	};

	const getGridClass = () => {
		// If in-line is selected, for that, otherwise do dynamic
		if (!grid) {
			return "grid grid-cols-1 gap-4";
		}
		if (props.cameras.length == 1) {
			return "grid grid-cols-1 gap-4";
		}
		// return 'grid gap-4 grid-cols-1 sm:grid-cols-2 xl:grid-cols-2'
		return "grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-4";
	};

	const updateCanPlay = (b, i) => {
		// console.log("Call to update can play", b, i);
		let arr = [...canPlayArray];
		arr[i] = b;
		setCanPlayArray([arr]);
	};

	return (
		<>
			<div>
				{/* <div className='bg-white shadow-lg rounded-lg mb-8'> */}
				<div className="mb-2">
					<VideoControls
						isLive={props.isLive}
						setIsLive={props.setIsLive}
						isPlaying={isPlaying}
						setIsPlaying={setIsPlaying}
						videoDateTime={props.videoDateTime}
						setVideoDateTime={props.setVideoDateTime}
						fastForwardCount={fastForwardCount}
						setFastForwardCount={setFastForwardCount}
						rewindCount={rewindCount}
						setRewindCount={setRewindCount}
						speed={speed}
						setSpeed={setSpeed}
						cameras={props.cameras}
						setFullScreen={setFullScreen}
						grid={grid}
						setGrid={setGrid}
						page={props.page}
						nextPage={props.nextPage}
						prevPage={props.prevPage}
					/>
				</div>
			</div>

			{/* <div className='grid gap-4 xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-1'> */}
			{props.showCVStream || props.showDTCVStream ? (
				<>
                {
                    props.showCVStream ? (
                        <div className="bg-white shadow-lg rounded-lg border-gray-200 mb-2">
                            <AWSCameraViewer
                                currentLocation={props.currentLocation}
                                videoDateTime={props.videoDateTime}
                                setVideoDateTime={props.setVideoDateTime}
                                isPlaying={isPlaying}
                                setIsPlaying={setIsPlaying}
                                camera={"Lobby CV"}
                                speed={getPlaybackSpeed()}
                                fastForwardCount={fastForwardCount}
                                rewindCount={rewindCount}
                                fullScreen={fullScreen}
                                setFullScreen={setFullScreen}
                                isLive={props.isLive}
                                setIsLive={props.setIsLive}
                                grid={grid}
                                resizeCounter={resizeCounter}
                                ind={0}
                                updateCanPlay={updateCanPlay}
                                key={`${props.currentLocation.address}-lobby-cv`}
                            />
                        </div>
                    ): null
                }
                {
                    props.showDTCVStream ? (
                        <div className="bg-white shadow-lg rounded-lg border-gray-200">
                            <AWSCameraViewer
                                currentLocation={props.currentLocation}
                                videoDateTime={props.videoDateTime}
                                setVideoDateTime={props.setVideoDateTime}
                                isPlaying={isPlaying}
                                setIsPlaying={setIsPlaying}
                                camera={"DT CV"}
                                speed={getPlaybackSpeed()}
                                fastForwardCount={fastForwardCount}
                                rewindCount={rewindCount}
                                fullScreen={fullScreen}
                                setFullScreen={setFullScreen}
                                isLive={props.isLive}
                                setIsLive={props.setIsLive}
                                grid={grid}
                                resizeCounter={resizeCounter}
                                ind={0}
                                updateCanPlay={updateCanPlay}
                                key={`${props.currentLocation.address}-dt-cv`}
                            />
                        </div>
                    ) : null
                }
                </>
			) : (
				<div className={getGridClass()}>
					{props.cameras.map((cam, i) => {
						if (props.currentLocation == undefined) {
							return;
						}
						return (
							<div className="bg-white shadow-lg rounded-lg border-gray-200">
								{
									// awsLocations.includes(props.currentLocation.id) ? (
									!nonAwsLocations.includes(props.currentLocation.id) ? (
										<AWSCameraViewer
											currentLocation={props.currentLocation}
											videoDateTime={props.videoDateTime}
											setVideoDateTime={props.setVideoDateTime}
											isPlaying={isPlaying}
											setIsPlaying={setIsPlaying}
											camera={cam}
											speed={getPlaybackSpeed()}
											fastForwardCount={fastForwardCount}
											rewindCount={rewindCount}
											fullScreen={fullScreen}
											setFullScreen={setFullScreen}
											isLive={props.isLive}
											setIsLive={props.setIsLive}
											grid={grid}
											resizeCounter={resizeCounter}
											ind={i}
											updateCanPlay={updateCanPlay}
											key={`${props.currentLocation.address}-${cam}`}
										/>
									) : (
										<CameraViewer
											currentLocation={props.currentLocation}
											videoDateTime={props.videoDateTime}
											isPlaying={isPlaying}
											setIsPlaying={setIsPlaying}
											camera={cam}
											speed={getPlaybackSpeed()}
											fastForwardCount={fastForwardCount}
											rewindCount={rewindCount}
											fullScreen={fullScreen}
											setFullScreen={setFullScreen}
											isLive={props.isLive}
											grid={grid}
											resizeCounter={resizeCounter}
											ind={i}
											updateCanPlay={updateCanPlay}
											key={`${props.currentLocation.address}-${cam}`}
										/>
									)
								}
							</div>
						);
					})}
				</div>
			)}
		</>
	);
}
