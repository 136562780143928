import React, { useState, useEffect } from 'react';
import { useAuth } from "../../contexts/AuthContext";
import { useDataContext } from "../../contexts/DataContext";
import PageOptions from './PageOptions';
import app from '../../firebase';
import { DockTwoTone, ExpandLessSharp } from '@material-ui/icons';
import LocationComparisonWidget from './LocationComparisonWidget';
import RegionComparisonWidget from './RegionComparisonWidget_new';
import DayPartComparisonWidget from './DayPartComparisonWidget';
import RegionLocationWidget from './RegionLocationWidget';
import ComparisonTableWidget from './ComparisonTableWidget';
import DayPartsLocationsWidget from './DayPartsLocationsWidget';
import RegionDayPartWidget from './RegionDayPartWidget';


export default function ComparisonAnalytics(){

	// States
	const [tokenState, setToken] = useState();
	const [allFranchises, setAllFranchises] = useState();
	const [franchise, setFranchise] = useState();
	const [period1, setPeriod1] = useState();
	const [period2, setPeriod2] = useState();
	const [allLocations, setAllLocations] = useState();
	const [selectedLocations, setSelectedLocations] = useState();
	const [dtToggle, setDtToggle] = useState();
	const [periodToggle, setPeriodToggle] = useState('both');

	// const [displayWidgets, setDisplayWidgets] = useState(['locationComparison', 'dayPartComparison', 'regionComparison', 'regionLocation', 'dayPartsLocation', 'regionDayPart']);
	const [displayWidgets, setDisplayWidgets] = useState([]);


	const componentInfo = [
		{
			name: 'locationComparison',
			displayName: 'Location Service Time Comparisons',
			description: `Compares total service times across all locations in one graph`,
			component: LocationComparisonWidget
		},
		{
			name: 'regionComparison',
			displayName: 'Region Comparison at Single Location',
			description: `Compare region times for a single location at a time`,
			component: RegionComparisonWidget
		},
		{
			name: 'dayPartComparison',
			displayName: 'Day Part Comparison at Single Location',
			description: `Compare total service times across all day parts for one location at a time`,
			component: DayPartComparisonWidget
		},
		{
			name: 'regionLocation',
			displayName: 'Regions Across All Locations',
			description: `View and compare all regions in each location at the same time in this stacked bar chart.`,
			component: RegionLocationWidget,
		},
		{
			name: 'dayPartsLocation',
			displayName: 'Day Parts Across All Locations',
			description: `View a single day part across all locations in this single bar chart`,
			component: DayPartsLocationsWidget,
		},
		{
			name: 'regionDayPart',
			displayName: 'Regions and Day Parts at Single Location',
			description: `Compare ALl regions across all Day parts for a single location at a time in this stacked bar chart`,
			component: RegionDayPartWidget
		}
	]



	// Contexts
	const { currentUser } = useAuth();
	const { routes, getCacheData, setCache, getFBPreferences, setFBPreference } = useDataContext();

	const getToken = async () => {
		let token = await currentUser.getIdToken();
		setToken(token);
	};

	/**
	 * On load, querry for all customer locations (save it)
	 * Hit firebase for defaults/preferences
	 * set initial states with fb > querry
	 */
	useEffect(async () => {
		// Get all Locations
		let allLocationsData = await getAllLocations();

		// Get FB preferences
		let prefs = await getFBPreferences();

		// Set states-------------------------------------------

		// Set All Franchises
		let franchiseList = allLocationsData.map(x => x.franchise);
		let ids = [];
		franchiseList = franchiseList.filter(x => {
			if (ids.includes(x.id)){
				return false;
			}
			else{
				ids.push(x.id);
				return true;
			}
		});
		franchiseList.sort((a,b) => a.id - b.id);
		setAllFranchises(franchiseList);

		// Set Selected Franchise
		let _selectedFranchise;
		if (prefs['default_franchise']){
			_selectedFranchise = prefs['default_franchise'];
			setFranchise(_selectedFranchise);
		}
		else{
			_selectedFranchise = franchiseList[0];
			setFranchise(_selectedFranchise);
		}

		// Set selected All Locations
		setAllLocations(allLocationsData);

		// Set Selected Locations (currently no prefernces.... think about this because
		// it would need to be tied to the selected franchise as well...)
		setSelectedLocations(allLocationsData.filter(loc => loc.franchise.id == _selectedFranchise.id));

		// Set Period 1
		let p1Prefs = prefs['comparisonAnalyticsPeriod1']
		if (p1Prefs){
			// let d1 = new Date(p1Prefs[0]);
			// let d2 = new Date(p1Prefs[1]);
			let d1 = loadDateString(p1Prefs[0]);
			let d2 = loadDateString(p1Prefs[1]);
			setPeriod1([d1, d2]);
		}
		else{
			// Set default
			let d1 = new Date(); d1.setDate(d1.getDate() - 7);
			let d2 = new Date(); d2.setDate(d2.getDate() -1);
			setPeriod1([d1, d2]);
		}
		
		// Set Period 2
		let p2Prefs = prefs['comparisonAnalyticsPeriod2']
		if (p2Prefs){
			// let d1 = new Date(p2Prefs[0]);
			// let d2 = new Date(p2Prefs[1]);
			let d1 = loadDateString(p2Prefs[0]);
			let d2 = loadDateString(p2Prefs[1]);
			setPeriod2([d1, d2]);
		}
		else{
			// Set default
			let d1 = new Date(); d1.setDate(d1.getDate() - 14);
			let d2 = new Date(); d2.setDate(d2.getDate() -8);
			setPeriod2([d1, d2]);
		}


		// Set dt toggle
		Object.keys(prefs).includes('comparisonAnalyticsDT')
		if (Object.keys(prefs).includes('comparisonAnalyticsDT')){
			setDtToggle(prefs['comparisonAnalyticsDT']);
		}
		else{
			setDtToggle(false);
		}

		// Set selected Graphs
		if (Object.keys(prefs).includes("comparisonAnaylticsGraphs") ){
			// Have graphs, load them
			setDisplayWidgets(prefs["comparisonAnaylticsGraphs"]);
		}
		else{
			// Set to default graphs
			setDisplayWidgets(['locationComparison', 'dayPartComparison', 'regionComparison', 'regionLocation']);
		}
		
	}, []);


	/**
	 * Loads string format into correct date, gets around JS doing the weird
	 * thing with the timezone that will make it the day before, does this
	 * by parsing the string then using the Date constructor
	 * @param {String} ds in format 'YYYY-m-d'
	 */
	const loadDateString = (ds) => {
		ds = ds.split('-').map(s => parseInt(s));
		let d = new Date(ds[0], ds[1]-1, ds[2]);
		return d;
	}


	/**
	 * Whenever the selected locations change, we want to check if they have
	 * been changed such that none have drive thrus, in such cases, we want
	 * to make sure we toggle DT to false
	 */
	useEffect(() => {
		if (selectedLocations === undefined){return}
		let anyHaveDT = selectedLocations.reduce((acc, cur) => {
			return acc || cur.has_drive_through
		}, false);
		if (!anyHaveDT){
			setDtToggle(false);
		}
	}, [selectedLocations])

	/**
	 * When franchise changes, update firestore, and
	 * update the selected locations to be sure they match new franchise
	 */
	useEffect(() => {
		if ( (allLocations == undefined) || (franchise == undefined) ){
			return;
		}
		// Udpate firestore
		setFBPreference('default_franchise', franchise);

		// Ensure selected locations match new franchise
		setSelectedLocations(allLocations.filter(loc => loc.franchise.id == franchise.id));

	}, [franchise]);


	/**
	 * When period 1 changes updates firestore
	 */
	useEffect(() => {
		// console.log("Period1 changed");
		if (period1 == undefined){return}
		// Process the dates to strings 
		let d1 = period1[0].toISOString().slice(0, 10);
		let d2 = period1[1].toISOString().slice(0, 10);
		setFBPreference('comparisonAnalyticsPeriod1', [d1, d2]);
	}, [period1])

	/**
	 * When period 2 changes updates firestore
	 */
	 useEffect(() => {
		if (period2 == undefined){return}
		// Process the dates to strings 
		let d1 = period2[0].toISOString().slice(0, 10);
		let d2 = period2[1].toISOString().slice(0, 10);
		setFBPreference('comparisonAnalyticsPeriod2', [d1, d2]);
	}, [period2]);


	/** When DT Toggle changes, update FB prefs */
	useEffect(() => {
		if (dtToggle == undefined){return}
		setFBPreference('comparisonAnalyticsDT', dtToggle);
	}, [dtToggle])

	useEffect(() => {
		if (displayWidgets === undefined || displayWidgets.length === 0){return}
		// console.log("SETTING GRAPHS IN FB TO", displayWidgets);
		setFBPreference('comparisonAnaylticsGraphs', displayWidgets);
	}, [displayWidgets])
	



	const getAllLocations = async () => {
		let key = `customerLocationsData-analytics`;
		let cacheData = getCacheData(key);
		if (cacheData != undefined){
			return cacheData;
		}

		// Else, make querry
		let url = routes['customerLocations'];
		let resp = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/JSON"
			},
			body: JSON.stringify({'uid': currentUser.uid})
		})
		.catch(err => {
			console.warn(err);
			return undefined;
		})

		// Catch any error
		if ( (resp == undefined) || (!resp.ok) ){
			console.warn("Issue getting data");
			return [];
		}
		
		// Process the response if good
		let data = await resp.json();
		
		setCache(key, data);
		return data;
	}
	

	return (
		<div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
			<PageOptions
				allFranchises={allFranchises}
				franchise={franchise}
				setFranchise={setFranchise}
				allLocations={allLocations}
				selectedLocations={selectedLocations}
				setSelectedLocations={setSelectedLocations}
				period1={period1}
				setPeriod1={setPeriod1}
				period2={period2}
				setPeriod2={setPeriod2}
				dtToggle={dtToggle}
				setDtToggle={setDtToggle}
				periodToggle={periodToggle}
				setPeriodToggle={setPeriodToggle}
				displayWidgets={displayWidgets}
				setDisplayWidgets={setDisplayWidgets}
				componentInfo={componentInfo}
				/>

			{/* <div className=' flex flex-wrap justify-center gap-8'> */}
			<div className='grid xl:grid-cols-2 grid-cols-1 gap-8'>
				
				{
					displayWidgets === undefined ? null : 
					displayWidgets.map(name => {
						// const Widget = componentMap[name];
						// find the match
						let obj = componentInfo.filter(x => x.name == name);
						if (obj.length != 0){
							const Widget = obj[0].component;
							return (
								<Widget
									selectedLocations={selectedLocations}
									dtToggle={dtToggle}
									period1={period1}
									period2={period2}
									periodToggle={periodToggle}
									/>
							)	
						}
						
					})
				}


				{/* <div className='bg-white rounded-lg shadow-lg w-96 h-96'></div> */}
				{/* <div className='bg-white rounded-lg shadow-lg w-full h-96'></div> */}
				{/* <div className='bg-white rounded-lg shadow-lg w-full h-96'></div> */}
				{/* <div className='bg-white rounded-lg shadow-lg w-full h-96'></div> */}
			</div>

			
			<div className='mt-8'>
				<ComparisonTableWidget 
					selectedLocations={selectedLocations}
					period1={period1}
					period2={period2}
					dtToggle={dtToggle}/>
			</div>

			
		</div>
	)
}