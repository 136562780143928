import React from 'react';

// Note, bg color for toggle switch is set in toggle-switch.scss file

/**
 * NOte, a switcId is REQURED, otherwise the input will not work
 */

export default function ToggleSwitch({
  toggle,
  setToggle,
  switchId,
}: {
  toggle: boolean;
  setToggle: React.Dispatch<React.SetStateAction<boolean>>;
  switchId: string;
}) {
  return (
    <div className="flex items-center">
      <div className="form-switch focus-within:outline-blue">
        <input
          type="checkbox"
          // id="switch-1"
          id={switchId}
          className="sr-only"
          checked={toggle}
          onChange={() => setToggle(!toggle)}
        />
        <label className="bg-gray-400" htmlFor={switchId}>
          <span className="bg-white shadow-sm" aria-hidden="true"></span>
          <span className="sr-only">Switch label</span>
        </label>
      </div>
      <div className="text-sm text-gray-400 italic ml-2">
        {toggle ? 'On' : 'Off'}
      </div>
    </div>
  );
}
