/**
	____                       __                    __  ___                
   / __ \_________  ____  ____/ /___ _      ______  /  |/  /__  ____  __  __
  / / / / ___/ __ \/ __ \/ __  / __ \ | /| / / __ \/ /|_/ / _ \/ __ \/ / / /
 / /_/ / /  / /_/ / /_/ / /_/ / /_/ / |/ |/ / / / / /  / /  __/ / / / /_/ / 
/_____/_/   \____/ .___/\__,_/\____/|__/|__/_/ /_/_/  /_/\___/_/ /_/\__,_/  
				/_/                                                         
 * 
 * 
 * Customer dropdown menu in the template style
 * This will take in a list of options to display
 * and a method to set the selected object based on what is clicked
 * 
 */

import React, { useState, useRef, useEffect } from 'react';
import Transition from '../../utils/Transition.js';


export default function DropdownMenu({ options, setOptions, selected }){

	const [dropdownOpen, setDropdownOpen] = useState(false);

	const trigger = useRef(null);
	const dropdown = useRef(null);

	// close on click outside
	useEffect(() => {
		const clickHandler = ({ target }) => {
		if (!dropdown.current) return;
		if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
		setDropdownOpen(false);
		};
		document.addEventListener('click', clickHandler);
		return () => document.removeEventListener('click', clickHandler);
	});

	// close if the esc key is pressed
	useEffect(() => {
		const keyHandler = ({ keyCode }) => {
		if (!dropdownOpen || keyCode !== 27) return;
		setDropdownOpen(false);
		};
		document.addEventListener('keydown', keyHandler);
		return () => document.removeEventListener('keydown', keyHandler);
	});

  return (
		<div className="relative">
			<button
				ref={trigger}
				className="btn justify-between min-w-56 bg-white border-gray-200 hover:border-gray-300 text-gray-500 hover:text-gray-600 focus:outline-none"
				aria-label="Select date range"
				aria-haspopup="true"
				onClick={() => setDropdownOpen(!dropdownOpen)}
				aria-expanded={dropdownOpen}
			>
				<span className="flex items-center">
				{/* <svg className="w-4 h-4 fill-current text-gray-500 flex-shrink-0 mr-2" viewBox="0 0 16 16">
					<path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
				</svg> */}
				<span className='w-4'></span>
				<span>{selected}</span>
				</span>
				<svg className="flex-shrink-0 ml-1 fill-current text-gray-400" width="11" height="7" viewBox="0 0 11 7">
				<path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
				</svg>
			</button>
			<Transition
				show={dropdownOpen}
				tag="div"
				className="z-10 absolute top-full right-0 w-full bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
				enter="transition ease-out duration-100 transform"
				enterStart="opacity-0 -translate-y-2"
				enterEnd="opacity-100 translate-y-0"
				leave="transition ease-out duration-100"
				leaveStart="opacity-100"
				leaveEnd="opacity-0"
			>
				<div
				ref={dropdown}
				className="font-medium text-sm text-gray-600"
				onFocus={() => setDropdownOpen(true)}
				onBlur={() => setDropdownOpen(false)}
				>
				{
					options.map(option => {
					return (
						<button
						key={option}
						tabIndex="0"
						className={`flex items-center w-full hover:bg-gray-50 py-1 px-3 cursor-pointer ${option === selected && 'text-indigo-500'}`}
						onClick={() => { setOptions(option); setDropdownOpen(false); }}
						>
						<svg className={`flex-shrink-0 mr-2 fill-current text-indigo-500 ${option !== selected && 'invisible'}`} width="12" height="9" viewBox="0 0 12 9">
							<path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
						</svg>
						<span>{option}</span>
						</button>
					)
					})
				}
				</div>
			</Transition>
		</div>
	);
	
}