/**
 * 
  ______      __    __     __                    __         
 /_  __/___ _/ /_  / /__  / /   ____  ____ _____/ /__  _____
  / / / __ `/ __ \/ / _ \/ /   / __ \/ __ `/ __  / _ \/ ___/
 / / / /_/ / /_/ / /  __/ /___/ /_/ / /_/ / /_/ /  __/ /    
/_/  \__,_/_.___/_/\___/_____/\____/\__,_/\__,_/\___/_/     
                                                            
 * 
 * Partial component, takes in headers
 * and makes a skeleton table that pulses to be a loader
 */

import React from 'react';

export default function TableLoader(props) {
  return (
    <>
      <table className="table-auto w-full">
        <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
          <tr>
            {props.columns.map((col) => {
              return (
                <th className="p-2" key={col}>
                  <div className="font-semibold text-left">{col}</div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="text-sm font-medium divide-y divide-gray-100 animate-pulse">
          {[1, 2, 3, 4].map((row) => {
            return (
              <tr key={row}>
                {props.columns.map((col) => {
                  return (
                    <td className="p-2" key={`${row}-${col}`}>
                      <div className="h-2 bg-blue-400 rounded w-3/4"></div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
