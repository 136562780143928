/**
 * Presentational component for a drop down filter.
 */
import { useState, useEffect } from 'react';
import Transition from '../../utils/Transition';

export default function DropDownFilter({
  options,
  selected,
  setSelected,
}: {
  options: any[];
  selected: any[];
  setSelected: (selected: any[]) => void;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);

  // Close the dropdown when the user clicks outside of it
  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      if (!(e.target as HTMLElement).closest('.relative')) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, []);

  // Handle filtering.
  function handleFilter(e: React.ChangeEvent<HTMLInputElement>) {
    setFilterText(e.target.value);
    if (e.target.value === '') {
      setFilteredOptions(options);
    } else {
      const newOptions = [];
      options.map((option) => {
        if (
          option.display_name &&
          option.display_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          newOptions.push(option);
        }
      });
      setFilteredOptions(newOptions);
    }
  }

  return (
    <div className="relative">
      <button
        className="btn justify-between w-full min-w-56 bg-white border-gray-200 hover:border-gray-300 text-gray-500 hover:text-gray-600 focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{selected.length} Selected</span>
        <svg
          className="flex-shrink-0 ml-1 fill-current text-gray-400"
          width="11"
          height="7"
          viewBox="0 0 11 7"
        >
          <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
        </svg>
      </button>
      <Transition
        show={isOpen}
        appear="transition ease-out duration-100 transform"
        tag="div"
        className="z-10 absolute top-full right-0 w-full bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
        enter="transition ease-out duration-100 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div className="max-h-64 overflow-y-auto">
          {/* Text filter. */}
          <div className="flex">
            <input
              type="text"
              placeholder="Filter..."
              className="w-full border-b border-t-0 border-l-0 border-r-0 pb-0.5 border-gray-200 sticky top-0"
              onChange={handleFilter}
              value={filterText}
            />
          </div>
          {/* Options to select. */}
          {filteredOptions.map((option, index) => {
            return (
              <button
                key={option.id}
                className={`flex items-center w-full hover:bg-gray-50 py-1 px-3 cursor-pointer ${
                  selected.includes(option) && 'text-indigo-500'
                }`}
                onClick={() => {
                  if (selected.includes(option)) {
                    setSelected(selected.filter((item) => item !== option));
                  } else {
                    setSelected([...selected, option]);
                  }
                }}
              >
                <svg
                  className={`flex-shrink-0 mr-2 fill-current text-indigo-500 ${
                    !selected.includes(option) && 'invisible'
                  }`}
                  width="12"
                  height="9"
                  viewBox="0 0 12 9"
                >
                  <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
                </svg>
                <span>{option.display_name}</span>
              </button>
            );
          })}
        </div>
      </Transition>
    </div>
  );
}
