import React, { useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useAuth } from './contexts/AuthContext';
import { analytics } from './firebase';


export default function PrivateRoute({ component: Component, ...rest}) {
    const { currentUser } = useAuth();

    useEffect(() => {
        if (currentUser != null) {
            console.log(JSON.stringify({uid: currentUser.uid}))
            analytics.setUserId(currentUser.uid);
            analytics.setUserProperties({
				user_id: currentUser.uid,
				email: currentUser.email
			});
        }

    }, [currentUser]);

    return (
        <Route
            {...rest}
            render={props => {
                return currentUser ? <Component {...props} /> : <Redirect to='/signin'/>
            }}
        ></Route>
    )
}
