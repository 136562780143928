import React, { useState } from "react";
import { formatSeconds, renderTimeLink } from "../../helpers/formatting";

export default function DetectionOrderRow({ data, columns, location, prefs }) {
	const [expanded, setExpanded] = useState(false);

	let detTime = "--";
    let hasRegions = false;
	if (data["detection"].length > 0) {
		detTime = formatSeconds(data["detection"][0].time_spent);
        if (data['detection'][0].regions.length > 0){
            hasRegions = true;
        }
	}


    const renderRegionData = () => {
        let detectionData = data['detection'][0];
        let regionData = data['detection'][0].regions;

        // Extract day part mapping
        let regionMapping = prefs[detectionData.is_drive_through ? 'drive_thru_regions2' : 'lobby_regions2'];
        regionMapping = regionMapping.reduce((acc, cur) => {
            acc[cur.key] = cur.display_name;
            return acc;
        }, {});
        let regionKeys = Object.keys(regionMapping);
        return (
            <table className='w-full max-w-md'>
                <thead>
                    <tr>
                        <th className='text-left'>First Seen</th>
                        <th className='text-left'>Station</th>
                        <th className='text-right'>Time Spent</th>
                    </tr>
                </thead>
                <tbody className='divide-y divide-gray-200'>
                    {
                        regionData.map(reg => {
                            let regionName = reg.region;
                            if (regionKeys.includes(reg.region)){
                                regionName = regionMapping[regionName];
                            }
                            return (
                                <tr key={regionData.id}>
                                    <td>{renderTimeLink(reg.first_seen_datetime_utc, location)}</td>
                                    <td>{regionName}</td>
                                    <td className='text-right'>{formatSeconds(reg.time_spent)}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        )
    }

	return (
		<>
			<tr onClick={() => setExpanded(!expanded)} className='hover:bg-gray-100 cursor-pointer'>
				<td>{renderTimeLink(data.timestamp, location)}</td>
				{Object.keys(columns)
					.slice(1, -1)
					.map((colHeader) => {
						let key = columns[colHeader].key;
						let type = columns[colHeader].type;
						return (
							<td
								className={`${type == "dollar" ? "text-right" : "text-left"} w-full whitespace-nowrap`}
								key={`cell-${data.id}-${key}`}>
								{type == "dollar" ? (
									<>{`$${data[key].toLocaleString(undefined, {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									})}`}</>
								) : (
									<>{data[key]}</>
								)}
							</td>
						);
					})}
				<td className="text-right">{detTime}</td>
			</tr>
			{ (expanded & hasRegions) ? (
                <tr>
                    <td colSpan={Object.keys(columns).length}>
                        <div className='w-full p-2 bg-gray-50'>
                            <h3 className='text-sm'>
                                Service Time Data: {formatSeconds(data['detection'][0].time_spent)}
                            </h3>
                            {renderRegionData()}
                        </div>
                    </td>
                </tr>
            ) : null}
		</>
	);
}
