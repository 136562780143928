import React, { useState, useEffect } from "react";
import { useDataContext } from "../../contexts/DataContext";
import { DateTime } from "luxon";
// import ReactLoading from 'react-loading';
import Loader from "../../utils/Loader";
import app from "../../firebase";
import { dateToString, formatTime } from "./analyticsHelpers";
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	Label,
	ResponsiveContainer,
} from "recharts";
import MultiSelectDropdownMenu from "../actions/MultiSelectDropdown";
import DropdownMenu from "../actions/DropdownMenu";

export default function RegionDayPartWidget({
	selectedLocations,
	dtToggle,
	period1,
	period2,
	periodToggle,
}) {
	// States
	const [regionData, setRegionData] = useState();
	const [selectedLocation, setSelectedLocation] = useState();
	const [allDayParts, setAllDayParts] = useState();
	const [selectedRegions, setSelectedRegions] = useState();
	const [period1Data, setPeriod1Data] = useState();
	const [period2Data, setPeriod2Data] = useState();
	const [legendDict, setLegendDict] = useState({
		p1: "Period 1",
		p2: "Period 2",
	});
	const [colorDict, setColorDict] = useState({});
	const colors = [
		"#1f77b4",
		"#ff7f0e",
		"#2ca02c",
		"#d62728",
		"#9467bd",
		"#8c564b",
		"#e377c2",
		"#7f7f7f",
		"#bcbd22",
		"#17becf",
	];

	// Context
	const { routes, getCacheData, setCache } = useDataContext();

	const propsLoaded = () => {
		return (
			dtToggle != undefined &&
			selectedLocations != undefined &&
			regionData != undefined &&
			allDayParts != undefined &&
			period1 != undefined &&
			period2 != undefined
		);
	};

	/**
	 * When key data parameters change, querry for the data
	 */
	useEffect(async () => {
		if (!propsLoaded()){return}
		let [p1d, p2d] = await Promise.all([querryData(period1), querryData(period2)])

		setPeriod1Data(p1d);
		setPeriod2Data(p2d);
	}, [period1, period2, selectedLocations, regionData, allDayParts, dtToggle]);

	const querryData = async (p) => {
		// Cache stuff
		let key = `comparisonFullData-${dateToString(p[0])}-${dateToString(
			p[1]
		)}-${dtToggle}-${selectedLocations
			.map((x) => x.id)
			.join("-")}-${regionData[dtToggle ? 'drive_thru_regions2' : 'lobby_regions2']
			.map((x) => x.key).join("-")}-${allDayParts.join('-')}`;
		// console.log(key);
		let cacheData = getCacheData(key);
		if (cacheData != undefined) {
			return cacheData;
		}

		// If no regions, nothing to show
		if (selectedRegions.length == 0) {
			return [];
		}

		let params = {
			start_date: dateToString(p[0]),
			end_date: dateToString(p[1]),
			locations: selectedLocations.map((x) => x.id),
			is_drive_through: dtToggle,
			regions: regionData[dtToggle ? 'drive_thru_regions2' : 'lobby_regions2'].map(x => x.key),
			day_parts: allDayParts,
			which: ["full"],
		};

		let url = routes["comparisonData"];
		let data = await fetch(url, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(params),
		})
			.then((res) => res.json())
			.then((data) => {
				data = JSON.parse(data["full"]);
				console.log(data);
				setCache(key, data);
				return data;
			})
			.catch((err) => {
				console.warn(err);
				return undefined;
			});

		return data;
	};

	/**
	 * Whenever the selected locations change, get the regions and day parts
	 */
	useEffect(async () => {
		if(selectedLocations == undefined){return}

		// Make a selectedLocation (check if in list or default)
		if (selectedLocation == undefined){
			setSelectedLocation(selectedLocations[0]);
		}
		else if (!selectedLocations.map(x => x.id).includes(selectedLocation.id)){
			setSelectedLocation(selectedLocations[0]);
		}


		// Hit firestore-----------------------------------------------------
		const db = app.firestore();
		let locationData = [];
		let e = 0;
		while (e < selectedLocations.length) {
			let locationQuery = await db
				.collection("location")
				.where(
					"location_id",
					"in",
					selectedLocations.slice(e, e + 10).map((x) => x.id)
				)
				.get();
			locationQuery.forEach((doc) => locationData.push(doc.data()));
			e = e + 10;
		}

		// Save Region Data--------------------------------------------------
		let dtRegions = [];
		let lobbyRegions = [];
		locationData.forEach(loc => {
			let dtKey = 'drive_thru_regions2';
			let lKey = 'lobby_regions2';
			if (Object.keys(loc).includes(dtKey)){
				dtRegions = [...dtRegions, ...loc[dtKey]]
			}
			if (Object.keys(loc).includes(lKey)){
				lobbyRegions = [...lobbyRegions, ...loc[lKey]];
			}
		})

		// remove all the duplicates
		const uniqueDt = dtRegions.reduce((acc, cur) => {
			// If not tracking, add it
			if (!acc.map(x => x.key).includes(cur.key)){
				// console.log("new");
				acc.push(cur)
			}
			// if already have it, ignore
			return acc
		}, []);

		const uniqueLobby = lobbyRegions.reduce((acc, cur) => {
			// If not tracking, add it
			if (!acc.map(x => x.key).includes(cur.key)){
				acc.push(cur)
			}
			// if already have it, ignore
			return acc
		}, []);

		setRegionData({
			'drive_thru_regions2': uniqueDt,
			'lobby_regions2': uniqueLobby
		});

		// Save Day Part Data----------------------------------------
		let _allDayParts = locationData.reduce((acc, cur) => {
			let cdp = cur.day_part_preferences;
			cdp.forEach((dp) => {
				if (acc.indexOf(dp.name) == -1) {
					acc.push(dp.name);
				}
			});
			return acc;
		}, []);
		setAllDayParts(_allDayParts);

	}, [selectedLocations]);

	useEffect(() => {
		if (selectedRegions == undefined){return}
		// check if any of the regions are NOT in the color dict
		let notIn = [];
		selectedRegions.forEach(reg => {
			if (!Object.keys(colorDict).includes(reg.key)){
				notIn.push(reg.key);
			}
		});
		if (notIn.length == 0){return}
		// Add any that are not there
		let newColorDict = Object.assign({}, colorDict);
		notIn.forEach(key => {newColorDict[key] = colors[Object.keys(newColorDict).length + 1 % colors.length]});
		setColorDict(newColorDict);

	}, [selectedRegions])

	/**
	 * When region data changes, need to cross check it with the selected regions
	 * we accept any overlap, and if there is no overlap, we will reset selected
	 * regioons to be all possible new regions
	 */
	useEffect(() => {
		if (regionData == undefined || dtToggle == undefined) {
			return;
		}
		setSelectedRegions(regionData[dtToggle ? "drive_thru_regions2" : 'lobby_regions2']);
		// trying no fancy logic here (can reference RegionLocationWidget if we want it back)
	}, [regionData, dtToggle]);

	/**
	 * Takes in a region display name and toggles its option in
	 * selectedRegions
	 * @param {String} reg region display name
	 */
	const regionToggleHelper = (reg) => {
		if (selectedRegions.map((x) => x.display_name).includes(reg)) {
			// remove it
			setSelectedRegions(selectedRegions.filter((x) => x.display_name != reg));
		} else {
			// find matching object
			let item = regionData[
				dtToggle ? "drive_thru_regions2" : "lobby_regions2"
			].filter((x) => x.display_name == reg)[0];

			// add it
			setSelectedRegions((selectedRegions) => [...selectedRegions, item]);
		}
	};

	const renderGraph = () => {
		if (
			period1Data == undefined ||
			period2Data == undefined ||
			selectedRegions == undefined ||
			allDayParts == undefined ||
			selectedLocation == undefined
		) {
			return <Loader />;
		}

		const getVal = (data, reg, lid, dp) => {
			let match = data.filter((x) => x.region == reg && x.location_id == lid && x.day_part == dp);
			return match.length == 0 ? 0 : match[0].avg;
		};


		// Organize Graph Data
		let plotData = [];
		// let order = regionData[dtToggle ? 'drive_thru_region_order' : 'lobby_region_order'];
		let order = regionData[
			dtToggle ? "drive_thru_regions2" : "lobby_regions2"
		].map((x) => x.key);
		let whichNames =
			regionData[dtToggle ? "drive_thru_regions2" : "lobby_regions2"];

		allDayParts.forEach(dp => {
			let obj = {};
			obj['name'] = dp;
			selectedRegions
			.sort((a, b) => order.indexOf(a.key) - order.indexOf(b.key))
			.forEach((reg) => {
				obj[`p1_${reg.key}`] = getVal(period1Data, reg.key, selectedLocation.id, dp);
				obj[`p2_${reg.key}`] = getVal(period2Data, reg.key, selectedLocation.id, dp);
			});
			plotData.push(obj);
		})

		// console.log(plotData);

		// Make the graph
		return (
			<ResponsiveContainer width="100%" height="100%">
				<BarChart
					width={500}
					height={300}
					data={plotData}
					margin={{
						top: 10,
						right: 30,
						left: 20,
						bottom: 10,
					}}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis
						dataKey="name"
						angle={-20}
						textAnchor="end"
						interval={0}
						tick={{ fontSize: "10px" }}
					/>
					<YAxis
						tickFormatter={(v) => formatTime(v)}
						style={{ fontSize: "10px" }}
						tickCount={10}>
						<Label
							value={"Average Service Time"}
							position="left"
							angle={-90}
							style={{ textAnchor: "middle", fontSize: "12px" }}
						/>
					</YAxis>
					<Tooltip
						formatter={(val, name, props) => {
							let period = name.substr(0, 2) == "p1" ? "Period 1" : "Period 2";
							let regKey = name.substr(3);
							let regName = selectedRegions.filter((x) => x.key == regKey)[0]
								.display_name;
							return [formatTime(val), `${period} ${regName}`];
						}}
						wrapperStyle={{ fontSize: "12px", opacity: ".95" }}
					/>
					<Legend
						payload={selectedRegions.map((reg) => {
							return {
								value: reg.display_name,
								type: "rect",
								color: colorDict[reg.key],
							};
						})}
						verticalAlign="top"
						wrapperStyle={{ fontSize: "12px", top:'-.1px' }}
					/>
					{
						periodToggle == 'both' || periodToggle == 'period1' ? 
						selectedRegions.map((reg) => {
							return (
								<Bar
									dataKey={`p1_${reg.key}`}
									stackId="p1"
									key={`p1_${reg.key}`}
									fill={colorDict[reg.key]}
								/>
							);
						}) 
						: null
					}
					{
						periodToggle == 'both' || periodToggle == 'period2' ? 
						selectedRegions.map((reg) => {
							return (
								<Bar
									dataKey={`p2_${reg.key}`}
									stackId="p2"
									key={`p2_${reg.key}`}
									fill={colorDict[reg.key]}
								/>
							);
						}) : null
					}
				</BarChart>
			</ResponsiveContainer>
		);
	};

	/**
	 * Helper to set the location based on the display name
	 * @param {STring} name location display name
	 */
	const setLocationHelper = (name) => {
		let match = selectedLocations.filter((x) => x.display_name == name)[0];
		setSelectedLocation(match);
	};

	return (
		<div className="bg-white rounded-lg shadow-lg p-4 w-full">
			<div className="sm:flex sm:flex-wrap sm:justify-between grid grid-cols-1 gap-2">
				<div>Regions & Day Parts</div>
				<div>
					<MultiSelectDropdownMenu
						options={
							regionData == undefined
								? []
								: regionData[
										dtToggle ? "drive_thru_regions2" : "lobby_regions2"
								  ].map((x) => x.display_name)
						}
						toggleOption={regionToggleHelper}
						selected={
							selectedRegions == undefined
								? []
								: selectedRegions.map((x) => x.display_name)
						}
						label="Regions"
					/>
				</div>
				<div>
					<DropdownMenu
						options={
							selectedLocations == undefined
								? []
								: selectedLocations.map((x) => x.display_name)
						}
						setOptions={setLocationHelper}
						selected={
							selectedLocation == undefined
								? null
								: selectedLocation.display_name
						}
					/>
				</div>
			</div>
			<div className='h-96'>{renderGraph()}</div>
		</div>
	);
}
