import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useDataContext } from '../contexts/DataContext';
import { analytics } from '../firebase';
import { useLocation } from 'react-router-dom';

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import VideoGrid from '../partials/cameras/VideoGrid';

import CameraOptions from '../partials/cameras/CameraOptions';

import { getCustomerData, getLocationDBData } from './cameraHelpers';
import CarLineTable from '../partials/dashboard2/CarLineTable';

const aws = require('aws-sdk');

// The ids to show the data table for.
const SHOW_TABLE_IDS = [26];

function Applications() {
  // Contexts
  const { currentUser } = useAuth();
  const { routes, oldLocations } = useDataContext();
  const location = useLocation();

  // States
  let today = new Date();
  let yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  yesterday.setHours(12);
  yesterday.setMinutes(30);

  /**
   * @todo Programatically determine if the current franchise is a drive-thru.
   */
  const [isDriveThru, setIsDriveThru] = useState(true);

  // The date and time of the video we are viewing.
  const [videoDateTime, setVideoDateTime] = useState(yesterday);
  // Whether or not we are viewing live video.
  const [isLive, setIsLive] = useState(false);
  const [customerData, setCustomerData] = useState();
  // The list of locations that the customer has access to.
  const [customerLocations, setCustomerLocations] = useState([]);
  const [currentLocation, setCurrentLocation] = useState();
  const [cameras, setCameras] = useState(['output']);
  const [page, setPage] = useState(); // undefined of [pageOn, numPages]
  const [selectedCameras, setSelectedCameras] = useState([0]);
  const [singleView, setSingleView] = useState(false);
  const DEFAULTNUMSLOTS = 4;
  const [numSlots, setNumSlots] = useState(DEFAULTNUMSLOTS);

  const [isRedirect, setIsRedirect] = useState(false);
  const [redirectWithCams, setRedirectWithCams] = useState(false);

  const [hasCVStream, setHasCVStream] = useState(false);
  const [hasDTCVStream, setHasDTCVStream] = useState(false);
  const [showCVStream, setShowCVStream] = useState(false);
  const [showDTCVStream, setShowDTCVStream] = useState(false);
  const [hasAccess, setHasAccess] = useState(true);
  const [kinesisVideo, setKinesisVideo] = useState();

  /**
   * @todo Programatically set the date depending on what the user toggles.
   */
  const [date, setDate] = useState(new Date());

  // Template mandated state
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // Use Effect--on load
  useEffect(async () => {
    // Get Customer Locations
    let customerFSData = await getCustomerData(currentUser.uid);
    let locationData = await getLocationDBData(
      customerFSData.locations,
      currentUser,
      routes
    );

    console.log(customerFSData, locationData);
    setCustomerData(customerFSData);
    setCustomerLocations(locationData);
    let initialLocation = locationData.find(
      (d) => d.id == customerFSData.primary_location
    );

    aws.config.update({
      region:
        initialLocation.franchise_id == 9 ||
        initialLocation.franchise_id == 28 ||
        initialLocation.franchise_id == 30 ||
        initialLocation.franchise_id == 33 ||
	      initialLocation.franchise_id == 38 || 
        initialLocation.franchise_id == 41
          ? 'eu-west-1'
          : 'us-west-2',
      credentials: {
        accessKeyId: process.env.REACT_APP_KINESIS_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey:
          process.env.REACT_APP_KINESIS_APP_AWS_SECRET_ACCESS_KEY,
      },
    });
    setKinesisVideo(new aws.KinesisVideo());

    setCurrentLocation(initialLocation);
    checkAndRedirect(locationData);

    analytics.logEvent('camera_page');
  }, []);

  const checkAndRedirect = (locationData) => {
    // Get any search params
    let u = new URLSearchParams(location.search);
    let params = {};
    for (var pair of u.entries()) {
      params[pair[0]] = pair[1];
    }

    // Make sure we have all the required params
    let keys = Object.keys(params);
    let must = ['lid'];
    if (keys.length > 0 && must.every((a) => keys.includes(a))) {
      let lid = parseInt(params.lid);

      if (locationData.findIndex((x) => x.id === lid) === -1) {
        window.alert(`You do not have access to the request location, 
				if you think this is in error please contact your account admin or Hellometer`);
        return;
      }

      let cams;
      if (keys.includes('cams')) {
        cams = params.cams.split(',').map((x) => parseInt(x));
        setRedirectWithCams(true);
        setSelectedCameras(cams);
      }

      // Set params to see it
      setIsRedirect(true);

      if (keys.includes('dt')) {
        let dt = new Date(params.dt);
        setVideoDateTime(dt);
        setIsLive(false);
      } else {
        console.log('GOING LIVE');
        setIsLive(true);
      }
      let _loc = locationData.filter((x) => x.id === lid)[0];
      setCurrentLocation(_loc);
    } else {
      // we don't, so just skip it
      return;
    }
  };

  const checkCameraAccess = () => {
    if (Object.keys(customerData).includes('has_video_access')) {
      setHasAccess(customerData['has_video_access']);
    } else {
      setHasAccess(true);
    }
  };

  useEffect(() => {
    if (customerData != undefined) {
      checkCameraAccess();
    }
  }, [customerData]);

  // After initial redirect, turn off the redirect flag
  useEffect(() => {
    if (isRedirect) {
      setIsRedirect(false);
      setRedirectWithCams(false);
    }
  }, [cameras]);

  // Hack for live start on current location if IP
  useEffect(() => {
    if (currentLocation != undefined) {
      // If redirecting with parmas, then we don't wany live
      console.log('redirect', isRedirect);
      if (isRedirect) {
        console.log('CHANGE MADE');
        // setIsLive(false);
        initializeCamerasArray();
        return;
      }

      initializeCamerasArray();

      if (oldLocations.indexOf(currentLocation.id) == -1) {
        setIsLive(true);
      } else {
        setIsLive(false);
      }
    }
  }, [currentLocation]);

  useEffect(() => {
    if (currentLocation !== undefined && kinesisVideo !== undefined) {
      setShowCVStream(false);
      setShowDTCVStream(false);
      // check kinesis to see if any CV streams exist
      kinesisVideo.describeStream(
        { StreamName: `CV_${currentLocation.id}` },
        (err, data) => {
          if (err) {
            console.log('not cv stream');
            setHasCVStream(false);
          } else {
            setHasCVStream(true);
          }
        }
      );

      kinesisVideo.describeStream(
        { StreamName: `CV_DT_${currentLocation.id}` },
        (err, data) => {
          if (err) {
            console.log('no dt cv stream');
            setHasDTCVStream(false);
          } else {
            setHasDTCVStream(true);
          }
        }
      );
    }
  }, [currentLocation]);

  const getNumSlots = () => {
    return singleView ? 1 : numSlots;
  };

  useEffect(() => {
    // Set selected cameras to the first numSlots cameras
    console.log(selectedCameras);
    console.log(cameras);
    console.log(numSlots);
    if (numSlots < cameras.length) {
      console.log('Settings to');
      setSelectedCameras(cameras.slice(0, numSlots));
    } else {
      console.log('Not enough cameras for grid to be relevant');
      setSelectedCameras(cameras);
    }
  }, [numSlots]);

  // Use Effect to manage pages by selectedCameras
  useEffect(() => {
    let numSlots = getNumSlots();

    if (selectedCameras.length > numSlots) {
      console.log('Case 1');
      // Take first four as selected and set pages
      setSelectedCameras(selectedCameras.slice(0, numSlots));
      setPage([1, Math.ceil(selectedCameras.length / numSlots)]);
    } else if (
      (selectedCameras.length < numSlots) &
      (selectedCameras.length > 1)
    ) {
      // Do nothing
      // Update the page tracker
      console.log('Page tracker should update here... figure out logic');
      console.log('Case 2');
      return;
    } else if (singleView & (cameras.length > 1)) {
      console.log('Case 3');
      // We have focused on a single camera but there are more than one
      setPage([cameras.indexOf(selectedCameras[0]) + 1, cameras.length]);
    } else if (selectedCameras == cameras) {
      setPage(undefined);
    } else {
      console.log('Catch case');
    }
  }, [selectedCameras]);

  const nextPage = () => {
    // go to next page, update selected cameras and page tracker
    if (page[0] < page[1]) {
      // There is more we can do
      // Find out where the current sequence starts
      let numSlots = getNumSlots();
      let currStart = cameras.indexOf(selectedCameras[0]);
      setSelectedCameras(
        cameras.slice(currStart + numSlots, currStart + 2 * numSlots)
      );
      setPage([page[0] + 1, page[1]]);
    } else {
      // there is no next
      return;
    }
  };

  const prevPage = () => {
    // We can go back
    let numSlots = getNumSlots();
    let currStart = cameras.indexOf(selectedCameras[0]);
    let newStart = Math.max(0, currStart - numSlots);
    setSelectedCameras(cameras.slice(newStart), newStart + numSlots);
    setPage([page[0] - 1, page[1]]);
  };

  /**
   * Takes in string address and sets the current location
   * with the full location data (not just the string address)
   * @param {STring} address
   */
  const updateCustomerLocation = (address) => {
    // find the piece of data with matching address
    let match = customerLocations.find((d) => d.address === address);
    if (match == undefined) {
      // must have used the reference
      match = customerLocations.find((d) => d.display_name === address);
    }
    setCurrentLocation(match);
  };

  const initializeCamerasArray = () => {
    if (currentLocation != undefined) {
      let cams;
      console.log(currentLocation);

      if (oldLocations.indexOf(currentLocation.id) != -1) {
        // This is an old location
        if (currentLocation.has_drive_through) {
          cams = ['output', 'entry', 'exit'];
        } else {
          cams = ['output'];
        }
      } else {
        // New way of getting the cameras DYNAMICALLY from the DB
        // fetch("https://hellometer.uc.r.appspot.com/location/get/"+currentLocation.id)

        if (currentLocation.id == 23) {
          console.log('Camera hack for Firestone');
          let cams = [1, 3, 4, 5, 0, 2, 6];
          setCameras(cams);
          // setSelectedCameras(cams);
          if (!isRedirect) {
            // If redirecting, we already dealt with selected cameras
            setSelectedCameras(cams);
          }
          return;
        } else if (currentLocation.id == 102) {
          console.log('Camera hack for Take5 VAuto');
          let cams = [1, 2, 3, 8, 0, 4, 5, 6, 7];
          setCameras(cams);
          // setSelectedCameras(cams);
          if (!isRedirect) {
            // If redirecting, we already dealt with selected cameras
            setSelectedCameras(cams);
          }
          return;
        } else if (currentLocation.id == 205) {
          console.log('Camera hack for Hardees');
          let cams = [7, 8, 10, 2, 3, 0, 1, 4, 5, 6, 9];
          setCameras(cams);
          // setSelectedCameras(cams);
          if (!isRedirect) {
            // If redirecting, we already dealt with selected cameras
            setSelectedCameras(cams);
          }
          return;
        } else if (currentLocation.id == 209) {
          console.log('Camera hack for Take5 VAuto');
          let cams = [2, 5, 7, 8, 0, 1, 3, 4, 6, 9];
          setCameras(cams);
          // setSelectedCameras(cams);
          if (!isRedirect) {
            // If redirecting, we already dealt with selected cameras
            setSelectedCameras(cams);
          }
          return;
        } else if (currentLocation.id == 225) {
          console.log('Camera hack for Take5 VAuto');
          let cams = [1, 2, 4, 6, 0, 3, 5, 7, 8, 9];
          setCameras(cams);
          // setSelectedCameras(cams);
          if (!isRedirect) {
            // If redirecting, we already dealt with selected cameras
            setSelectedCameras(cams);
          }
          return;
        } else if (currentLocation.id == 241) {
          console.log('Camera hack for Take5 VAuto');
          let cams = [1, 4, 5, 6, 7, 0, 2, 8, 3];
          setCameras(cams);
          // setSelectedCameras(cams);
          if (!isRedirect) {
            // If redirecting, we already dealt with selected cameras
            setSelectedCameras(cams);
          }
          return;
        } else if (currentLocation.id == 242) {
          console.log('Camera hack for Take5 VAuto');
          let cams = [0, 4, 5, 7, 1, 2, 3, 6, 8];
          setCameras(cams);
          // setSelectedCameras(cams);
          if (!isRedirect) {
            // If redirecting, we already dealt with selected cameras
            setSelectedCameras(cams);
          }
          return;
        } else if (currentLocation.id == 368) {
          console.log('Camera hack for Take5 VAuto');
          let cams = [3, 4, 5, 8, 0, 1, 2, 6, 7];
          setCameras(cams);
          // setSelectedCameras(cams);
          if (!isRedirect) {
            // If redirecting, we already dealt with selected cameras
            setSelectedCameras(cams);
          }
          return;
        } else if (currentLocation.id == 369) {
          console.log('Camera hack for Take5 VAuto');
          let cams = [1, 3, 6, 7, 2, 4, 5, 8, 0];
          setCameras(cams);
          // setSelectedCameras(cams);
          if (!isRedirect) {
            // If redirecting, we already dealt with selected cameras
            setSelectedCameras(cams);
          }
          return;
        } else if (currentLocation.id === 283) {
          console.log('Camera hack for Take5 VAuto');
          let cams = [0, 4, 5, 7, 1, 2, 3, 6, 8];
          setCameras(cams);
          if (!isRedirect) {
            // If redirecting, we already dealt with selected cameras
            setSelectedCameras(cams);
          }
          return;
	 } else if (currentLocation.id === 371) {
          console.log('Camera hack for Take5 VAuto');
          let cams = [2, 3, 4, 5, 0, 1, 6, 7, 8];
          setCameras(cams);
          if (!isRedirect) {
            // If redirecting, we already dealt with selected cameras
            setSelectedCameras(cams);
          }
          return;
        } else if (currentLocation.id === 271) {
          console.log('Camera hack for Take5 VAuto');
          let cams = [2, 3, 4, 5, 0, 1, 6, 7, 8];
          setCameras(cams);
          if (!isRedirect) {
            // If redirecting, we already dealt with selected cameras
            setSelectedCameras(cams);
          }
          return;
        }
        fetch(routes['locationGet'] + currentLocation.id)
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            let cameras = [];
            data.devices.forEach((dev) => {
              console.log(dev.cameras);
              dev.cameras.forEach((cam) => cameras.push(cam.storage_id)); // figure out how to pass cam with all its stuff
            });
            console.log('Cameras: ', cameras);
            cameras = cameras.sort();
            setCameras(cameras);

            // REDIRECT LOGIC-----

            // Want all cameras on redirect use this
            // setSelectedCameras(cameras);
            // return;

            // Not redirecting, or want all cameras
            if (!isRedirect || !redirectWithCams) {
              setSelectedCameras(cameras);
            } else {
              // do nothing, we already delt with selected cameras
            }
          });

        return;
      }

      // This is legacy... idk that we ever get here?
      console.log('WE ARE HERE????');
      setCameras(cams);
      setSelectedCameras(cams);
      // if (!isRedirect){
      // 	// If redirecting, we already dealt with selected cameras
      // 	setSelectedCameras(cams);
      // }
    }
  };

  if (!hasAccess) {
    return (
      <div className="flex h-screen overflow-hidden">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <Header
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            title="Cameras"
          />
          <main>
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full mx-auto">
              <h1>
                You do not have access to view video, if you think this is in
                error, please contact your administrator.
              </h1>
            </div>
          </main>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          title="Cameras"
        />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full mx-auto">
            {/* Page content */}
            <div>
              <div className="bg-white shadow-lg rounded-lg mb-2">
                <div className="flex flex-row">
                  <CameraOptions
                    videoDateTime={videoDateTime}
                    setVideoDateTime={setVideoDateTime}
                    isLive={isLive}
                    setIsLive={setIsLive}
                    customerLocations={customerLocations}
                    currentLocation={currentLocation}
                    updateCustomerLocation={updateCustomerLocation}
                    setCurrentLocation={setCurrentLocation}
                    cameras={cameras}
                    selectedCameras={selectedCameras}
                    setSelectedCameras={setSelectedCameras}
                    singleView={singleView}
                    setSingleView={setSingleView}
                    numSlots={numSlots}
                    setNumSlots={setNumSlots}
                    hasCVStream={hasCVStream}
                    hasDTCVStream={hasDTCVStream}
                    showCVStream={showCVStream}
                    setShowCVStream={setShowCVStream}
                    showDTCVStream={showDTCVStream}
                    setShowDTCVStream={setShowDTCVStream}
                  />
                </div>
              </div>

              {/* Camera Grid Display */}
              <div className="mt-2">
                <VideoGrid
                  currentLocation={currentLocation}
                  videoDateTime={videoDateTime}
                  setVideoDateTime={setVideoDateTime}
                  isLive={isLive}
                  setIsLive={setIsLive}
                  cameras={selectedCameras}
                  page={page}
                  nextPage={nextPage}
                  prevPage={prevPage}
                  showCVStream={showCVStream}
                  showDTCVStream={showDTCVStream}
                />
              </div>
            </div>
          </div>
          {customerLocations &&
            currentLocation &&
            currentLocation.has_drive_through &&
            SHOW_TABLE_IDS.includes(currentLocation.franchise_id) && (
              <CarLineTable
                customerLocationData={customerLocations}
                franchise={currentLocation.franchise}
                date={date}
                isDriveThru={isDriveThru}
                selectedLocation={currentLocation}
                setDate={setVideoDateTime}
                setIsLive={setIsLive}
              />
            )}
        </main>
      </div>
    </div>
  );
}

export default Applications;
