import React, { useRef, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Link, useHistory } from 'react-router-dom';

// import AuthImage from '../images/auth-image.jpg';
import AuthImage from '../assets/hellometer_cover_horiz.png';
import AVSImage from '../assets/avs/avs_cover.png';

function Signin() {
  const emailRef = useRef<HTMLInputElement>();
  const passwordRef = useRef<HTMLInputElement>();
  const { login } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // used to prevent clicking signup
  // mutiple times and creating multiple accounts
  const history = useHistory();

  /**
   * Handles submit from login form
   * passes given credientials to firebase helpers in AuthContext
   * and verifies they are correct
   * @param {Event} e
   */
  async function handleSubmit(e) {
    e.preventDefault(); // prevent form from refreshing

    try {
      setError('');
      setLoading(true);
      if (emailRef.current && passwordRef.current) {
        await login(emailRef.current.value, passwordRef.current.value);

        // made it here, so login succesfful
        setLoading(false);
        history.push('/');
      }
    } catch {
      setError('Failed to login');
      setLoading(false);
    }
  }

  const getAuthImage = () => {
    if (window.location.href.includes('avs.hellometer.io')) {
      return AVSImage;
    } else {
      return AuthImage;
    }
  };

  return (
    <main className="bg-white">
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-1/2">
          <div className="max-w-sm mx-auto min-h-screen flex flex-col justify-center px-4 py-8">
            <div className="w-full">
              <h1 className="text-3xl text-gray-800 font-bold mb-6">
                Welcome back! ✨
              </h1>
              {/* Form */}
              {error && (
                <div className="mt-5">
                  <div className="bg-red-100 text-red-600 px-3 py-2 rounded">
                    <svg
                      className="inline w-3 h-3 flex-shrink-0 fill-current mr-2"
                      viewBox="0 0 12 12"
                    >
                      <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                    </svg>
                    <span className="text-sm">{error}</span>
                  </div>
                </div>
              )}
              <form onSubmit={handleSubmit}>
                <div className="space-y-4">
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="email"
                    >
                      Email Address
                    </label>
                    <input
                      id="email"
                      ref={emailRef}
                      className="form-input w-full"
                      type="email"
                      data-cy="email"
                    />
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <input
                      id="password"
                      ref={passwordRef}
                      className="form-input w-full"
                      type="password"
                      autoComplete="on"
                      data-cy="password"
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between mt-6">
                  <div className="mr-1">
                    <Link
                      className="text-sm underline hover:no-underline"
                      to="/reset-password"
                    >
                      Forgot Password?
                    </Link>
                  </div>
                  <button
                    disabled={loading}
                    className="btn bg-blue-400 hover:bg-blue-600 text-white ml-3"
                    type="submit"
                    data-cy="submit"
                  >
                    Sign In
                  </button>
                </div>
              </form>
              {/* Footer */}
              <div className="pt-5 mt-6 border-t border-gray-200"></div>
            </div>
          </div>
        </div>

        {/* Image */}
        <div
          className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
          aria-hidden="true"
        >
          <img
            className="object-cover object-center w-full h-full"
            src={getAuthImage()}
            width="760"
            height="1024"
            alt="Authentication"
          />
        </div>
      </div>
    </main>
  );
}

export default Signin;
