import React, { useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { useAuth } from '../contexts/AuthContext';
import AuthImage from '../assets/hellometer_cover_horiz.png';
import AVSImage from '../assets/avs/avs_cover.png';

function Signup() {
  const emailRef = useRef<HTMLInputElement>();
  const passwordRef = useRef<HTMLInputElement>();
  const passwordConfirmRef = useRef<HTMLInputElement>();
  const { signup, checkWhiteList, createFirestoreCustomerEntry } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // used to prevent clicking signup
  // mutiple times and creating multiple accounts
  const history = useHistory();

  /**
   * Handles form submit for signup, checks to ensure that the
   * passwords match, ensurest the the email is in the white list
   * and if those are met then makes a call to firebase signup via auth-context
   * @param {Event} e
   */
  async function handleSubmit(e) {
    e.preventDefault(); // prevent form from refreshing
    // CHecks
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError('Passwords do not match');
    }
    if (passwordRef.current.value.length < 6) {
      return setError('Password must be at least 6 characters');
    }
    setLoading(true);
    let wl = await checkWhiteList(emailRef.current.value);
    if (!wl) {
      return setError('Email is not registered, please contact support');
    }
    setError('');
    setLoading(true);
    let signupReturn = await signup(
      emailRef.current.value,
      passwordRef.current.value
    );
    console.log(signupReturn.user.uid);
    // Create Firestore Entry
    createFirestoreCustomerEntry(signupReturn.user.uid);

    history.push('/');

    setLoading(false);
  }

  const getAuthImage = () => {
    if (window.location.href.includes('localhost')) {
      return AVSImage;
    } else {
      return AuthImage;
    }
  };

  return (
    <main className="bg-white">
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-1/2">
          <div className="max-w-sm mx-auto min-h-screen flex flex-col justify-center px-4 py-8">
            <div className="w-full">
              <h1 className="text-3xl text-gray-800 font-bold mb-6">
                Create your Account ✨
              </h1>
              {error && (
                <div className="mt-5">
                  <div className="bg-red-100 text-red-600 px-3 py-2 rounded">
                    <svg
                      className="inline w-3 h-3 flex-shrink-0 fill-current mr-2"
                      viewBox="0 0 12 12"
                    >
                      <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                    </svg>
                    <span className="text-sm">{error}</span>
                  </div>
                </div>
              )}
              {/* Form */}
              <form onSubmit={handleSubmit}>
                <div className="space-y-4">
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="email"
                    >
                      Email Address <span className="text-red-500">*</span>
                    </label>
                    <input
                      id="email"
                      className="form-input w-full"
                      type="email"
                      ref={emailRef}
                      data-cy="email"
                    />
                  </div>

                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <input
                      id="password"
                      className="form-input w-full"
                      type="password"
                      ref={passwordRef}
                      autoComplete="on"
                      data-cy="password"
                    />
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      Confirm Password
                    </label>
                    <input
                      id="passwordConfirm"
                      className="form-input w-full"
                      type="password"
                      ref={passwordConfirmRef}
                      autoComplete="on"
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between mt-6">
                  <button
                    disabled={loading}
                    type="submit"
                    className="btn bg-blue-400 hover:bg-blue-600 text-white ml-3 whitespace-nowrap"
                  >
                    Sign Up
                  </button>
                </div>
              </form>
              {/* Footer */}
              <div className="pt-5 mt-6 border-t border-gray-200">
                <div className="text-sm">
                  Have an account?{' '}
                  <Link
                    className="font-medium text-blue-500 hover:text-blue-600"
                    to="/signin"
                  >
                    Sign In
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Image */}
        <div
          className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
          aria-hidden="true"
        >
          <img
            className="object-cover object-center w-full h-full"
            src={getAuthImage()}
            width="760"
            height="1024"
            alt="Authentication"
          />
        </div>
      </div>
    </main>
  );
}

export default Signup;
