import React from 'react';
import dateFormat from "dateformat";
import { Link } from 'react-router-dom';

const formatSeconds = (s) => {
    if ( (s == null) || (isNaN(s)) ){ return "--"}
    let minutes = Math.floor(s / 60);
    let seconds = Math.round(s % 60);
    if (minutes == 0){
        return `${seconds < 10 ? '0' + seconds : seconds}s`
    }
    return `${minutes}m ${seconds < 10 ? '0' + seconds : seconds}s`
}


const UTCStringToLocalDate = (s, tz_string) => {
    // Split
    let dt = s.split(/[: T-]/).map(parseFloat);

    let d = Date.UTC(dt[0], dt[1]-1, dt[2], dt[3], dt[4], dt[5]);
    let localString = (new Date(d)).toLocaleString('en-US', {timeZone:tz_string});

    // Now back to a datetimeobject that is tz wrong, but all else good
    let nd = new Date(localString);
    return nd;
}

const renderTimeLink = (utcTime, location) => {
    // REnder the time link
    // let d = UTCStringToLocalDate(utcTime, location.time_zone);
    let d = UTCStringToLocalDate(utcTime, location.time_zone);
    // console.log("Hard coded TIMEZONE TODO")
    let d2 = new Date(d.valueOf()); //1000 = ms/s
    let formatted = dateFormat(d, 'mediumTime');
    let params = {
        dt: dateFormat(d2, 'yyyy-mm-dd') + 'T' + dateFormat(d2, 'HH:MM'),
        lid: location.id
    }
    // console.log("HARD CODED LOCATION ID")

    let u = new URLSearchParams(params);
    let to = '/applications?' + u.toString();
    return (
        <Link 
            className='underline text-blue-600 hover:text-blue-800 visited:text-purple-600' 
            to={to}>
            
            {formatted}
        </Link>
    )
}

export {
    formatSeconds as formatSeconds,
    renderTimeLink as renderTimeLink
}