import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useDataContext } from '../contexts/DataContext';

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import DriveThruToggle from '../partials/dashboard2/DriveThruToggle';
import SingleDatepicker from '../partials/actions/SingleDatepicker';
import DropdownMenu from '../partials/actions/DropdownMenu';

import RankTable from '../partials/dashboard2/RankTable';
import DayPartTable from '../partials/dashboard2/DayPartTable';
import RegionTable from '../partials/dashboard2/RegionTable';
import CarLineTable from '../partials/dashboard2/CarLineTable';
import DropDownFilter from '../partials/actions/DropDownFilter';
import { analytics } from '../firebase';
import {
  getCustomerLocationData,
  getCacheKey,
  getFranchises,
} from '../helpers/location';

export default function Dashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [isDriveThru, setIsDriveThru] = useState(false);
  const [date, setDate] = useState(new Date());
  const [tokenState, setToken] = useState();
  const [customerLocationData, setCustomerLocationData] = useState();
  const [franchises, setFranchises] = useState();
  const [franchise, setFranchise] = useState();
  const [filteredLocations, setFilteredLocations] = useState();

  // Contexts
  const { currentUser } = useAuth();
  const { routes, getCacheData, setCache, oldFranchises } = useDataContext();

  const getToken = async () => {
    let token = await currentUser.getIdToken();
    setToken(token);
  };

  useEffect(async () => {
    await getToken();

    const data = await getCustomerLocationData(
      currentUser.uid,
      getCacheKey(),
      getCacheData
    );
    setCustomerLocationData(data);
    setCache(getCacheKey(), data);
  }, []);

  // Hardees hard code helper, if switch to hardees, show DT
  useEffect(() => {
    if (franchise !== undefined) {
      analytics.setUserProperties({
        franchise_id: franchise.id,
      });
      localStorage.setItem('dashboardFranchise', franchise.id);

      // Check if we have a DT toggle value stored
      let franchiseDTToggle = localStorage.getItem(
        `f-${franchise.id}-dt-toggle`
      );
      if (franchiseDTToggle !== null) {
        setIsDriveThru(eval(franchiseDTToggle));
      } else {
        // if this franchise has any locations with DT, show the DT
        console.log('No stored preference');
        if (customerLocationData !== undefined) {
          let locs = customerLocationData.filter(
            (x) => x.franchise.id == franchise.id
          );
          console.log(locs);
          let hasDT = locs.reduce(
            (agg, cur) => agg || cur.has_drive_through,
            false
          );
          console.log('any have DT?', hasDT);
          if (hasDT) {
            console.log('Setting true');
            setIsDriveThru(true);
          } else {
            setIsDriveThru(false);
          }
        }
      }
    }

    if (franchise !== undefined && [6, 8].includes(franchise.id)) {
      setIsDriveThru(true);
    }
  }, [franchise]);

  // When DT toggle changes, update the preference for the franchise
  useEffect(() => {
    if (franchise !== undefined) {
      localStorage.setItem(`f-${franchise.id}-dt-toggle`, isDriveThru);
    }
  }, [isDriveThru]);

  useEffect(() => {
    if (customerLocationData == undefined) {
      setFranchises(undefined);
      setFranchise(undefined);
      return;
    }
    if (customerLocationData.length === 0) {
      setFranchises([]);
      return;
    }

    // Else we have some locations
    const franchiseList = getFranchises(customerLocationData);
    setFranchises(franchiseList);

    // set franchise with local storage if available
    let sf = localStorage.getItem('dashboardFranchise');
    if (sf) {
      sf = parseInt(sf);
      if (franchiseList.map((x) => x.id).includes(sf)) {
        setFranchise(franchiseList.filter((x) => x.id == sf)[0]);
      } else {
        setFranchise(franchiseList[0]);
      }
    } else {
      setFranchise(franchiseList[0]);
    }
  }, [customerLocationData]);

  useEffect(() => {
    if (franchise !== undefined && filteredLocations !== undefined) {
      localStorage.setItem(
        `f-${franchise.id}-locations-toggle`,
        filteredLocations.map((x) => x.id)
      );
    }
  }, [filteredLocations]);

  useEffect(() => {
    if (customerLocationData !== undefined && franchise !== undefined) {
      let cachedLocations = localStorage.getItem(
        `f-${franchise.id}-locations-toggle`
      );
      if (cachedLocations != null) {
        // Does user still has access to all those
        cachedLocations = cachedLocations.split(',').map(Number);
        setFilteredLocations(
          customerLocationData.filter((x) => cachedLocations.includes(x.id))
        );
      } else {
        setFilteredLocations(
          customerLocationData.filter((x) => x.franchise.id == franchise.id)
        );
      }
    }
  }, [customerLocationData, franchise]);

  const setFranchiseHelper = (name) => {
    // find franchise object with matching name
    let match = franchises.filter((x) => x.name == name)[0];
    setFranchise(match);

    // Have to clear here otherwise causinga a bug
    setFilteredLocations();
  };

  const renderFranchiseFilter = () => {
    if (franchises == undefined) {
      // Render a loader
      return <div className="animate-pulse bg-blue-400 rounded w-56 h-2"></div>;
    } else if (franchises.length <= 1) {
      return;
    }

    // Else we have multiple franchises, so lets make a dropdown
    return (
      <DropdownMenu
        options={franchises.map((x) => x.name)}
        setOptions={setFranchiseHelper}
        selected={franchise.name}
      />
    );
  };

  const renderLocationFilter = () => {
    if (franchise == undefined || customerLocationData == undefined) {
      return null;
    }

    let filterFunc = (opt, val) => {
      let l = opt.display_name.toLowerCase();
      // console.log(l, val);
      return l.includes(val);
    };

    return (
      <DropDownFilter
        options={customerLocationData.filter(
          (x) => x.franchise.id == franchise.id
        )}
        selected={filteredLocations}
        setSelected={setFilteredLocations}
        filterFunc={filterFunc}
        selectedDisplayFunc={(x) => x.display_name}
        optionKey={'id'}
      />
    );
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          title="Dashboard"
        />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="flex flex-wrap sm:flex sm:justify-between sm:items-center mb-8">
              <div className="flex flex-wrap justify-start gap-2 mb-4">
                {renderFranchiseFilter()}

                {renderLocationFilter()}
              </div>

              <div className="flex flex-wrap justify-start sm:justify-end gap-2 mb-4">
                <SingleDatepicker setDate={setDate} defaultDate={new Date()} />
                <DriveThruToggle
                  isDriveThru={isDriveThru}
                  setIsDriveThru={setIsDriveThru}
                />
              </div>
            </div>

            <div>
              <RankTable
                customerLocationData={customerLocationData}
                franchise={franchise}
                filteredLocations={filteredLocations}
                date={date}
                isDriveThru={isDriveThru}
                showCounts={
                  franchise != undefined &&
                  !oldFranchises.includes(franchise.id)
                }
              />
            </div>

            <div>
              <DayPartTable
                customerLocationData={customerLocationData}
                franchise={franchise}
                filteredLocations={filteredLocations}
                date={date}
                isDriveThru={isDriveThru}
                showCounts={
                  franchise != undefined &&
                  !oldFranchises.includes(franchise.id)
                }
              />
            </div>

            {franchise != undefined && !oldFranchises.includes(franchise.id) ? (
              <>
                <RegionTable
                  customerLocationData={customerLocationData}
                  franchise={franchise}
                  filteredLocations={filteredLocations}
                  date={date}
                  isDriveThru={isDriveThru}
                />
                <CarLineTable
                  customerLocationData={customerLocationData}
                  franchise={franchise}
                  date={date}
                  isDriveThru={isDriveThru}
                />
              </>
            ) : null}
          </div>
        </main>
      </div>
    </div>
  );
}
