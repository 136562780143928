import React, { useState, useEffect } from "react";
import { useDataContext } from "../../contexts/DataContext";
import { DateTime } from "luxon";
// import ReactLoading from 'react-loading';
import Loader from "../../utils/Loader";
import app from "../../firebase";
import { dateToString, formatTime } from "./analyticsHelpers";
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	Label,
	ResponsiveContainer,
} from "recharts";
import MultiSelectDropdownMenu from "../actions/MultiSelectDropdown";
import DropdownMenu from "../actions/DropdownMenu";

export default function RegionComparisonWidget({
	selectedLocations,
	dtToggle,
	period1,
	period2,
	periodToggle,
}) {
	// States
	const [graphLocation, setGraphLocation] = useState();
	const [regionData, setRegionData] = useState();
	const [selectedRegions, setSelectedRegions] = useState();
	const [locationData, setLocationData] = useState();
	const [period1Data, setPeriod1Data] = useState();
	const [period2Data, setPeriod2Data] = useState();
	const [legendDict, setLegendDict] = useState({
		p1: "Period 1",
		p2: "Period 2",
	});

	// Context
	const { routes, getCacheData, setCache } = useDataContext();

	const propsLoaded = () => {
		return (
			dtToggle != undefined &&
			// && graphLocation != undefined
			// && selectedRegions != undefined
			selectedLocations != undefined &&
			regionData != undefined &&
			period1 != undefined &&
			period2 != undefined
		);
	};

	/**
	 * When key data parameters change, querry for the data
	 */
	useEffect(async () => {
		if (!propsLoaded()) {
			return;
		}
		// console.log("effect");
		// let p1d = await querryData(period1);
		// let p2d = await querryData(period2);
		let [p1d, p2d] = await Promise.all([
			querryData(period1),
			querryData(period2),
		]);

		setPeriod1Data(p1d);
		setPeriod2Data(p2d);
		// }, [period1, period2, graphLocation, selectedRegions]);
	}, [period1, period2, selectedLocations, regionData, dtToggle]);

	const querryData = async (p) => {
		// Cache stuff
		let key = `comparison-region-graph-${dateToString(p[0])}-${dateToString(
			p[1]
		)}-${dtToggle}-${graphLocation.id}-${regionData[
			dtToggle ? "drive_thru_regions2" : "lobby_regions2"
		]
			.map((x) => x.key)
			.join("-")}`;
		// console.log(key);
		let cacheData = getCacheData(key);
		if (cacheData != undefined) {
			return cacheData;
		}

		// If no regions, nothing to show
		// console.log(regionData);
		if (regionData[dtToggle ? 'drive_thru_regions2' : 'lobby_regions2'].length == 0) {
			return [];
		}

		let params = {
			start_date: dateToString(p[0]),
			end_date: dateToString(p[1]),
			// locations: [graphLocation.id],
			locations: selectedLocations.map((x) => x.id),
			is_drive_through: dtToggle,
			// regions: selectedRegions.map(x => x.key),
			regions: regionData[
				dtToggle ? "drive_thru_regions2" : "lobby_regions2"
			].map((x) => x.key),
			which: ["region"],
		};

		let url = routes["comparisonData"];
		let data = await fetch(url, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(params),
		})
			.then((res) => res.json())
			.then((data) => {
				data = JSON.parse(data["region"]);
				setCache(key, data);
				return data;
			})
			.catch((err) => {
				console.warn(err);
				return undefined;
			});

		return data;
	};

	/**
	 * If selected locations change:
	 * - If graphDLocation is undefined (would happen on first load)
	 * 	+ set grpah location to some selected Location
	 * - check to see if graphLocation in new selected regions
	 * 	+ if so, do nothing
	 * - else (i.e., graphLocation is not among possible choices)
	 * 	+ default to a new graph location
	 * 	+ run through data sequence
	 */
	useEffect(() => {
		if (selectedLocations == undefined) {
			return;
		}
		if (graphLocation == undefined) {
			setGraphLocation(selectedLocations[0]);
			return;
		}
		if (selectedLocations.map((x) => x.id).includes(graphLocation.id)) {
			return;
		} else {
			// Update graphLocation
			setGraphLocation(selectedLocations[0]);

			// uPdate data
		}
	}, [selectedLocations]);

	/**
	 * Whenever Graph Location changes we have to get the region data, i.e.,
	 * hit firebase
	 */
	useEffect(() => {
		if (graphLocation == undefined) {
			return;
		}
		const db = app.firestore();
		const docRef = db.collection("location").doc(`${graphLocation.id}`);
		docRef.get().then((doc) => {
			let data = doc.data();
			// extract lobby AND dt regions (if it has them)
			let obj = {};
			// let keys = ['drive_thru_regions', 'drive_thru_region_order', 'lobby_regions', 'lobby_region_order'];
			let keys = ["drive_thru_regions2", "lobby_regions2"];
			keys.forEach((key) => {
				if (Object.keys(data).includes(key)) {
					obj[key] = data[key];
				} else {
					obj[key] = [];
				}
			});
			setRegionData(obj);
		});
	}, [graphLocation]);

	// Update the Legend Dict
	useEffect(() => {
		let obj = Object.assign({}, legendDict);
		if (period1 != undefined) {
			let d1 = DateTime.fromJSDate(period1[0]);
			let d2 = DateTime.fromJSDate(period1[1]);
			obj["p1"] = `${d1.toLocaleString(
				DateTime.DATE_MED
			)} to ${d2.toLocaleString(DateTime.DATE_MED)}`;
		}
		if (period2 != undefined) {
			let d1 = DateTime.fromJSDate(period2[0]);
			let d2 = DateTime.fromJSDate(period2[1]);
			obj["p2"] = `${d1.toLocaleString(
				DateTime.DATE_MED
			)} to ${d2.toLocaleString(DateTime.DATE_MED)}`;
		}
		setLegendDict(obj);
	}, [period1, period2]);

	/**
	 * When region data changes, need to cross check it with the selected regions
	 * we accept any overlap, and if there is no overlap, we will reset selected
	 * regioons to be all possible new regions
	 */
	useEffect(() => {
		if (regionData == undefined || dtToggle == undefined) {
			return;
		}

		setSelectedRegions(
			dtToggle
				? regionData["drive_thru_regions2"]
				: regionData["lobby_regions2"]
		)

		return;
		// I no longer like the fancy overlap detector....
		if (selectedRegions == undefined || selectedRegions == []) {
			// set to all regions
			setSelectedRegions(
				dtToggle
					? regionData["drive_thru_regions2"]
					: regionData["lobby_regions2"]
			);
		} else {
			// check overlap
			// let newRegions = dtToggle ?
			// 	regionData['drive_thru_region_order']
			// 	: regionData['lobby_region_order'];
			let newRegions =
				regionData[dtToggle ? "drive_thru_regions2" : "lobby_regions2"];
			let overlap = selectedRegions.filter((x) =>
				newRegions.map((nr) => nr.display_name).includes(x.display_name)
			);

			// if overlap set it
			if (overlap.length > 0) {
				setSelectedRegions(overlap);
			}
			// else (no overlap) default to all
			else {
				setSelectedRegions(newRegions);
			}
		}
	}, [regionData, dtToggle]);

	const setLocationHelper = (loc) => {
		// Find match in selected
		let match = selectedLocations.filter((x) => x.display_name == loc)[0];

		// Set graph location with match
		setGraphLocation(match);
	};

	/**
	 * Takes in a region display name and toggles its option in
	 * selectedRegions
	 * @param {String} reg region display name
	 */
	const regionToggleHelper = (reg) => {
		if (selectedRegions.map((x) => x.display_name).includes(reg)) {
			// remove it
			setSelectedRegions(selectedRegions.filter((x) => x.display_name != reg));
		} else {
			// find matching object
			let item = regionData[
				dtToggle ? "drive_thru_regions2" : "lobby_regions2"
			].filter((x) => x.display_name == reg)[0];

			// add it
			setSelectedRegions((selectedRegions) => [...selectedRegions, item]);
		}
	};

	const renderRegionSelect = () => {
		// Determine which of previously selected
		return (
			<div className='text-xs'>
				<MultiSelectDropdownMenu
					options={
						regionData == undefined
							? []
							: regionData[
									dtToggle ? "drive_thru_regions2" : "lobby_regions2"
							  ].map((x) => x.display_name)
					}
					toggleOption={regionToggleHelper}
					selected={
						selectedRegions == undefined
							? []
							: selectedRegions.map((x) => x.display_name)
					}
					label="Regions"
				/>
			</div>
		);
	};

	const renderGraph = () => {
		// if (period1Data == undefined || period2Data == undefined || regionData == undefined){
		// 	return <Loader />
		// }
		if (
			period1Data == undefined ||
			period2Data == undefined ||
			selectedRegions == undefined ||
			graphLocation == undefined
		) {
			return <Loader />;
		}

		const getVal = (data, reg) => {
			let match = data.filter((x) => x.region == reg && x.location_id == graphLocation.id);
			return match.length == 0 ? 0 : match[0].avg;
		};

		// console.log("P1", period2Data);
		// console.log("P2", period2Data);

		// Organize Graph Data
		let plotData = [];
		// let order = regionData[dtToggle ? 'drive_thru_region_order' : 'lobby_region_order'];
		let order = regionData[
			dtToggle ? "drive_thru_regions2" : "lobby_regions2"
		].map((x) => x.key);
		let whichNames =
			regionData[dtToggle ? "drive_thru_regions2" : "lobby_regions2"];
		selectedRegions
			.sort((a, b) => order.indexOf(a.key) - order.indexOf(b.key))
			.forEach((reg) => {
				let obj = {};

				// get the display name
				let map = whichNames.filter((x) => x.key == reg.key)[0];
				// obj["name"] = map.display_name;
				obj["name"] = map == undefined ? "" : map.display_name;
				obj["reg_key"] = reg.key;
				obj["p1"] = getVal(period1Data, reg.key);
				obj["p2"] = getVal(period2Data, reg.key);
				plotData.push(obj);
			});

		// Make the graph
		return (
			<ResponsiveContainer width="100%" height="100%">
				<BarChart
					width={500}
					height={300}
					data={plotData}
					margin={{
						top: 10,
						right: 30,
						left: 20,
						bottom: 15,
					}}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis
						dataKey="name"
						angle={-20}
						textAnchor="end"
						interval={0}
						tick={{ fontSize: "10px" }}
					/>
					<YAxis
						tickFormatter={(v) => formatTime(v)}
						// domain={[0, "dataMax"]}
						style={{ fontSize: "10px" }}
						tickCount={10}>
						<Label
							value={"Average Service Time"}
							position="left"
							angle={-90}
							style={{ textAnchor: "middle", fontSize: "12px" }}
						/>
					</YAxis>
					<Tooltip
						formatter={(val, name, props) => [
							formatTime(val),
							legendDict[name],
						]}
						wrapperStyle={{ fontSize: "12px", opacity: ".95" }}
					/>
					<Legend
						formatter={(val, entry, index) => legendDict[val]}
						verticalAlign="top"
						wrapperStyle={{ fontSize: "12px", top:'-.1px' }}
					/>
					{
						periodToggle == 'both' || periodToggle == 'period1' ? 
						<Bar dataKey="p1" fill="#54BFF0" /> : null
					}
					{
						periodToggle == 'both' || periodToggle == 'period2' ? 
						<Bar dataKey="p2" fill="#FFA695" /> : null
					}
					{/* <Bar dataKey="p1" fill="#54BFF0" />
					<Bar dataKey="p2" fill="#FFA695" /> */}
				</BarChart>
			</ResponsiveContainer>
		);
	};

	return (
		// <div className="bg-white rounded-lg shadow-lg p-4 w-full h-96">
		<div className="bg-white rounded-lg shadow-lg p-4 w-full">
			{/* <div className="flex flex-wrap justify-between"> */}
			<div className="sm:flex sm:flex-wrap sm:justify-between grid grid-cols-1 gap-2">
				<div>Regions</div>
				<div>
					<DropdownMenu
						options={
							selectedLocations == undefined
								? []
								: selectedLocations.map((x) => x.display_name)
						}
						setOptions={setLocationHelper}
						selected={
							graphLocation == undefined ? null : graphLocation.display_name
						}
					/>
				</div>
				<div>
					{renderRegionSelect()}
				</div>
			</div>
			<div className='h-96'>
				{renderGraph()}
			</div>
		</div>
	);
}
