import { createContext, useState } from 'react';
import { LocationType } from '../types';

const defaults = {
  selectedLocation: {
    id: -1,
    address: '',
    address_2: '',
    city: '',
    state: '',
    country: '',
    zip_code: '',
    time_zone: 'America/Los_Angeles',
    display_name: '',
    franchise: {
      id: 0,
      name: '',
    },
    franchise_id: 0,
    has_drive_through: false,
    has_pos: false,
    reference: '',
    devices: [],
  },
  setSelectedLocation: () => {},
};

export const LocationContext = createContext<{
  selectedLocation: LocationType;
  setSelectedLocation: (location: LocationType) => void;
}>(defaults);

export const LocationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [selectedLocation, setSelectedLocation] = useState<LocationType>(
    defaults.selectedLocation
  );

  return (
    <LocationContext.Provider
      value={{
        selectedLocation,
        setSelectedLocation,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};
