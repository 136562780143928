import React, { useEffect, useState } from 'react';
import { useDataContext } from '../../contexts/DataContext';
import TableLoader from '../dashboard/TableLoader';
import CountToggle from './CountToggle';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

export default function DayPartTable({
  customerLocationData,
  franchise,
  filteredLocations,
  date,
  isDriveThru,
  showCounts,
}) {
  const [tableData, setTableData] = useState();
  const [dayParts, setDayParts] = useState();
  const [countToggle, setCountToggle] = useState(false);
  const [toggle, setToggle] = useState(''); // acceptable values: '' which is time, 'count', 'target_delta'
  const [sortDirection, setSortDirection] = useState(-1);
  const [sortColumn, setSortColumn] = useState();

  const { getCacheData, setCache, routes } = useDataContext();

  useEffect(() => {
    const abortController = new AbortController();
    if ((customerLocationData != undefined) & (franchise != undefined)) {
      // Get Data
      getTableData({ controller: abortController });
    }

    // Set interval refresh
    const id = setInterval(refreshData, 5 * 60 * 1000);
    return () => {
      clearInterval(id);
      abortController.abort();
    };
  }, [franchise, customerLocationData, date, isDriveThru]);

  // Hack so that when franchise changes always start by showing times
  useEffect(() => {
    setCountToggle(false);
    setToggle('');
  }, [franchise]);

  const getCacheKey = () => {
    let locs = customerLocationData
      .filter((x) => x.franchise.id == franchise.id)
      .map((x) => x.id);
    let dateString = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
    // let key = `dayparttable-${locs.join('-')}-${date.toISOString()}-${isDriveThru}`;
    let key = `dayparttable-${locs.join('-')}-${dateString}-${isDriveThru}`;
    return key;
  };

  const getTableData = ({
    override = false,
    controller = new AbortController(),
  }) => {
    // Check cache or override
    let key = getCacheKey();
    let cacheData = getCacheData(key);
    if ((cacheData != undefined) & !override) {
      // setTableData(cacheData);
      setTableData(cacheData.data);
      setDayParts(cacheData.dayParts);
      return;
    }

    // Clear it out will activate loader
    setTableData(undefined);

    // Fetch
    let locations = customerLocationData
      .filter((x) => x.franchise_id == franchise.id)
      .map((x) => x.id);
    let sendDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;
    let params = {
      locations: locations,
      // date: date,
      date: sendDate,
      is_drivethru: isDriveThru,
    };
    let url = routes['dayPartTable'];
    // fetch("http://localhost:5001/webapp2/getDaypartData", {
    fetch(url, {
      signal: controller.signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/JSON',
      },
      body: JSON.stringify(params),
    })
      .then((res) => res.json())
      .then((data) => {
        // let td = JSON.parse(data['data']);
        let td =
          typeof data['data'] == 'string'
            ? JSON.parse(data['data'])
            : data['data'];
        let dps = data['day_parts'];
        data = augmentLocations(td, dps);
        setTableData(data);
        setDayParts(dps);
        setCache(getCacheKey(), { data: data, dayParts: dps });
      })
      .catch((err) => console.warn(err));
  };

  /**
   * Takes in location data, and adds to it any locations that have no data
   */
  const augmentLocations = (data, dps) => {
    let missing = customerLocationData
      .filter((x) => !data.map((x) => x.Location).includes(x.display_name))
      .filter((x) => x.franchise_id == franchise.id);

    if (isDriveThru) {
      missing = missing.filter((x) => x.has_drive_through);
    }

    missing.forEach((dat) => {
      let d = { Location: dat.display_name };
      dps.forEach((dp) => (d[dp] = null));
      data.push(d);
    });
    return data;
  };

  const refreshData = () => {
    if ((customerLocationData != undefined) & (franchise != undefined)) {
      getTableData({ override: true });
    }
  };

  const formatSeconds = (s, showSign = false) => {
    if (s == null || isNaN(s)) {
      return '--';
    }
    if (s == 0) {
      return '--';
    }
    let sign;
    if (showSign) {
      sign = s < 0 ? '-' : '+';
    } else {
      sign = '';
    }
    s = Math.abs(s);
    let minutes = Math.floor(s / 60);
    let seconds = Math.round(s % 60);
    return `${sign}${minutes}m ${seconds < 10 ? '0' + seconds : seconds}s`;
  };

  /**
   * Takes in the value and the dap part, compares it to the rest of the
   * table data and decies how to style the cell (i.e., max or min coloring
   * for a given day part)
   * @param {seconds value} val
   * @param {day part name} dp
   * @param {Bool} inv indicates if we switch logic, i.e., highest gets green
   */
  const getCellStyle = (val, dp, inv = false) => {
    if (val == null || isNaN(val)) {
      return '';
    }
    // get other day part values
    let dayPartVals = tableData
      .map((x) => x[dp])
      .filter((x) => (x != null) & !isNaN(x));
    if (dayPartVals.length == 1) {
      // only one here, so do nothing
      return '';
    }

    // Else there are a bunch, so get colorful
    let maxVal = Math.max(...dayPartVals);
    let minVal = Math.min(...dayPartVals);

    // Handle inversion
    if (inv) {
      let _swp = minVal;
      minVal = maxVal;
      maxVal = _swp;
    }

    if (maxVal == val) {
      return 'text-white rounded-lg font-bold bg-red-500 px-2 py-1';
    }
    if (minVal == val) {
      return 'text-white rounded-lg font-bold bg-green-500 px-2 py-1';
    } else {
      return '';
    }
  };

  /**
   * Handles when a column was clicked.
   * Cases:
   *  first click on this column: set as sort column and set direction
   *  second click: keep sort column, flip direction
   *  third click: unset as sort column
   *
   * As we know this is the order, we can use the sort direction to help
   * us determine which number click it is
   * @param {String} col column name that was clicked
   */
  const colClick = (col) => {
    if (sortColumn != col) {
      // First click
      setSortColumn(col);
      setSortDirection(-1);
      return;
    }

    // Else, this is already the sort column, so rely on the sort direction
    if (sortDirection == -1) {
      // this is the second click
      setSortDirection(1);
    } else {
      // This is the third click--reset all
      setSortColumn();
      setSortDirection(-1);
    }
  };

  const sortData = (data) => {
    // Base case, sort by rank
    if (sortColumn == undefined) {
      return data.sort((a, b) => a.day_avg - b.day_avg);
    }

    // Column specific
    return data.sort((a, b) => {
      a = a[sortColumn + toggle];
      b = b[sortColumn + toggle];

      // always push undefined to the bottom
      if (a == undefined || a == null || b == undefined || b == null) {
        return (a == undefined || a == null) - (b == undefined || b == null);
      }

      let dir = a > b ? 1 : -1;
      return dir * sortDirection;
    });
  };

  const renderTable = () => {
    if (
      tableData == undefined ||
      dayParts == undefined ||
      franchise == undefined ||
      filteredLocations == undefined
    ) {
      return (
        <TableLoader
          columns={['Location', 'Breakfast', 'Lunch', 'Afternoon', 'Dinner']}
        />
      );
    }

    // Apply filtered locations
    let names = filteredLocations.map((x) => x.display_name);
    let filteredTableData = tableData.filter((x) => names.includes(x.Location));

    if (filteredTableData.length == 0) {
      return <div className="w-full text-center text-gray-400">No Data</div>;
    }

    // Handle data sorting
    let sorted = sortData(filteredTableData);

    // Else we have some legit data
    // let columns = Object.keys(filteredTableData[0]).filter(x => !x.includes("_count"));
    let columns = ['Location', ...dayParts];
    return (
      <table className="table-fixed w-full divide-y divide-gray-200 min-w-98">
        <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
          <tr>
            {columns.map((col) => {
              return (
                <th
                  className="p-2 w-24 sticky top-0 bg-gray-50"
                  key={`header-${col}`}
                  onClick={() => colClick(col)}
                >
                  <div className="text-left cursor-pointer select-none">
                    {col}
                    {col === sortColumn ? (
                      <span className="ml-1">
                        {sortDirection == -1 ? <>&#9650; </> : <>&#9660;</>}
                      </span>
                    ) : null}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 text-xs">
          {
            // filteredTableData.sort((a,b) => a.day_avg - b.day_avg).map(data => {
            sorted.map((data) => {
              return (
                <tr
                  className="hover:bg-gray-100 cursor-default my-4"
                  key={data.Location}
                >
                  <td className="p-2">
                    <Link
                      to={`/applications?lid=${data.location_id}`}
                      target="_blank"
                    >
                      {data.Location}
                    </Link>
                  </td>
                  {dayParts.map((dp) => {
                    let tooltipContent =
                      toggle == '_target_delta'
                        ? `Target: ${formatSeconds(data[dp + '_target'])}`
                        : null;
                    return (
                      <td className={`p-2`} key={`${data.location}-${dp}`}>
                        <Tooltip title={tooltipContent} arrow placement="top">
                          <span
                            className={`${getCellStyle(
                              data[dp + toggle],
                              dp + toggle,
                              toggle == '_count' ? true : false
                            )}`}
                          >
                            {toggle == '_count'
                              ? data[dp + '_count'] || '--'
                              : formatSeconds(
                                  data[dp + toggle],
                                  toggle == '_target_delta'
                                )}
                          </span>
                        </Tooltip>
                      </td>
                    );
                  })}
                </tr>
              );
            })
          }
        </tbody>
      </table>
    );
  };

  return (
    <>
      <div className="flex flex-col col-span-full bg-white shadow-lg rounded-lg border border-gray-200 mb-8">
        <header className="px-5 py-4 border-b border-gray-100">
          <div>
            <h2 className="font-semibold text-gray-800 inline">Day Parts</h2>

            <div className="flex float-right">
              {
                // isDriveThru || (franchise != undefined && franchise.id == 9) ? (
                showCounts ? (
                  <div className="mr-2">
                    <CountToggle val={toggle} setVal={setToggle} />
                  </div>
                ) : null
              }
              <button
                className="button bg-transparent hover:bg-blue-400
								text-blue-400 hover:text-white rounded-full
								border border-blue-400 py-1 px-2 text-xs"
                onClick={() => refreshData()}
              >
                Refresh
              </button>
            </div>
          </div>
          <div>
            <span className="mt-2 flex flex-wrap">
              <span className="text-white text-xs rounded-lg font-bold bg-green-500 px-2 py-1">
                Best Time
              </span>
              &nbsp;
              <span className="text-white text-xs rounded-lg font-bold bg-red-500 px-2 py-1">
                Worst Time
              </span>
            </span>
          </div>
        </header>
        <div className="p-3">
          {/* Table */}
          <div className="overflow-x-auto max-h-96">{renderTable()}</div>
        </div>
      </div>
    </>
  );
}
