/**
 * Creates the link that displays the departure time, but links to the
 * arrival time.
 */
import { Link } from 'react-router-dom';
import dateFormat from 'dateformat';

import { LocationType } from '../../types';
import { utcStringToLocalDate } from '../../helpers/time';

const TimeArrivalLink = ({
  utcTime,
  location,
  setDate,
  handleClick,
  setIsLive,
}: {
  utcTime: string;
  location: LocationType;
  setDate?: (date: Date) => void;
  handleClick?: () => void;
  setIsLive?: (isLive: boolean) => void;
}) => {
  // Unpack the date.
  const localDate = utcStringToLocalDate(utcTime, location.time_zone);
  const valueDate = new Date(localDate.valueOf()); //1000 = ms/s
  const formattedDate = dateFormat(localDate, 'mediumTime');
  // Create the query parameters for the link.
  const params = {
    dt:
      dateFormat(valueDate, 'yyyy-mm-dd') +
      'T' +
      dateFormat(valueDate, 'HH:MM'),
    lid: location.id.toString(),
  };
  // Hard coded for ?
  if (location.id === 24) {
    params['cams'] = [0, 2];
  }
  const urlSearchParams = new URLSearchParams(params);
  const url = '/applications?' + urlSearchParams.toString();
  return (
    <Link
      className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
      to={url}
      onClick={() => {
        if (setIsLive) setIsLive(false);
        if (setDate) setDate(valueDate);
        if (handleClick) handleClick();
      }}
    >
      {formattedDate}
    </Link>
  );
};

export default TimeArrivalLink;
