import React from 'react';
import { formatSeconds } from '../../helpers/formatting';
import DetectionOrderRow from './DetectionOrderRow';



export default function DetectionOrderTable({
    columns,
    orderData,
    detectionData,
    locationPrefs,
    matchedData,
    location,
    sortColumn,
    setSortColumn,
    sortDirection,
    setSortDirection
}){


    /**
     * Handles setting the sort column and direction, if the same column
     * is clicken then we swap the sort direction, else if new column we set it
     * and we set the default sort direction
     * @param {String} col column name when clicked
     */
    const toggleSort = (col) => {
        if (col === sortColumn){
            setSortDirection(sortDirection * -1);
        }
        else{
            // new column
            setSortColumn(col);
            setSortDirection(1);
        }
    }


    // Sort the matched data based on the sort key
    matchedData.sort((a,b) => {
        let key = columns[sortColumn].key;
        if (sortColumn != 'Service Time'){
            // standrad
            a = a[key];
            b = b[key];
        }
        else{
            // Need to dig into 
            if (a.detection.length > 0){
                a = a.detection[0].time_spent;
            }
            else{
                a = 0
            }
            if (b.detection.length > 0){
                b = b.detection[0].time_spent;
            }
            else{
                b = 0;
            }
        }
        
        let dir = a > b ? 1 : -1;
        // console.log(dir);
        return dir*sortDirection;
    })

    return (
			<div className='max-h-96 overflow-y-auto'>
				<table className='table-fixed w-full divide-y divide-gray-200 min-w-98'>
					<thead  className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
						<tr>
							{Object.keys(columns).map(col => {
								let type = columns[col].type
								return (
									<th className={`p-2 w-28 sticky top-0 bg-gray-50 cursor-pointer ${sortColumn == col ? 'text-blue-700' : ''}`} 
                                        key={`header-${col}`} 
                                        onClick={() => toggleSort(col)}>
										<div className={`font-semibold text-left ${['dollar', 'time'].includes(type) ? 'text-right' : ''}`}>
                                            {col} 
                                            {
                                               col === sortColumn ? (<span>
                                                    {sortDirection==1 ? <>&#9650; </> : <>&#9660;</>}
                                                </span> ) : null
                                            }
                                        </div>
									</th>
								)
							})}
						</tr>
					</thead>
					<tbody className='divide-y divide-gray-200 text-xs'>
						{
							matchedData.map(x => {
                                // Check if has detection time
                                let det_time = '--';
                                if (x['detection'].length > 0){
                                    det_time = formatSeconds(x['detection'][0].time_spent);
                                }
                                return <DetectionOrderRow key={x.id} data={x} columns={columns} location={location} prefs={locationPrefs}/>
							})
						}
					</tbody>
				</table>
			</div>
	)
}