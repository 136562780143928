import React, { useEffect, useState } from 'react';
import { useDataContext } from '../../contexts/DataContext';
import TableLoader from '../dashboard/TableLoader';
import { formatSeconds } from './dashboardHelpers';
import app from '../../firebase';

export default function RegionTable({
  customerLocationData,
  franchise,
  filteredLocations,
  date,
  isDriveThru,
}) {
  const [tableData, setTableData] = useState();
  const [locationPreferences, setLocationPreferences] = useState();
  const [allFirestore, setAllFirestore] = useState();

  const { getCacheData, setCache, routes } = useDataContext();

  useEffect(() => {
    const abortController = new AbortController();
    if ((customerLocationData != undefined) & (franchise != undefined)) {
      // Get Data
      getTableData({ controller: abortController });
    }

    // Set interval refresh
    const id = setInterval(refreshData, 5 * 60 * 1000);
    return () => {
      clearInterval(id);
      abortController.abort();
    };
  }, [franchise, customerLocationData, date, isDriveThru]);

  const getCacheKey = () => {
    let locs = customerLocationData
      .filter((x) => x.franchise.id == franchise.id)
      .map((x) => x.id);
    let dateString = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
    let key = `regiontable-${locs.join('-')}-${dateString}-${isDriveThru}`;
    return key;
  };

  const getTableData = async ({
    override = false,
    controller = new AbortController(),
  }) => {
    // Check cache or override
    let key = getCacheKey();
    let cacheData = getCacheData(key);
    if ((cacheData != undefined) & !override) {
      // console.log("Got table data from cache");
      // console.log(cacheData);
      setTableData(cacheData.tableData);
      setLocationPreferences(cacheData.prefs);
      setAllFirestore(cacheData.firestore);
      return;
    }

    // Get pref data no matter what
    setLocationPreferences(undefined);
    setAllFirestore(undefined);
    let locations = customerLocationData
      .filter((x) => x.franchise_id == franchise.id)
      .map((x) => x.id);
    let prefReturn = await getPrefData(locations);
    let prefData = prefReturn['prefData'];
    let allFirestoreData = prefReturn['allFirestore'];
    // console.log("Got pref data");
    setLocationPreferences(prefData);
    setAllFirestore(allFirestoreData);

    // Clear out table table
    setTableData(undefined);

    // Fetch
    // console.log("Getting table data with querry");
    let sendDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;
    let params = {
      locations: locations,
      date: sendDate,
      is_drivethru: isDriveThru,
    };
    // console.log(params);
    // console.log(JSON.stringify(params));
    let url = routes['dashboardRegionData'];

    // console.log("Making region request");
    // console.log(controller);
    // fetch(url, {
    // 	signal: controller.signal,
    // 	method: "POST",
    // 	headers: {
    // 		"Content-Type": "application/JSON"
    // 	},
    // 	body: JSON.stringify(params)
    // })
    // .then(res => res.json())
    // .then(data => {
    // 	// data = augmentLocations(data);
    // 	setCache(getCacheKey(), data);
    // 	setTableData(data);
    // })
    // .catch(err => console.warn(err));

    // async table data fetch
    let resp = await fetch(url, {
      signal: controller.signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/JSON',
      },
      body: JSON.stringify(params),
    }).catch((err) => console.warn(err));
    if (resp == undefined) {
      console.log('Failure, so bailing from get data');
      return;
    }
    let data = await resp.json();

    // Make cache object
    // console.log("About to set cache");
    // console.log(allFirestoreData);
    let cacheObj = {
      prefs: prefData,
      firestore: allFirestoreData,
      tableData: data,
    };
    setCache(getCacheKey(), cacheObj);
    setTableData(data);
  };

  const getPrefData = async (locations) => {
    const db = app.firestore();
    const prefData = {};
    const allFirestore = {};

    for (const lid of locations) {
      let doc = await db.collection('location').doc(lid.toString()).get();
      if (doc.exists) {
        let data = await doc.data();
        allFirestore[lid] = data;
        prefData[lid] =
          data[isDriveThru ? 'drive_thru_regions2' : 'lobby_regions2'];
      }
    }

    // return prefData;
    return {
      prefData: prefData,
      allFirestore: allFirestore,
    };
  };

  const refreshData = () => {
    if ((customerLocationData != undefined) & (franchise != undefined)) {
      getTableData({ override: true });
    }
  };

  const renderTable = () => {
    // loader while waiting
    if (
      tableData == undefined ||
      locationPreferences == undefined ||
      allFirestore === undefined ||
      filteredLocations == undefined
    ) {
      return (
        <TableLoader columns={['Location', 'Order Board', 'Pickup Window']} />
      );
    }

    // Filter based on filterdLocations
    let names = filteredLocations.map((x) => x.display_name);
    let filteredTableData = tableData.filter((x) =>
      names.includes(x.display_name)
    );

    // no data table
    if (filteredTableData.length == 0) {
      return <div className="w-full text-center text-gray-400">No Data</div>;
    }

    // Decide on columns
    let columns = ['Location'];
    let columnKeys = [];
    if (isDriveThru) {
      // Check to see if preference on regions
      // For each location
      Object.keys(locationPreferences).forEach((loc) => {
        // Check if it has preferences
        // console.log(loc);
        if (
          Object.keys(allFirestore[loc]).includes('drive_thru_region_displays')
        ) {
          // add them
          let keys = allFirestore[loc]['drive_thru_region_displays'];
          let nameMapping = locationPreferences[loc];
          columnKeys = columnKeys.concat(keys);
          keys.forEach((key) => {
            let match = nameMapping.find((m) => m.key === key);
            if (match === undefined) {
              return;
            }
            columns.push(match['display_name']);
          });
        } else {
          // add defaults
          columns = columns.concat(['Order Board', 'Pickup Window']);
          columnKeys = columnKeys.concat(['order', 'leave']);
        }
      });
      // Remove duplicates
      columns = [...new Set(columns)];
      columnKeys = [...new Set(columnKeys)];
    } else {
      // Lobby
      let regions = Object.values(locationPreferences).reduce((agg, cur) => {
        if (agg.length > cur.length) {
          return agg;
        } else {
          return cur;
        }
      }, []);
      // console.log(regions);
      columns = columns.concat(regions.map((x) => x.display_name));
      columnKeys = columnKeys.concat(regions.map((x) => x.key));
    }
    // console.log(columns);
    // console.log(columnKeys);

    // Gather data
    // console.log(filteredTableData);

    // Render
    return (
      <table className="table-auto w-full divide-y divide-gray-200">
        <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
          <tr>
            {
              // ['Location', 'Order Board', 'Pickup Window'].map(col => {
              columns.map((col) => {
                return (
                  <th className="p-2 sticky top-0 bg-gray-50" key={col}>
                    <div className="text-left">{col}</div>
                  </th>
                );
              })
            }
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 text-xs">
          {filteredTableData.map((data) => {
            return (
              <tr
                className="hover:bg-gray-100 cursor-defualt my-4"
                key={`regTableRow-${data.display_name}`}
              >
                <td className="p-2">{data.display_name}</td>
                {/* {
                                        columnKeys.map(col => <td className='p-2'>{formatSeconds(data[col], false)}</td>)
                                    } */}
                {columns.slice(1).map((colName) => {
                  let lid = data.id;
                  // console.log(colName);
                  // get the column id from firestore that matches the column name
                  let prefs = locationPreferences[lid];
                  if (prefs == undefined) {
                    return (
                      <td className="p-2" key={`${data.id}-${colName}`}></td>
                    );
                  }
                  // console.log(prefs);
                  let match = prefs.find((x) => x.display_name == colName);
                  let val;
                  if (match === undefined) {
                    val = NaN;
                  } else {
                    let key = match.key;
                    val = data[key];
                  }
                  // let key = parseInt(prefs.find(x => x.display_name == colName).key);
                  // console.log(prefs.find(x => x.display_name == colName));
                  return (
                    <td className="p-2" key={`${data.id}-${colName}`}>
                      {formatSeconds(val, false)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const swap = (obj) => {
    var ret = {};
    for (var key in obj) {
      ret[obj[key]] = key;
    }
    return ret;
  };

  return (
    <div className="flex flex-col col-span-full bg-white shadow-lg rounded-lg border border-gray-200 mb-8">
      <header className="px-5 py-4 border-b border-gray-100">
        <div>
          <h2 className="font-semibold text-gray-800 inline">Stations</h2>

          <div className="flex float-right">
            <button
              className="button bg-transparent hover:bg-blue-400
								text-blue-400 hover:text-white rounded-full
								border border-blue-400 py-1 px-2 text-xs"
              onClick={() => refreshData()}
            >
              Refresh
            </button>
          </div>
        </div>
      </header>
      <div className="p-3">
        {/* Table */}
        <div className="overflow-x-auto max-h-96">{renderTable()}</div>
      </div>
    </div>
  );
}
