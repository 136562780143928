import React from 'react';
import MultiSelectDropdownMenu from '../actions/MultiSelectDropdown';

export default function MultiLocationSelector({allLocations, franchise, selectedLocations, setSelectedLocations}){

	/**
	 * Helper to toggle locations in selectedLocations based on the multiselect
	 * @param {sting} loc 
	 */
	const toggleHelper = (loc) => {
		// If location selected remove it
		if (selectedLocations.map(x => x.display_name).includes(loc)){
			setSelectedLocations(
				selectedLocations.filter(x => x.display_name != loc)
			)
		}
		// If not selected, add it
		else{
			// Get the match
			let locData = allLocations.filter(x => x.display_name == loc)[0];

			// Add it to the list
			setSelectedLocations((selectedLocations) => [
				...selectedLocations,
				locData,
			]);
		}
	}

	if ((allLocations == undefined) || (allLocations.length == 0) 
				|| (franchise == undefined) || (selectedLocations == undefined)){
		return <div className="animate-pulse bg-blue-400 rounded w-56 h-2"></div>;
	}

	return (
		<div>
			<span className='text-xs text-gray-600'>Locations</span><br/>
			<MultiSelectDropdownMenu
				options={allLocations
					.filter(x => x.franchise.id == franchise.id)
					.map(x => x.display_name)}
				toggleOption={toggleHelper}
				selected={selectedLocations.map(x => x.display_name)}
				label="Locations"
				/>
		</div>
	)
}