import React, { useState } from "react";
import FranchiseSelector from "./FranchiseSelector";
import DriveThruToggle from "../dashboard2/DriveThruToggle";
import MultiLocationSelector from "./MultiLocationSelector";
import PeriodPicker from "./PeriodPicker";
import ModalBasic from "../actions/ModalBasic";
import GraphSelector from "./GraphSelector";

export default function PageOptions({
	franchise,
	allFranchises,
	setFranchise,
	allLocations,
	selectedLocations,
	setSelectedLocations,
	period1,
	period2,
	setPeriod1,
	setPeriod2,
	dtToggle,
	setDtToggle,
	periodToggle,
	setPeriodToggle,
	displayWidgets,
	setDisplayWidgets,
	componentInfo
}) {
	// const [graphModalOpen, setGraphModalOpen] = useState(false);

	const renderDTToggle = () => {
		if (selectedLocations === undefined){return null}
		let haveDT = selectedLocations.map(x => x.has_drive_through).reduce((acc, cur) => {
			return acc || cur
		}, false);
		if (haveDT){
			return (
				<div>
					<span className="text-xs text-gray-600">Drive-Thru</span> <br />
					<DriveThruToggle isDriveThru={dtToggle} setIsDriveThru={setDtToggle} />
				</div>
			)
		}
		else{
			return null;
		}
	}

	return (
		// <div className='sm:flex sm:justify-between sm:items-center mb-8 p-3 bg-white border-gray-100 rounded-lg shadow-sm'>
		<div className="flex flex-wrap gap-4 mb-8 p-3 bg-white border-gray-100 rounded-lg shadow-lg">
			<FranchiseSelector
				franchises={allFranchises}
				franchise={franchise}
				setFranchise={setFranchise}
			/>

			<MultiLocationSelector
				allLocations={allLocations}
				franchise={franchise}
				selectedLocations={selectedLocations}
				setSelectedLocations={setSelectedLocations}
			/>

			<PeriodPicker name="Period 1" dates={period1} setDates={setPeriod1} />

			<PeriodPicker name="Period 2" dates={period2} setDates={setPeriod2} />

			{renderDTToggle()}
			{/* {
				selectedLocations.map(x => x.has_drive_through).reduce((acc, cur) => {
					return acc || cur
				}, false) ? (
					<div>
						<span className="text-xs text-gray-600">Drive-Thru</span> <br />
						<DriveThruToggle isDriveThru={dtToggle} setIsDriveThru={setDtToggle} />
					</div>
				) : null
			} */}

			<div className="">
				<span className="text-xs text-gray-600">Display</span> <br />
				<div className="flex flex-wrap -space-x-px">
					<button
						className={`btn focus:outline-none border-gray-200 hover:bg-gray-50 ${
							periodToggle == "period1"
								? "text-blue-500 bg-gray-50"
								: "text-gray-600 bg-white"
						} rounded-none rounded-l-sm`}
						onClick={() => setPeriodToggle("period1")}>
						Period 1
					</button>
					<button
						className={`btn focus:outline-none border-gray-200 hover:bg-gray-50  ${
							periodToggle == "period2"
								? "text-blue-500 bg-gray-50"
								: "text-gray-600 bg-white"
						} rounded-none first:rounded-l last:rounded-r`}
						onClick={() => setPeriodToggle("period2")}>
						Period 2
					</button>
					<button
						className={`btn focus:outline-none border-gray-200 hover:bg-gray-50  ${
							periodToggle == "both"
								? "text-blue-500 bg-gray-50"
								: "text-gray-600 bg-white"
						} rounded-none rounded-r-sm`}
						onClick={() => setPeriodToggle("both")}>
						Both
					</button>
				</div>
			</div>

			<GraphSelector 
				displayWidgets={displayWidgets}
				setDisplayWidgets={setDisplayWidgets}
				componentInfo={componentInfo}/>

			
		</div> 
	);
}
