import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

export default function CountAnimation({ value }) {
  const ref = useRef();
  const prevValue = useRef();

  useEffect(() => {
    render();
  }, []);

  const render = () => {
    let div = d3.select(ref.current);
    div.html('$0');
    div
      .transition()
      .duration(1000)
      .ease(d3.easePoly)
      .tween('html', function () {
        const i = d3.interpolate(0, value);
        let selection = d3.select(this);
        return function (t) {
          selection.html(
            `$${i(t).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          );
        };
      });
    prevValue.current = value;
  };

  return <div ref={ref}></div>;
}
