/**
 * Construct the CSV from the data and open it.
 */
export function constructCsv(
  data: any[][],
  columns: { label: string; type?: string }[]
) {
  let csvContent = 'data:text/csv;charset=utf-8,';

  // Add the column labels.
  const columnLabels = columns.map((column) => column.label);
  csvContent += columnLabels.join(',') + '\n';

  // Add the data.
  data.forEach(function (rowArray) {
    let row = rowArray.join(',');
    csvContent += row + '\n';
  });

  csvContent = csvContent.replaceAll('#', '');

  // Open the CSV.
  let encodedUri = encodeURI(csvContent);
  var link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', 'my_data.csv');
  document.body.appendChild(link); // Required for FF
  link.click();
}
