import React, {useRef, useEffect, useState} from 'react';
import * as d3 from 'd3';
import { formatTime } from './analyticsHelpers';

export default function DayPartGraph({data, colors, showTimes}){

    // Focus location (location id or undefined if show all)
    // const [location, setLocation] = useState();
    // const [focusRegion, setFocusRegion] = useState("Order Board");
    const [selections, setSelections] = useState([undefined, undefined]); // location, focusRegion

    const target = useRef();

    const margin = {
        top: 80,
        left: 60,
        right: 40,
        bottom: 100
    }

    const HEIGHT = 500
    
    useEffect(() => {
        // console.log(target.current.clientWidth);
        if (target.current &&  d3.select(target.current).select("#mainG").empty()){
            setupSVG();
        }

    }, []);

    useEffect(() => {
        plot();

        // Need to re-register listener with dependency updates
        // Register a resize listener
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    // }, [data, location, focusRegion, selections])
}, [data, selections, showTimes])

    const setupSVG = () => {
        // To be called ONCE on start to setup the SVG
        console.log("setting up svg");
        const svg = d3.select(target.current)
            .append('svg')
            .attr('width', target.current.clientWidth)
            .attr('height', HEIGHT)
            .attr('id', 'mainSVG')
            .on('dblclick', () => {
                // setLocation(); 
                // setFocusRegion();
                setSelections([undefined, undefined])
            })

        const g = svg.append('g')
            .attr('transform', `translate(${margin.left}, ${margin.top})`)
            .attr('id', 'mainG')

        const xScale = d3.scaleLinear()
            .domain([])
            .range([0, target.current.clientWidth - margin.left - margin.right]);

        const yScale = d3.scaleLinear()
            .domain([])
            .range([0, HEIGHT - margin.top - margin.bottom]);

        g.append('g')
            .attr('id', 'xAxis')
            .attr('transform', `translate(${0}, ${HEIGHT - margin.top - margin.bottom})`)
            .call(d3.axisBottom(xScale));

        g.append('g')
            .attr('id', 'yAxis')
            .attr('transform', `translate(0, 0)`)
            .call(d3.axisLeft(yScale));

        // Axis Labels
        svg.append('text')
            .attr('id', 'xLabel')
            .style('font-size', 15)
            .style('text-anchor', 'middle')
            .attr('transform', `translate(${target.current.clientWidth/2}, ${HEIGHT - .2*margin.bottom})`)

        // Legend
        svg.append('g')
            .attr('id', 'legend')
            .attr('transform', `translate(${margin.left}, ${.25 * margin.top})`);

        // Add tooltip
        let tooltip = d3.select(target.current)
           .append("div")
           .attr('id', 'tooltip')
           .style("position", "absolute")
           .style("visibility", "hidden")
           .style("background-color", "white")
           .style("border", "solid")
           .style("border-width", "1px")
           .style("border-radius", "5px")
           .style("padding", "10px")
           .style('opacity', .75)
           .style('font-size', '10px');

    }

    const handleResize = () => {
        // Resize SVG
        const svg = d3.select(target.current).select("#mainSVG")
            .attr('width', target.current.clientWidth);

        // re-plot--which resizes axes, labels, legend, and plot
        plot();

    }

    const legend = (colorMap) => {
        // Get distinct locations
        // let locs = data.reduce((agg, cur) => {
        //     // console.log(cur);
        //     if (!agg.map(x => x.id).includes(cur.location_id)){
        //         agg.push({id:cur.location_id, name:cur.display_name});
        //     }
        //     return agg
        // }, []);

        let regions = data.ordered_day_parts
        let s = d3.scaleLinear()
            .domain([0, d3.min([5, regions.length])])
            .range([0, target.current.clientWidth - margin.left - margin.right]);

        // const g = d3.select(target.current).select("#legend");
        const g = d3.select(target.current).select("#legend");
        let circles = g.selectAll(".legendCircle")
            .data(regions)
            .join(
                enter => enter.append("circle")
                    // .attr('class', 'legendCircle')
                    .classed("legendCircle", true)
                    .classed("legendItem", true)
                    .attr('cx', (d,i) => s(i % 5))
                    .attr('cy', (d,i) => i < 5 ? 5 : 25)
                    .attr('r', 0)
                    .style('fill', d => colorMap[d])
                    .transition()
                    .delay((d,i) => 500 + (i * 300))
                    .attr('r', 7)
                ,
                update => update.transition()
                    .attr('cx', (d,i) => s(i % 5))
                    .attr('cy', (d, i) => i < 5 ? 5 : 25)
                    .attr('r', 7)
                ,
                exit => exit.remove()
            );

        let labels = g.selectAll(".legendLabel")
            .data(regions)
            .join(
                enter => enter.append('text')
                    // .attr('class', 'legendLabel')
                    .classed('legendLabel', true)
                    .classed("legendItem", true)
                    .attr('transform', (d, i) => `translate(${s(i % 5) + 10}, ${i < 5 ? 7 : 27})`)
                    .style('font-size', 10)
                    .transition()
                    .delay((d,i) => 750 + (i * 300))
                    .text(d => d)
                    .attr('fill', d => colorMap[d])
                    .style('cursor', 'default')
                ,
                update => update.transition()
                    .attr('transform', (d, i) => `translate(${s(i % 5) + 10}, ${i < 5 ? 7 : 27})`)
                ,  
                exit => exit.remove()
            );

        // attach listeners
        let items = g.selectAll(".legendItem");
        items
            .style('cursor', 'pointer')
            .on('click', d => {
                let dat = d3.select(d.target).data()[0];
                setSelections([undefined, dat]);
            });
    }


    const plotLines = (datum, xScale, yScale, colorMap) => {
        const g = d3.select(target.current).select("#mainG");

        // Group the data
        datum  = d3.group(datum, d => d.day_part);
        // console.log(datum);

        let lines = g.selectAll(".chartLine")
            .data(datum);

        let newLines = lines.join(
            enter => {
                // console.log(enter);
                if (enter.empty()){return enter} // with weird return need this
                let path = enter.append('path')
                    .classed('chartLine', true)
                    .attr('fill', 'none')
                    .attr('stroke', (d, i) => colorMap[d[0]])
                    .attr('stroke-opacity', 1)
                    .attr('stroke-width', 1.5)
                    .attr('d', d => {
                        return d3.line()
                            .x(d => xScale(new Date(d.date)))
                            .y(d => yScale(showTimes ? d.rolling_avg_time : d.rolling_customer_count))
                            (d[1])
                    });
                

                return path.attr("stroke-dasharray", function(d){
                        let length = d3.select(this).node().getTotalLength();
                        let dashArray = length + " " + length;
                        return dashArray;
                    })
                    .attr('stroke-dashoffset', function(d){return d3.select(this).node().getTotalLength()})
                    .transition()
                        .duration(1000)
                        .delay((d, i) => i * 150)
                        .attr('stroke-dashoffset', 0)
                    .on('end', )
            },
            update => update.transition()
                .duration(800)
                .delay((d,i) => i * 200)
                .attr('d', d => {
                    return d3.line()
                        .x(d => xScale(new Date(d.date)))
                        .y(d => yScale(showTimes ? d.rolling_avg_time : d.rolling_customer_count))
                        (d[1])
            }),
            // exit => exit.remove()
            exit => exit.transition()
                .duration(500)
                .attr('stroke-opacity', 0)
                .remove(),
        );

        return newLines;
    }

    const plotBars = (barData, xScale, yScale, colorMap) => {
        const g = d3.select(target.current).select("#mainG");
        // console.log(barData);
        // Define inner scale
        let innerScale;
        if (selections[0] != undefined){
            // Case when we want lines to go away, need to adjust scale
            // So we don't throw an error calling xScale.bandwidth()
            innerScale = d3.scaleBand();
        } else {
            // We need the ordered regions! fuck
            // console.log(data);
            innerScale = d3.scaleBand()
                .domain(data['ordered_day_parts'])
                .range([0, xScale.bandwidth()])
        }

        // if (focusRegion != undefined){
        if (selections[1] != undefined){
            // we don't really want an inner scale at all if possible
            innerScale = d3.scaleBand()
                .domain(data['ordered_day_parts'])
                .range([0, 0])
        }

        let bars = g.selectAll(".bar")
            .data(barData, (d,i) => `${d.location_id}_${d.day_part}`);

        bars = bars.join(
            enter => enter.append('rect')
                .classed("bar", true)
                .attr('x', d => xScale(d.display_name) + innerScale(d.day_part))
                .attr('width', selections[1] == undefined ? innerScale.bandwidth() : xScale.bandwidth())
                .attr('y', yScale(0))
                .attr('height', 0)
                .style('fill', d => colorMap[d.day_part])
                .style('cursor', 'pointer')
                .transition()
                .delay((d,i) => i * 25)
                .attr('y', d => yScale(showTimes ? d.rolling_avg_time : d.rolling_customer_count))
                .attr('height', d => yScale(0) - yScale(showTimes ? d.rolling_avg_time : d.rolling_customer_count))
                .attr('width', selections[1] == undefined ? innerScale.bandwidth() : xScale.bandwidth())
            ,
            update => update.transition()
                .attr('x', d => xScale(d.display_name) + innerScale(d.day_part))
                .attr('y', d => yScale(showTimes ? d.rolling_avg_time : d.rolling_customer_count))
                .attr('height', d => yScale(0) - yScale(showTimes ? d.rolling_avg_time : d.rolling_customer_count))
                .attr('width', selections[1] == undefined ? innerScale.bandwidth() : xScale.bandwidth())
                
            ,
            exit => exit.transition()
                .attr('y', yScale(0))
                .attr('height', 0)
                .remove(),
        );

        bars.on('click', d => {
            let dat = d3.select(d.target).data()[0].day_part;
            setSelections([undefined, dat])
        });

        bars.on('pointerenter', d => {
            let datum = d3.select(d.target).data()[0]
            let textVal = showTimes ? formatTime(datum.rolling_avg_time) : datum.rolling_customer_count

            // Add a bar label
            g.append('text')
                .attr('class', 'barLabel')
                .attr('x', xScale(datum.display_name) + innerScale(datum.day_part))
                .attr('y', yScale(showTimes ? datum.rolling_avg_time : datum.rolling_customer_count))
                .attr('font-size', '10px')
                .attr('dy', -5)
                .text(textVal);
        }).on('pointerleave', d => {
            // remove all bar labels
            g.selectAll(".barLabel").remove();
        });
    }

    const updateAxes = (xScale, yScale) => {
        const g = d3.select(target.current).select("#mainG");

        // Update axes objects
        let xAxis = g.select("#xAxis")
            .attr('transform', `translate(${0}, ${HEIGHT - margin.top - margin.bottom})`)
            .transition()
            .call(d3.axisBottom(xScale));

        let yAxis = g.select("#yAxis")
            .transition()
            .call(d3.axisLeft()
                .scale(yScale)
                .tickFormat((d, i) => showTimes ? formatTime(d) : d)
            );

        // Fix ticks
        if (selections[0] == undefined){
            xAxis.selectAll("text")
                .style('text-anchor', 'end')
                .attr('transform', 'rotate(-60)');
        }

        // Add Grid Lines
        g.selectAll(".yGridLine")
            .data(yScale.ticks())
            .join(
                enter => enter.append('line')
                    .attr('class', 'yGridLine')
                    .attr('x1', 0)
                    .attr('x2', target.current.clientWidth - margin.left - margin.right)
                    .attr('y1', d => yScale(d))
                    .attr('y2', d => yScale(d))
                    .style('stroke', 'grey')
                    .style('stroke-opacity', .2)
                ,
                update => update.transition()
                    .attr('x1', 0)
                    .attr('x2', target.current.clientWidth - margin.left - margin.right)
                    .attr('y1', d => yScale(d))
                    .attr('y2', d => yScale(d)) 
                ,
                exit => exit.remove()
            )

        // Axis Labels
        g.select("#y1Label")
            .attr('transform', `translate(${-margin.left + 7}, ${HEIGHT/2}) rotate(270)`)
            .text("Avg. Time at Station")
        
        d3.select(target.current).select('#xLabel')
            .text(() => {
                if (selections[0] !== undefined){
                    // get location name
                    let loc = data.period.find(x => x.location_id == selections[0]);
                    return loc.display_name;
                } else {
                    // no location
                    return ''
                }
            });

    }

    /**
     * Gets the X and Y scales based on the location selected (or undefined)
     */
    const getScales = () => {
        // console.log(data);
        // return [null, null];
        let dat, xKey, yKey, xScale, yScale;
        if (selections[0] == undefined){
            dat = data.period;
            xKey = 'display_name';
            // yKey = 'rolling_avg_time';
            yKey = showTimes ? 'rolling_avg_time' : 'rolling_customer_count';
            let unique = Array.from((new Set(dat.map(x => x[xKey]))).values())
            // unique.sort((a,b) => a - b);
            xScale = d3.scaleBand()
                .domain(unique)
                .padding(.25);

            yScale = d3.scaleLinear()
                .domain([0, d3.max(dat.map(y => y[yKey]))]);

        } else {
            // We have a location
            dat = data.daily.filter(x => x.location_id == selections[0]);
            xKey = 'date';
            // yKey = 'rolling_avg_time';
            yKey = showTimes ? 'rolling_avg_time' : 'rolling_customer_count';
            xScale = d3.scaleTime()
                .domain(d3.extent(dat.map(x => new Date(x[xKey]))));
            yScale = d3.scaleLinear()
                .domain([0, d3.max(dat.map(y => y[yKey]))])
        }


        xScale.range([0, target.current.clientWidth - margin.right - margin.left]);
        yScale.range([HEIGHT - margin.top - margin.bottom, 0]);

        return [xScale, yScale]
    }

    const tooltip = (xScale, yScale, lineData, colorMap) => {
        // console.log("Setting tooltip")
        const svg = d3.select(target.current).select("#mainSVG");
        const g = svg.select("#mainG");
        const tooltip = d3.select(target.current).select("#tooltip");

        // always make it hidden on transitions
        tooltip.style('visibility', 'hidden');
        g.selectAll(".tooltipPoint").remove();
        g.selectAll(".tooltipLine").remove();

        let allDates = lineData.map(x => new Date(x.date));

        /**
         * Helper to get the offset from top of page to position tooltip
         */
        const getTopOffset = (el) => {
            let offsets = [];
            while (el != undefined){
                if (!isNaN(el.offsetTop)){
                    offsets.push(el.offsetTop);
                }
                el = el.parentElement;
            }
            return d3.max(offsets)
        }

        const moveTip = (e) => {
            let offset = getTopOffset(d3.select(target.current).select("#mainSVG").node());

            return tooltip.style('visibility', 'visible')
                .style('top', (d3.pointer(e, svg.node())[1] + offset - 10) + "px")
                .style('left', (d3.pointer(e, svg.node())[0] + 75) + "px");
        }

        const setTipData = (e) => {
            // get x position
            let x = d3.pointer(e, g.node())[0];

            // get closest date
            let date = xScale.invert(x);
            let closestDateIndex = d3.bisectCenter(allDates, date);
            let closestDate = allDates[closestDateIndex]

            // Let matching data 
            let matchingData = lineData.filter(x => x.date == closestDate.valueOf());
            // matchingData.sort((a,b) => b.rolling_avg_time - a.rolling_avg_time);
            matchingData.sort((a,b) => b[showTimes ? 'rolling_avg_time' : 'rolling_customer_count'] - a[showTimes ? 'rolling_avg_time' : 'rolling_customer_count'] )

            // Set the Tooltip HTML
            let html = '';
            matchingData.forEach(d => {
                let val = showTimes ? formatTime(d.rolling_avg_time) : d.rolling_customer_count;
                html = html +  
                `<p style="color:${colorMap[d.day_part]}">
                    ${d.day_part}: ${val}
                </p>`;
            });
            
            // Add Tooltip vertical line
            g.selectAll(".tooltipLine")
                .data([closestDate])
                .join("line")
                .attr('class', 'tooltipLine')
                .attr('x1', xScale(new Date(closestDate)))
                .attr('x2', xScale(new Date(closestDate)))
                .attr('y1', yScale.range()[0])
                .attr('y2', 0)
                .style('stroke', 'grey')
                .style('stroke-width', 1)
                .style('stroke-opacity', .5)

            // Add Points
            g.selectAll(".tooltipPoint")
                .data(matchingData)
                .join('circle')
                .attr('class', 'tooltipPoint')
                .attr('cx', d => xScale(new Date(d.date)))
                .attr('cy', d => yScale(showTimes ? d.rolling_avg_time : d.rolling_customer_count))
                .attr('r', 4)
                .attr('fill', d => colorMap[d.day_part]);


            tooltip.html(html);
        }

        // Attach handlers
        svg.on('pointerenter, pointermove', e => {
            if (selections[0] != undefined){
                moveTip(e);
                setTipData(e);
            }
        }).on('pointerleave', e => {
            tooltip.style('visibility', 'hidden');
            g.selectAll(".tooltipPoint").remove();
            g.selectAll(".tooltipLine").remove();
        })
    }

    const plot = () => {
        // console.log("Plotting");
        if (data.daily.length == 0 || data.period.length == 0) {
			d3.select(target.current)
				.select("#mainG")
				.selectAll(".noDataText")
				.data([1])
				.join("text")
				.attr("class", "noDataText")
				.style("text-anchor", "middle")
				.attr(
					"x",
					(target.current.clientWidth - margin.left - margin.right) / 2
				)
				.attr("y", (HEIGHT - margin.top - margin.bottom) / 2)
				.text("No data for selected parameters");
			return;
		} else {
			d3.select(target.current)
				.select("#mainG")
				.selectAll(".noDataText")
				.remove();
		}

        let [xScale, yScale] = getScales();
        updateAxes(xScale, yScale);

        // setup data
        let barData;
        let lineData;
        /**
         * There are 3 data cases:
         * 1) location is undefined AND focusRegion is undefined
         * 2) location is undefined BUT focusRegion has a value
         * 3) location has a value
         */
        if ( (selections[0] == undefined) & (selections[1] == undefined) ){
            barData = data.period;
            lineData = [];
        } else if ( (selections[0] == undefined) && (selections[1] != undefined) ){
            barData = data.period.filter(x => x.day_part == selections[1]);
            lineData = [];
        } else {
            barData = [];
            lineData = data.daily.filter(x => x.location_id == selections[0]);
        }

        let colorMap = data.ordered_day_parts.reduce((agg, cur) => {
            agg[cur] = d3.schemeCategory10[Object.keys(agg).length];
            return agg
        }, {});

        plotBars(barData, xScale, yScale, colorMap);
        plotLines(lineData, xScale, yScale, colorMap);

        // Add binders to axis (it should exist at this point)
        if (selections[0] == undefined){
            d3.select(target.current).select("#mainG")
                .select('#xAxis')
                .selectAll("text")
                .style('cursor', 'pointer')
                .on('click', d => {
                    let loc_name = d3.select(d.target).data()[0];
                    let lid = data.period.find(x => x.display_name == loc_name).location_id
                    // The order in which we set the states matter!!! ugh
                    // setFocusRegion();
                    // setLocation(lid);
                    setSelections([lid, undefined])
                });
        }

        // Make / Update Legend
        legend(colorMap)

        // Tooltip
        tooltip(xScale, yScale, lineData, colorMap);

    }

    return (
        <>
            <div ref={target}></div>
        </>
    )
}