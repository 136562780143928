/**
 * Helper functions to get and organize data for Orders
 */
 import { getTimezoneOffset } from 'date-fns-tz';

const getDaysOrders = async ({queryKey}) => {
    const [_key, date, isDriveThru, location, url] = queryKey;
    const locationId = location.id; 

    // USE date sting and re-parse

    // Deal with date, converting to thing...
    date.setHours(0); date.setMinutes(0); date.setSeconds(0);
    let end_date = new Date(date);
    end_date.setDate(end_date.getDate() + 1);
    let start_time = dateToUTC(date, location.time_zone); // HARD CODED TZ FOR NOW
    let end_time = dateToUTC(end_date, location.time_zone);


    const params = {
        location_id: locationId,
        start_time: start_time.toISOString(), 
        end_time: end_time.toISOString()
    }

    const response = await fetch(url, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(params)
    });
    if (!response.ok){
        throw new Error("Orders Table requst failed");
    }
    let data = response.json();
    // console.log(data);
    return data;
}

const getFullDetectionData = async({queryKey}) => {
    const [_key, date, isDriveThru, location, url] = queryKey;
    console.log(date);
    // uise date string and re-parse
    const splitDate = date.split("/");

    // Get API Detection Data
    let sendDate = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;

    let params = {
        location_id: location.id,
        start_date: sendDate,
        end_date: sendDate,
    };

    const response = await fetch(url, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(params)
    });
    if (!response.ok){
        throw new Error("Full Detection Data request failed");
    }

    let data = await response.json();

    // React query requires a promise be returned, not just data
    let returnData = new Promise((resolve, reject) => {
        let detections = data.detection.filter(x => x.model_id != -7);
        let regions = data.regions;
        let return_data = {detections: detections, regions:regions};
        resolve(return_data);
    });
    return returnData;
}

const getLastWeek = async ({queryKey}) => {
    // console.log("Getting last week");
    const [_key, date, location, url] = queryKey;

    // Make params
    let startDate = new Date(date);
    let endDate = new Date(date);
    endDate.setDate(endDate.getDate() + 1);
    startDate.setDate(startDate.getDate() - 7);
    // startDate.setHours(0);
    // startDate.setMinutes(0);
    // endDate.setHours(0);
    // endDate.setMinutes(0);
    startDate = dateToUTC(startDate, location.time_zone);
    endDate = dateToUTC(endDate, location.time_zone)


    let params = {
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
        location_id: location.id
    }

    // console.log(params);

    const response = await fetch(url, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(params)
    })

    if (!response.ok){
        throw new Error("Could not fetch previous week data");
    }

    let data = response.json();
    return data;
}

/**
 * Takes a local date (e.g., new Date) that should be in the given timezone,
 * and converts it to UTC for use in querries
 * @param {Date} date 
 * @param {String} localTZ 
 */
const dateToUTC = (date, localTZ) => {
    let localOffset = -1 * date.getTimezoneOffset() * 60 * 1000;
    let baseOffset = getTimezoneOffset(localTZ);
    let adjusted = new Date(date.valueOf() - (baseOffset - localOffset));
    return adjusted;
}


export {
    getDaysOrders as getDaysOrders,
    getFullDetectionData as getFullDetectionData,
    getLastWeek as getLastWeek
};