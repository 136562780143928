const formatSeconds = (s, showZero=true) => {
	if (isNaN(s)) {
		return "--";
	}
    
    if (!showZero && (s == 0 || s == null) ){
        return '--';
    }
	let minutes = Math.floor(s / 60);
	let seconds = Math.round(s % 60);
	return `${minutes}m ${seconds < 10 ? "0" + seconds : seconds}s`;
};


exports.formatSeconds = formatSeconds;