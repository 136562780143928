import React from "react";

export default function CVToggleButton({ which, showStream, setShowStream }) {
	const getSVG = () => {
		if (which == "dt") {
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					class="icon icon-tabler icon-tabler-car"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					strokeWidth="2"
					stroke="currentColor"
					fill="none"
					strokeLinecap="round"
					strokeLinejoin="round">
					<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
					<circle cx="7" cy="17" r="2"></circle>
					<circle cx="17" cy="17" r="2"></circle>
					<path d="M5 17h-2v-6l2 -5h9l4 5h1a2 2 0 0 1 2 2v4h-2m-4 0h-6m-6 -6h15m-6 0v-5"></path>
				</svg>
			);
		} else {
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					class="icon icon-tabler icon-tabler-users"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					strokeWidth="2"
					stroke="currentColor"
					fill="none"
					strokeLinecap="round"
					strokeLinejoin="round">
					<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
					<circle cx="9" cy="7" r="4"></circle>
					<path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
					<path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
					<path d="M21 21v-2a4 4 0 0 0 -3 -3.85"></path>
				</svg>
			);
		}
	};

	return (
		<button
			className={`btn sm:w-auto w-6/12 border-gray-200 hover:border-gray-300 text-gray-500 hover:text-gray-600 ${
				showStream ? "bg-gray-300" : ""
			}`}
			onClick={() => setShowStream(!showStream)}>
			{getSVG()}
		</button>
	);
}
