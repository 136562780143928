import React, { useRef, useEffect } from "react";
import * as d3 from "d3";
import { getAccordionDetailsUtilityClass } from "@mui/material";
import { formatTime } from "./analyticsHelpers";

export default function TrendGraph({ data, showTimes, showVolumes, colors }) {
	const target = useRef();

	const margin = {
		top: 80,
		left: 60,
		right: 40,
		bottom: 30,
	};

	const HEIGHT = 500;

	useEffect(() => {
		console.log(target.current.clientWidth);
		if (target.current && d3.select(target.current).select("#mainG").empty()) {
			setupSVG();
		}
	}, []);

	useEffect(() => {
		plot();

		// Need to re-register listener with dependency updates
		// Register a resize listener
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [data, showTimes, showVolumes]);

	const setupSVG = () => {
		// To be called ONCE on start to setup the SVG
		console.log("setting up svg");
		const svg = d3
			.select(target.current)
			.append("svg")
			.attr("width", target.current.clientWidth)
			.attr("height", HEIGHT)
			.attr("id", "mainSVG");

		const g = svg
			.append("g")
			.attr("transform", `translate(${margin.left}, ${margin.top})`)
			.attr("id", "mainG");

		const xScale = d3
			.scaleLinear()
			.domain([])
			.range([0, target.current.clientWidth - margin.left - margin.right]);

		const yScale = d3
			.scaleLinear()
			.domain([])
			.range([0, HEIGHT - margin.top - margin.bottom]);

		g.append("g")
			.attr("id", "xAxis")
			.attr(
				"transform",
				`translate(${0}, ${HEIGHT - margin.top - margin.bottom})`
			)
			.call(d3.axisBottom(xScale));

		g.append("g")
			.attr("id", "yAxis")
			.attr("transform", `translate(0, 0)`)
			.call(d3.axisLeft(yScale));

		g.append("g")
			.attr("id", "yAxis2")
			.attr(
				"transform",
				`translate(${
					target.current.clientWidth - margin.left - margin.right
				}, 0)`
			)
			.call(d3.axisRight(yScale));

		// Axis Labels
		g.append("text")
			.attr("id", "y1Label")
			// .text("Y 1")
			.style("font-size", 10)
			.attr("text-anchor", "start")
			.attr(
				"transform",
				`translate(${-margin.left + 5}, ${
					(HEIGHT - margin.top - margin.bottom) / 2
				}) rotate(270)`
			);

		g.append("text")
			.attr("id", "y2Label")
			.attr("text-anchor", "middle")
			// .text('Y2')
			.style("font-size", 10)
			.attr(
				"transform",
				`translate(${
					target.current.clientWidth - margin.right - margin.left / 2
				}, ${(HEIGHT - margin.top - margin.bottom) / 2}) rotate(90)`
			);

		// Legend
		svg
			.append("g")
			.attr("id", "legend")
			.attr("transform", `translate(${margin.left}, ${0.25 * margin.top})`);

		// Add tooltip
		let tooltip = d3
			.select(target.current)
			.append("div")
			.attr("id", "regionGraphToolTip")
			.style("position", "absolute")
			// .style("position", "relative")
			.style("visibility", "hidden")
			.style("background-color", "white")
			.style("border", "solid")
			.style("border-width", "1px")
			.style("border-radius", "5px")
			.style("padding", "10px")
			.style("opacity", 0.75)
			.style("font-size", "10px");
	};

	const handleResize = () => {
		// Resize SVG
		const svg = d3
			.select(target.current)
			.select("#mainSVG")
			.attr("width", target.current.clientWidth);

		// re-plot--which resizes axes, labels, legend, and plot
		plot();
	};

	const legend = () => {
		// Get distinct locations
		let locs = data.reduce((agg, cur) => {
			// console.log(cur);
			if (!agg.map((x) => x.id).includes(cur.location_id)) {
				agg.push({ id: cur.location_id, name: cur.display_name });
			}
			return agg;
		}, []);

		let s = d3
			.scaleLinear()
			.domain([0, d3.min([5, locs.length])])
			.range([0, target.current.clientWidth - margin.left - margin.right]);

		// const g = d3.select(target.current).select("#legend");
		const g = d3.select(target.current).select("#legend");
		let circles = g
			.selectAll(".legendCircle")
			.data(locs, (d) => d.id)
			.join(
				(enter) =>
					enter
						.append("circle")
						// .attr('class', 'legendCircle')
						.classed("legendCircle", true)
						.classed("legendItem", true)
						.attr("cx", (d, i) => s(i % 5))
						.attr("cy", (d, i) => (i < 5 ? 5 : 25))
						.attr("r", 0)
						.style("fill", (d) => colors[d.id])
						.transition()
						.delay((d, i) => 500 + i * 300)
						.attr("r", 7),
				(update) =>
					update
						.transition()
						.attr("cx", (d, i) => s(i % 5))
						.attr("cy", (d, i) => (i < 5 ? 5 : 25))
						.attr("r", 7),
				(exit) => exit.remove()
			);

		let labels = g
			.selectAll(".legendLabel")
			.data(locs, (d) => d.id)
			.join(
				(enter) =>
					enter
						.append("text")
						// .attr('class', 'legendLabel')
						.classed("legendLabel", true)
						.classed("legendItem", true)
						.attr(
							"transform",
							(d, i) => `translate(${s(i % 5) + 10}, ${i < 5 ? 7 : 27})`
						)
						.style("font-size", 10)
						.transition()
						.delay((d, i) => 750 + i * 300)
						.text((d) => d.name)
						.attr("fill", (d) => colors[d.id])
						.style("cursor", "default"),
				(update) =>
					update
						.transition()
						.attr(
							"transform",
							(d, i) => `translate(${s(i % 5) + 10}, ${i < 5 ? 7 : 27})`
						),
				(exit) => exit.remove()
			);

		// attach listeners
		let items = g.selectAll(".legendItem");
		let mainG = d3.select(target.current).select("#mainG");
		items.on("pointerenter, pointermove", (d) => {
			// Stop hover prop
			d.stopPropagation(); // IT WORKED--stops the prop to svg hover
			// so we can actually remove the tooltip

			// console.log("HERE");
			let datum = d3.select(d.target).data();
			let location = datum[0].id;
			// console.log(datum);
			highlightLocation(location);

			// hide tooltip info
			console.log("should be hiding");
			mainG.selectAll(".tooltipLine").remove();
			mainG.selectAll(".tooltipPointTime").remove();
			mainG.selectAll(".tooltipPointVolume").remove();
			d3.select(target.current)
				.select("#regionGraphToolTip")
				.style("visibility", "hidden");
		});

		items.on("pointerleave", (d) => {
			let datum = d3.select(d.target).data();
			let location = datum[0].id;
			unHighlightLocation(location);
		});
	};

	const highlightLocation = (id) => {
		// Select All lines for the given location
		// console.log("Trying to highlight");
		const g = d3.select(target.current).select("#mainG");
		g.selectAll(".line")
			.transition()
			// .attr('stroke', d => d[0] == id ? colors[d[0]] : '#CCECEC')
			.attr("stroke-opacity", (d) => (d[0] == id ? 1 : 0.1));
	};

	const unHighlightLocation = (id) => {
		// Select All lines for the given location
		const g = d3.select(target.current).select("#mainG");
		g.selectAll(".line")
			.transition()
			// .attr('stroke', d => colors[d[0]])
			.attr("stroke-opacity", 1);
	};

	const plotLines = (xScale, yScale, datum, key) => {
		const g = d3.select(target.current).select("#mainG");
		let lineClass = `line_${key}`;
		let lines = g.selectAll("." + lineClass).data(datum);

		let newLines = lines.join(
			(enter) => {
				if (enter.empty()) {
					return enter;
				} // with weird return need this
				let path = enter
					.append("path")
					// .attr('class', `${lineClass} line`)
					.classed(lineClass, true)
					.classed("line", true)
					.attr("fill", "none")
					.attr("stroke", (d, i) => colors[d[0]])
					.attr("stroke-opacity", 1)
					.attr("stroke-width", 1.5)
					.attr("d", (d) => {
						return d3
							.line()
							.x((d) => xScale(new Date(d.date)))
							.y((d) => yScale(d[key]))(d[1]);
					});

				return (
					path
						.attr("stroke-dasharray", function (d) {
							let length = d3.select(this).node().getTotalLength();
							let dashArray;
							if (key == "avg_volume") {
								let dashing = "3, 3";
								let dashLength = dashing
									.split(/[\s,]/)
									.map(function (a) {
										return parseFloat(a) || 0;
									})
									.reduce(function (a, b) {
										return a + b;
									});
								let dashCount = Math.ceil(length / dashLength);
								let newDashes = new Array(dashCount).join(dashing + " ");
								dashArray = newDashes + " 0, " + length;
							} else {
								dashArray = length + " " + length;
							}
							return dashArray;
						})
						.attr("stroke-dashoffset", function (d) {
							return d3.select(this).node().getTotalLength();
						})
						.transition()
						// .duration(1500)
						.delay((d, i) => i * 100)
						.attr("stroke-dashoffset", 0)
						.on("end")
				);
			},
			(update) =>
				update
					.transition()
					.duration(800)
					.delay((d, i) => i * 200)
					// .attr('stroke', 'blue')
					.attr("d", (d) => {
						return d3
							.line()
							.x((d) => xScale(new Date(d.date)))
							.y((d) => yScale(d[key]))(d[1]);
					}),
			(exit) =>
				exit
					.transition()
					.duration(500)
					.attr("d", (d) => {
						return d3
							.line()
							.x((d) => xScale(new Date(d.date)))
							.y(500)(d[1]);
					})
					.remove()
		);

		return newLines;
	};

	const updateAxes = (xScale, y1Scale, y2Scale) => {
		const g = d3.select(target.current).select("#mainG");

		// Update axes objects
		g.select("#xAxis")
			.attr(
				"transform",
				`translate(${0}, ${HEIGHT - margin.top - margin.bottom})`
			)
			.transition()
			.call(d3.axisBottom(xScale));

		g.select("#yAxis")
			.transition()
			.call(
				d3
					.axisLeft()
					.scale(y1Scale)
					.tickFormat((d, i) => formatTime(d))
			);

		g.select("#yAxis2")
			.attr(
				"transform",
				`translate(${
					target.current.clientWidth - margin.left - margin.right
				}, 0)`
			)
			.transition()
			.call(d3.axisRight(y2Scale));

		// Axis Labels
		if (showTimes) {
			g.select("#y1Label")
				.attr(
					"transform",
					`translate(${-margin.left + 7}, ${HEIGHT / 2}) rotate(270)`
				)
				.text("Avg. Service Time");
		} else {
			g.select("#y1Label").text("");
		}

		if (showVolumes) {
			g.select("#y2Label")
				.attr(
					"transform",
					`translate(${
						target.current.clientWidth - margin.right - margin.left / 2
					}, ${(HEIGHT - margin.top - margin.bottom) / 2}) rotate(90)`
				)
				.text("Avg. Daily Volume (dashed)");
		} else {
			g.select("#y2Label").text("");
		}

		// Add Grid Lines (for which axis?)
		g.selectAll(".yGridLine")
			.data(y1Scale.ticks())
			.join(
				(enter) =>
					enter
						.append("line")
						.attr("class", "yGridLine")
						.attr("x1", 0)
						.attr("x2", target.current.clientWidth - margin.left - margin.right)
						.attr("y1", (d) => y1Scale(d))
						.attr("y2", (d) => y1Scale(d))
						.style("stroke", "grey")
						.style("stroke-opacity", 0.2),
				(update) =>
					update
						.transition()
						.attr("x1", 0)
						.attr("x2", target.current.clientWidth - margin.left - margin.right)
						.attr("y1", (d) => y1Scale(d))
						.attr("y2", (d) => y1Scale(d)),
				(exit) => exit.remove()
			);
	};

	const updateTooltip = (xScale, timeScale, volScale) => {
		let tooltip = d3.select(target.current).select("#regionGraphToolTip");
		let svg = d3.select(target.current).select("#mainSVG");
		let g = d3.select(target.current).select("#mainG");

		// get ALL dates
		let allDates = data.map((x) => new Date(x.date));

		const getTopOffset = (el) => {
			let offsets = [];
			while (el != undefined) {
				if (!isNaN(el.offsetTop)) {
					offsets.push(el.offsetTop);
				}
				el = el.parentElement;
			}
			return d3.max(offsets);
		};

		const moveTip = (e) => {
			/**
			 * d3.pointer(e, svg.node()) gets us mouse relative to the SVG which is good
			 * but we need to then get the relative distance from the top of the SVG to the top
			 * of the #analyticsPage, and add that onto the tooltip y coordinate, note that value
			 * should not change with scrolling, but still struggling to get it
			 */
			let offset = getTopOffset(
				d3.select(target.current).select("#mainSVG").node()
			);

			return tooltip
				.style("visibility", "visible")
				.style("top", d3.pointer(e, svg.node())[1] + offset - 10 + "px")
				.style("left", d3.pointer(e, svg.node())[0] + 75 + "px");
		};

		const setTipData = (e) => {
			// Get nearest date----
			// get pointer position x
			let x = d3.pointer(e, g.node())[0];

			// feed it into the xScale inverted
			let date = xScale.invert(x);

			// disectCenter to get the date (what data do we give it?)
			let closestDateIndex = d3.bisectCenter(allDates, date);
			let closestDate = allDates[closestDateIndex];

			// Get all Data that matches that date
			let matchingData = data.filter((x) => x.date == closestDate.valueOf());
			matchingData.sort((a, b) => b.avg_time - a.avg_time);
			// console.log(matchingData);

			// Set the Tooltip HTML
			let html = "";
			matchingData.forEach((d) => {
				if (showTimes) {
					html =
						html +
						`<p style="color:${colors[d.location_id]}">
                        ${d.display_name}: ${formatTime(d.avg_time)}
                    </p>`;
				}
				if (showVolumes) {
					html =
						html +
						`<p style="color:${colors[d.location_id]}">
                        &nbsp;(Volume): ${d.avg_volume}
                    </p>`;
				}
			});

			// Add Points on the lines.... fancy fancy fancy
			if (showTimes) {
				g.selectAll(".tooltipPointTime")
					.data(matchingData)
					.join("circle")
					.attr("class", "tooltipPointTime")
					.attr("cx", (d) => xScale(new Date(d.date)))
					.attr("cy", (d) => timeScale(d.avg_time))
					.attr("r", 4)
					.attr("fill", (d) => colors[d.location_id]);
			}
			if (showVolumes) {
				g.selectAll(".tooltipPointVolume")
					.data(matchingData)
					.join("circle")
					.attr("class", "tooltipPointVolume")
					.attr("cx", (d) => xScale(new Date(d.date)))
					.attr("cy", (d) => volScale(d.avg_volume))
					.attr("r", 4)
					.attr("fill", (d) => colors[d.location_id]);
			}
			// console.log(html);
			// Add Tooltip vertical line
			g.selectAll(".tooltipLine")
				.data([closestDate])
				.join("line")
				.attr("class", "tooltipLine")
				.attr("x1", xScale(new Date(closestDate)))
				.attr("x2", xScale(new Date(closestDate)))
				.attr("y1", HEIGHT - margin.top - margin.bottom)
				.attr("y2", 0)
				.style("stroke", "grey")
				.style("stroke-width", 1)
				.style("stroke-opacity", 0.5);

			tooltip.html(html);
		};

		d3.select(target.current)
			.select("#mainSVG")
			.on("pointerenter, pointermove", (e) => {
				// g.on('pointerenter, pointermove', e => {
				console.log("Showing tooltip");
				moveTip(e);
				setTipData(e);
				// tooltip.html("Starting");
			})
			.on("pointerleave", (e) => {
				// Hide tooltip
				tooltip.style("visibility", "hidden");

				// Remove points
				g.selectAll(".tooltipPointTime").remove();
				g.selectAll(".tooltipPointVolume").remove();
				g.selectAll(".tooltipLine").remove();
			});
	};

	const plot = () => {
		console.log("Plotting");
		if (data.length == 0) {
			d3.select(target.current)
				.select("#mainG")
				.selectAll(".noDataText")
				.data([1])
				.join("text")
				.attr("class", "noDataText")
				.style("text-anchor", "middle")
				.attr(
					"x",
					(target.current.clientWidth - margin.left - margin.right) / 2
				)
				.attr("y", (HEIGHT - margin.top - margin.bottom) / 2)
				.text("No data for selected parameters");
			return;
		} else {
			d3.select(target.current)
				.select("#mainG")
				.selectAll(".noDataText")
				.remove();
		}

		// Define Scales
		let xScale = d3
			.scaleTime()
			.domain(d3.extent(data.map((x) => new Date(x.date))))
			.range([0, target.current.clientWidth - margin.left - margin.right]);

		let timeScale = d3
			.scaleLinear()
			.domain([0, d3.max(data.map((x) => x.avg_time))])
			.range([HEIGHT - margin.top - margin.bottom, 0]);
		if (!showTimes) {
			timeScale.domain([]);
		}

		let volScale = d3
			.scaleLinear()
			.domain([0, d3.max(data.map((x) => x.avg_volume))])
			.range([HEIGHT - margin.top - margin.bottom, 0]);
		if (!showVolumes) {
			volScale.domain([]);
		}

		updateAxes(xScale, timeScale, volScale);

		// Update Axes
		const g = d3.select(target.current).select("#mainG");

		// Group Data
		let grouped = d3.group(data, (d) => d.location_id);

		// Plot Lines
		let timeLines = plotLines(
			xScale,
			timeScale,
			showTimes ? grouped : [],
			"avg_time"
		);
		let volLines = plotLines(
			xScale,
			volScale,
			showVolumes ? grouped : [],
			"avg_volume"
		);

		// Handle tooltip
		updateTooltip(xScale, timeScale, volScale);

		// Make / Update Legend
		legend();
	};

	return (
		<>
			<div ref={target}></div>
		</>
	);
}
