import { useQuery } from '@tanstack/react-query';
import React, { useState, useEffect } from 'react';
import { getLastWeek } from '../../api/orders';
import { useDataContext } from '../../contexts/DataContext';
import Loader from '../../utils/Loader';
import DropdownMenu from '../actions/DropdownMenu';
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	Label,
	ResponsiveContainer,
} from "recharts";


export default function WeekBarGraphWidget({customerLocationData, franchise, location, date}){


    // const [location, setLocation] = useState();

    const { routes } = useDataContext();


    const {data, refetch, isLoading, isFetching} = useQuery(
        ['orders-range', date, location, routes['ordersRangeTotals']],
        getLastWeek,
        {
            enabled: !!(!!franchise & !!customerLocationData & !!location),
            retry: 0,
            refetchOnWindowFocus: false
        }
    )


    const formatAxisDate = (v) => {
        let d = new Date(v.slice(0, 16));
        return d.toLocaleDateString();
    }

    const renderGraph = () => {
        if (data === undefined || isFetching || isLoading){
            return <Loader />
        }

        if (data.length == 0){
            return(
                <div className='w-full h-full justify-self-center align-center text-lg text-gray-500 text-center'>
                    No POS data found for this range
                </div>
            )
        }

        // Make the graph bitches
        // console.log(data);
        return (
            <ResponsiveContainer width='100%' height='100%'>
                <BarChart
                    width='100%'
                    height='100%'
                    data={data}
                    margin={{
						top: 10,
						right: 30,
						left: 20,
						bottom: 10,
					}}>
                <CartesianGrid strokeDasharray="3 3" />
					<XAxis
						dataKey="date"
						angle={-20}
						textAnchor="end"
						interval={0}
						tick={{ fontSize: "10px" }}
                        tickFormatter={(v) => formatAxisDate(v)}
					/>
					<YAxis
						tickFormatter={(v) => `$${v.toLocaleString()}`}
						// domain={[0, "dataMax"]}
						style={{ fontSize: "10px" }}
						tickCount={10}>
						<Label
							value={"Total Orders"}
							position="left"
							angle={-90}
							style={{ textAnchor: "middle", fontSize: "12px" }}
						/>
					</YAxis>
					<Tooltip
                        formatter={(val, name, props) => [
                            `$${val.toLocaleString()}`,
                            "Total",
                        ]}
                        labelFormatter={(name) => formatAxisDate(name)}
						wrapperStyle={{fontSize:'12px', opacity:'.95'}}
					/>    
                    <Bar dataKey='sum' fill="#54BFF0" />
                </BarChart>
            </ResponsiveContainer>
        )

    }
    
    return (
        <div className="flex flex-col col-span-full sm:col-span-8 bg-white shadow-lg rounded-lg border border-gray-200 mb-8">
            <header className="px-5 py-4 border-b border-gray-100">
                <div>
                    <h2 className='font-semibold text-gray-800 inline'>
                        This Week
                    </h2>
                    
                    <div className='flex flex-wrap items-center justify-center float-right'>
                        {/* {renderDropdown()} */}
                        <button
                            className="button bg-transparent hover:bg-blue-400
                            text-blue-400 hover:text-white rounded-full
                            border border-blue-400 py-1 px-2 text-xs ml-2"
                            style={{'maxHeight': '2.5em'}}
                            onClick={refetch}>
                            Refresh
                        </button>
                    </div>
                </div>
            </header>
            <div className='p-3'>
                <div className='min-h-56 h-64'>
                    {renderGraph()}
                </div>
            </div>
        </div>
    )

}