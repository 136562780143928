import React, { useState } from 'react';
import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';

import ComparisonAnalytics from '../partials/analytics/ComparisonAnalytics';

export default function Analytics() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // Contexts

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          title="Analytics"
        />

        <main>
          {/* This is where tab nav would go for Comparison and Trend */}
          <ComparisonAnalytics />
        </main>
      </div>
    </div>
  );
}
