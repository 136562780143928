import React from 'react';
import MultiSelectDropdownMenu from '../actions/MultiSelectDropdown';
import { useAuth } from "../../contexts/AuthContext";

export default function NotificationSettings({allLocations, selectedLocations, handleNotificationChange}){
	const { currentUser } = useAuth();
	/**
	 * Helper to toggle locations in selectedLocations based on the multiselect
	 * @param {sting} loc 
	 */
	const toggleHelper = (loc) => {
		let locData = allLocations.filter(x => x.display_name == loc)[0];
		// If location selected remove it
		if (selectedLocations.map(x => x.display_name).includes(loc)){
			console.log(JSON.stringify({
				remove_notification: locData.id
			}));
			handleNotificationChange(selectedLocations.filter(x => x.display_name != loc));
		}
		// If not selected, add it
		else{
			// Get the match

			console.log(JSON.stringify({
				add_notification: locData.id
			}));

			handleNotificationChange([...selectedLocations, locData]);
		}
	}

	if ((allLocations == undefined) || (allLocations.length == 0) 
				 || (selectedLocations == undefined)){
		return <div className="animate-pulse bg-blue-400 rounded w-56 h-2"></div>;
	}

	return (
		<div>
			<span className='text-xs text-gray-600'>Locations</span><br/>
			<MultiSelectDropdownMenu
				options={allLocations
					.map(x => x.display_name)}
				toggleOption={toggleHelper}
				selected={selectedLocations.map(x => x.display_name)}
				label="Locations"
				/>
		</div>
	)
}