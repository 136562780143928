/**
 * 
 _    ___     __           ______            __             __    
| |  / (_)___/ /__  ____  / ____/___  ____  / /__________  / /____
| | / / / __  / _ \/ __ \/ /   / __ \/ __ \/ __/ ___/ __ \/ / ___/
| |/ / / /_/ /  __/ /_/ / /___/ /_/ / / / / /_/ /  / /_/ / (__  ) 
|___/_/\__,_/\___/\____/\____/\____/_/ /_/\__/_/   \____/_/____/  
																  
 * 
 * Partial Component to control all the videos at once
 * This is esppecially important on Mobile as the video
 * player is super sketch
 */

import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import Replay5Icon from "@material-ui/icons/Replay5";
import Forward5Icon from "@material-ui/icons/Forward5";
import SpeedIcon from "@material-ui/icons/Speed";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import Slider from "@material-ui/core/Slider";
import Grid from "@material-ui/core/Grid";

import ReorderIcon from '@material-ui/icons/Reorder';
import AppsIcon from '@material-ui/icons/Apps';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import React from "react";

export default function VideoControls(props) {
	const pause = () => {
		props.setIsPlaying(!props.isPlaying);
	};

	const rewind = () => {
		props.setRewindCount(props.rewindCount + 1);
	};

	const fastForward = () => {
		props.setFastForwardCount(props.fastForwardCount + 1);
	};

	const renderSpeedOptions = () => {
		// let speeds = props.cameras.length > 1 ? [1, 2, 5, 10] : [1, 2, 5, 10, 15];
		let speeds = [1, 2, 5, 10, 15];
		return (
			<div className="flex justify-center">
				<div className="m-3 flex flex-row bg-white shadow-lg rounded-lg p-2">
					<span className="mr-3 mt-3 flex flex-row">
						<SpeedIcon />
					</span>
					<div class="inline-flex">
						{
							speeds.map(speed => {
								return(
									<button
										class={`btn ${props.speed == speed ? "bg-gray-200" : "bg-white"}
										focus:outline-none
										${props.isLive ? "cursor-not-allowed" : ""}
										${props.isLive ? "" : "hover:bg-gray-100"}`}
										onClick={() => props.setSpeed(speed)}
										disabled={props.isLive}>
										{speed}X
									</button>
								)
							})
						}
					</div>
				</div>
			</div>
						
		)
	}

	return (
		<>
			{/* <div className='flex flex-col sm:flex-row justify-center sm:justify-start'> */}
			<div className="flex flex-col sm:flex-row justify-center">
				{/* <div className='m-3 bg-white shadow-lg rounded-lg p-2 flex justify-center'>
					<ButtonGroup>
						<IconButton onClick={rewind} disabled={props.isLive}>
							<Replay5Icon />
						</IconButton>
						<IconButton onClick={pause}>
							{props.isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
						</IconButton>
						<IconButton onClick={fastForward} disabled={props.isLive}>
							<Forward5Icon />
						</IconButton>
					</ButtonGroup>
				</div> */}

				<div className="flex justify-center">
					<div className="m-3 bg-white shadow-lg rounded-lg p-2 ">
						<ButtonGroup>
							<IconButton onClick={rewind} disabled={props.isLive}>
								<Replay5Icon />
							</IconButton>
							<IconButton onClick={pause}>
								{props.isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
							</IconButton>
							<IconButton onClick={fastForward} disabled={props.isLive}>
								<Forward5Icon />
							</IconButton>
						</ButtonGroup>
					</div>
				</div>

				{renderSpeedOptions()}


				{/* Page  */}
				{( (props.page != undefined) ) ? 
					(
					// <div className={`sm:flex hidden justify-center`}>
					<div className={`flex justify-center`}>
						<div className="m-3 flex flex-row rounded-lg p-2">
							<div className="inline-flex">
								<button 
									className={`btn 
										bg-white
										rounded-tr-none
										rounded-br-none
										focus:outline-none`}
									onClick={props.prevPage}
									
								>
									<NavigateBeforeIcon />
								</button>
								<div className={`btn 
									bg-white 
									rounded-none
									focus:outline-none 
									cursor:default`}>
									{props.page[0]} of {props.page[1]}
								</div>
								<button className={`btn 
									bg-white
									rounded-bl-none
									rounded-tl-none
									focus:outline-none`}
									onClick={props.nextPage}
								>
									<NavigateNextIcon />
								</button>
							</div>
						</div>
					</div>
					): null
				}

				{/* Grid or no grid */}
				<div className={`sm:flex hidden justify-center`}>
					<div className="m-3 flex flex-row rounded-lg p-2">
						<div className="inline-flex">
							<button 
								className={`btn 
									${props.grid ? 'bg-white' : ''} 
									focus:outline-none`}
								onClick={() => props.setGrid(true)}
							>
								<AppsIcon />
							</button>
							<button className={`btn 
								${!props.grid ? 'bg-white' : ''} 
								focus:outline-none`}
								onClick={() => props.setGrid(false)}
							>
								<ReorderIcon />
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
