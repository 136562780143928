import React, { useState, useEffect } from "react";
import { useDataContext } from "../../contexts/DataContext";
import { useAuth } from "../../contexts/AuthContext";
import app from '../../firebase';
import ModalBlank from "../../utils/ModalBlank";
import ModalBasic from "../actions/ModalBasic";

export default function DriveThruSettings() {
	// const [dtLocations, setDTLocations] = useState();
	const [locations, setLocations] = useState();
	const [type, setType] = useState(); // either dt or security, indicates which button pressed
	const [modalOpen, setModalOpen] = useState(false);
	const [modalError, setModalError] = useState(false);
	const [focusDevice, setFocusDevice] = useState();
	const [redirecting, setRedirecting] = useState(false);
	const { routes } = useDataContext();
	const { currentUser } = useAuth();

	const allowedLocations = [20, 24, 27];

	// Get all of the customer locations and filter DT for render
	useEffect(() => {
		let url = routes["customerLocations"];
		fetch(url, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ uid: currentUser.uid }),
		})
			.then((res) => res.json())
			.then((data) => {
				// setDTLocations(data.filter((l) => l.has_drive_through));
				setLocations(data.filter(l => allowedLocations.includes(l.id)));
			})
			.catch((err) => console.warn(err));
	}, []);

	// const prepModal = (id) => {
	// 	let r = window.confirm(
	// 		"Please make sure you are connected to the store's wifi network before continuing"
	// 	);
	// 	if (!r) {
	// 		return;
	// 	}

	// 	// Get the IP
	// 	let url = routes["deviceIP"];
	// 	fetch(url + `/${id}`)
	// 		.then((res) => res.json())
	// 		.then((data) => {
	// 			console.log(data);
	// 			let ip = data["ip"];
	// 			let url = `http://${ip}:5000`;
	// 			window.open(url, "_blank");
	// 		});
	// };

	/**
	 * GEts the IP based on the focusLocation state
	 * then opens a new tab with the URL and port
	 */
	const getIPAndRedirect = async () => {
		setRedirecting(true);

		// Check to see if alternative link in firestore
		console.log(focusDevice);
		const db = app.firestore();
		let locRef = await db.collection('location').doc(String(focusDevice.location_id)).get();
		let locData = await locRef.data();
		console.log(locData);
		Object.keys(locData).includes("urls")


        // SHould be able to comment out this logic to get local IP only and not tunnel
		// if (Object.keys(locData).includes("urls")) {
		// 	if (type == 'dt'){
		// 		let urls = locData['urls'];
		// 		if (Object.keys(urls).includes("dt_monitor")){
		// 			// use this instead
		// 			window.open(urls.dt_monitor, "_blank");
		// 			setRedirecting(false);
		// 			setModalOpen(false);
		// 			setType(undefined);
		// 			return;
		// 		}
		// 	}
	
		// 	if (type == 'security'){
		// 		let urls = locData['urls'];
		// 		if (Object.keys(urls).includes("video_monitor")){
		// 			// use this instead
		// 			window.open(urls.video_monitor + "/pages/multiview/full", "_blank");
		// 			setRedirecting(false);
		// 			setModalOpen(false);
		// 			setType(undefined);
		// 			return;
		// 		}
		// 	}
		// }


		let port = type == 'dt' ? 5000 : 8083

		// If no link in firestore, then work with local ip
		let id = focusDevice.id;
		let url = routes["deviceIP"];
		fetch(url + `/${id}`)
			.then((res) => res.json())
			.then((data) => {
				console.log(data);
				let ip = data["ip"];
				let url = `http://${ip}:${port}`;
				console.log(url);

                if (type !== 'dt'){
                    url  = url + "/pages/multiview/full";
                }

				window.open(url, "_blank");
				setRedirecting(false);
				setModalOpen(false);
				setType(undefined);
			})
			.catch((err) => {
				console.warn(err);
				setRedirecting(false);
				setModalError(true);
				setType(undefined);
			})
	};

	const renderLocations = () => {
		if (locations === undefined) {
			// Show loader
			return (
				<>
					{[1, 2, 3].map((x) => {
						return (
							<div className="p-4 w-full" key={`placeholder-${x}`}>
								<div className="animate-pulse">
									<div className="h-2 rounded-lg bg-blue-500"></div>
								</div>
							</div>
						);
					})}
				</>
			);
		} else if (locations.length === 0) {
			// Show message that no DT available
			return (
				<div>
					This account does not have any drive-thru monitor or security monitor locations, if you
					think this is in error, please conact support
				</div>
			);
		} else {
			// Render the objects
			return (
				<>
					{locations.map((loc) => {
						let dt_device;
						let device;
						if (loc.devices.length == 1){
							dt_device = loc.devices[0];
							device = loc.devices[0];
						}
						else{
							// Must be more than 1
							dt_device = loc.devices.filter(x => x.is_drive_through)[0];
							device = dt_device;
						}
						// let dt_device = loc.devices.filter((x) => x.is_drive_through);
						// if (device.length == 0) {
						// 	return null;
						// }
						// dt_device = dt_device[0];
						// console.log(dt_device);
						// console.log(device);
						return (
							<div
								className="flex justify-between border items-center shadow-md border-gray-200 p-2 rounded-lg"
								key={`location-${loc.id}`}>
								<div>{loc.display_name}</div>
								<div>
									{loc.has_drive_through ? (
										<button
											className="btn rounded-full border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white"
											// onClick={() => prepModal(device.id)}
											onClick={(e) => {
												console.log("Clicked");
												e.stopPropagation();
												setModalError(false);
												setFocusDevice(dt_device);
												setModalOpen(true);
												setType('dt');
											}}>
											Drive Thru
										</button>
									) : null}
									{
										<button
											className="btn ml-2 rounded-full border-yellow-500 text-yellow-500 hover:bg-yellow-500 hover:text-white"
											// onClick={() => prepModal(device.id)}
											onClick={(e) => {
												console.log("Clicked");
												e.stopPropagation();
												setModalError(false);
												setFocusDevice(device);
												setModalOpen(true);
												setType('security');
											}}>
											Security
										</button>
									}
								</div>
							</div>
						);
					})}
				</>
			);
		}
	};

	return (
		<>
			<h2 className="text-xl text-gray-800 font-bold mb-1">Monitors</h2>
			<div className='p-3 text-sm bg-yellow-100 text-yellow-600 border-yellow-100 rounded-lg w-full'>
				Note, to connect to a monitor, you <i>must</i> be connected to that store's Wifi network
			</div>
			{renderLocations()}
			<div>
				<ModalBlank
					id="connect-modal"
					modalOpen={modalOpen}
					setModalOpen={setModalOpen}>
					{/* Start */}
					<div className="p-5 flex space-x-4">
						{/* Icon */}
						<div className="w-10 h-10 rounded-full flex items-center justify-center flex-shrink-0 bg-green-100">
							<svg
								className="w-4 h-4 flex-shrink-0 fill-current text-green-500"
								viewBox="0 0 16 16">
								<path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z" />
							</svg>
						</div>
						{/* Content */}
						<div>
							{/* Modal header */}
							<div className="mb-2">
								<div className="text-lg font-semibold text-gray-800">
									Verify Connection
								</div>
							</div>
							{/* Modal content */}
							<div className="text-sm mb-10">
								<div className="space-y-2">
									<p>
										Please make sure you are connected to the store's WiFi
										network before proceeding
									</p>
								</div>

								{ redirecting ? (
									<div className='p-3 mt-3 border border-green-100 rounded-lg bg-green-100 text-green-600'>
										Working on establishing a connection, you will be redirected automatically!
									</div>
								): null}

								{ modalError ? (
									<div className='p-3 mt-3 border border-red-100 rounded-lg bg-red-100 text-red-600'>
										Hmm, we are having an issue establishing a connection, make sure your Hellometer device is powered
										on and connected to the internet then try again
									</div>
								): null}

							</div>
							{/* Modal footer */}
							<div className="flex flex-wrap justify-end space-x-2">
								<button
									className="btn-sm border-gray-200 hover:border-gray-300 text-gray-600"
									onClick={(e) => {
										e.stopPropagation();
										setModalOpen(false);
									}}>
									Give me a minute
								</button>
								<button className="btn-sm bg-green-500 hover:bg-green-600 text-white"
									onClick={getIPAndRedirect}
									disabled={redirecting}>
									Yup, I'm Connected
								</button>
							</div>
						</div>
					</div>
				</ModalBlank>
			</div>
		</>
	);
}
