/**
 _    ___     __           ______     _     __
| |  / (_)___/ /__  ____  / ____/____(_)___/ /
| | / / / __  / _ \/ __ \/ / __/ ___/ / __  / 
| |/ / / /_/ /  __/ /_/ / /_/ / /  / / /_/ /  
|___/_/\__,_/\___/\____/\____/_/  /_/\__,_/   
											  
 * 
 * Creates the grid for the videos, controls which cameras we will
 * be showing for each location and passes down any relevant props
 * (analog is VideoManager in legacy app)
 */

import React, { useState, useEffect } from "react";
import CameraViewer from "./CameraViewer_hidden";
import VideoControls from "./VideoControls_hidden";


export default function VideoGrid(props){

	// define states
	const [cameras, setCameras] = useState(['output']);
	const [isPlaying, setIsPlaying] = useState(true);
	const [rewindCount, setRewindCount] = useState(0);
	const [fastForwardCount, setFastForwardCount] = useState(0);
	const [speed, setSpeed] = useState(1);
	const [fullScreen, setFullScreen] = useState();
	const realTimeSpeedFactor = 0.2;
	const [grid, setGrid] = useState(true);

	// Note: videojs has a fluid mode: https://docs.videojs.com/tutorial-layout.html

	/**
	 * On Location Change, check if DT and update the list
	 * of video prefixes to show
	 */
	useEffect(() => {
		if (props.currentLocation != undefined){
			if (props.currentLocation.has_drive_through){
				setCameras(['output', 'entry', 'exit']);
			}
			// else if (props.currentLocation.id == 2){
			// 	console.log("Crenshaw Camera Hack")
			// 	// Hard code in crenshaw for now
			// 	setCameras([0, 1, 2, 3]);
			// 	// setCameras([0])
			// }
			else{
				setCameras(['output']);
			}
		}

		setIsPlaying(true); // when locatin changes automatically plays
	}, [props.currentLocation]);

	/**
	 * On live force the playback rate to 1X
	 */
	useEffect(() => {
		setSpeed(1);
	}, [props.isLive])


	/**
	 * Uses the speed set by the control slider to get
	 * the speed at which the video is to be played back
	 * (not 1:1 as 1X needs to be slowed down)
	 */
	const getPlaybackSpeed = () => {
		if (speed ==1 ){
			if (props.currentLocation.id == 2){
				console.log("Crenshaw Speed Hack");
				return 1
			}
			else{
				return realTimeSpeedFactor;
			}
		}
		else{
			return speed;
		}
		// return speed == 1 ? realTimeSpeedFactor : speed;
	}

	const getGridClass = () => {
		// If in-line is selected, for that, otherwise do dynamic
		if (!grid){
			return 'grid grid-cols-1 gap-4';
		}
		if (cameras.length == 1){
			return 'grid grid-cols-1 gap-4'
		}
		return 'grid gap-4 grid-cols-1 sm:grid-cols-2'
	}


	return(
		<>
		
			<div>
				{/* <div className='bg-white shadow-lg rounded-lg mb-8'> */}
				<div className='mb-2'>
					<VideoControls 
						isLive={props.isLive}
						setIsLive={props.setIsLive}
						isPlaying={isPlaying}
						setIsPlaying={setIsPlaying}
						videoDateTime={props.videoDateTime}
						setVideoDateTime={props.setVideoDateTime}
						fastForwardCount={fastForwardCount}
						setFastForwardCount={setFastForwardCount}
						rewindCount={rewindCount}
						setRewindCount={setRewindCount}
						speed={speed}
						setSpeed={setSpeed}
						cameras={cameras}
						setFullScreen={setFullScreen}
						grid={grid}
						setGrid={setGrid}
					/>
				</div>
			</div>

			{/* <div className='grid gap-4 xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-1'> */}
			<div className={getGridClass()}>
				{
					cameras.map(cam => {
						if (props.currentLocation == undefined){
							return;
						}
						return(
							<div className='bg-white shadow-lg rounded-lg border-gray-200'>
								<CameraViewer 
									currentLocation={props.currentLocation}
									videoDateTime={props.videoDateTime}
									isPlaying={isPlaying}
									setIsPlaying={setIsPlaying}
									camera={cam}
									speed={getPlaybackSpeed()}
									fastForwardCount={fastForwardCount}
									rewindCount={rewindCount}
									fullScreen={fullScreen}
									setFullScreen={setFullScreen}
									isLive={props.isLive}
									grid={grid}
									key={`${props.currentLocation.address}-${cam}`}/>
							</div>)
					})
				}
			</div>

			{/* <div className='grid gap-4 xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-1'>
				{
					[1,2,3,4,5,6].map(x => {
						return(
							<div className='bg-white shadow-lg rounded-lg border-gray-200 p-6'>
								THis is where we puta bunch of shite
							</div>)
					})
				}
			</div> */}
		</>
	)
}