import React, { useState, useEffect } from "react";
import Flatpickr from "react-flatpickr";
// import CircularProgress from '@material-ui/core/CircularProgress';
import download from "downloadjs";
import SaveIcon from "@material-ui/icons/Save";
import { useDataContext } from "../../contexts/DataContext";
import { getTimezoneOffset } from "date-fns-tz";

const aws = require("aws-sdk");
aws.config.update({
	region: "us-west-2",
	credentials: {
		// accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
		// secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        accessKeyId: process.env.REACT_APP_KINESIS_APP_AWS_ACCESS_KEY_ID,
		secretAccessKey: process.env.REACT_APP_KINESIS_APP_AWS_SECRET_ACCESS_KEY
	},
});

const kinesisVideo = new aws.KinesisVideo();
const kinesisVideoArchivedContent = new aws.KinesisVideoArchivedMedia();

export default function VideoDownload(props) {
	const [showModal, setShowModal] = useState(false);
	const [saveDate, setSaveDate] = useState(props.videoDateTime);
	const [startTime, setStartTime] = useState([12, 30]);
	const [endTime, setEndTime] = useState([12, 45]);
	const [errorMsg, setErrorMsg] = useState("");
	const [whichCamera, setWhichCamera] = useState(0);
	const [canDownload, setCanDownload] = useState(true);

	// const newLocations = [2, 20, 21];
	const { oldLocations, awsLocations, nonAwsLocations } = useDataContext();

	const flatpickrOptions = {
		mode: "single",
		enableTime: false,
		static: true,
		monthSelectorType: "static",
		dateFormat: "M j, Y ",
		defaultDate: props.videoDateTime,
		disableMobile: true,
		prevArrow:
			'<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
		nextArrow:
			'<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
	};

	const d1 = new Date();
	d1.setHours(12);
	d1.setMinutes(30);
	const flatpickrStartTimeOptions = {
		enableTime: true,
		noCalendar: true,
		dateFormat: "h:i K",
		defaultDate: d1,
	};

	const d2 = new Date();
	d2.setHours(12);
	d2.setMinutes(45);
	const flatpickrEndTimeOptions = {
		enableTime: true,
		noCalendar: true,
		dateFormat: "h:i K",
		defaultDate: d2,
	};

	useEffect(() => {
		if (props.currentLocation) {
			let id = props.currentLocation.id;
			// if (id == 2){
			// if (newLocations.indexOf(id) != -1){
			if (oldLocations.indexOf(id) == -1) {
				setWhichCamera(0);
			} else {
				setWhichCamera("output");
			}
		}
	}, [props.currentLocation]);

	const formatTime = (h, m) => {
		return `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}`;
	};

	const formatAWSDate = (d, h, m) => {
		// let lcl = new Date(d);
		let lcl = d;
		lcl.setHours(h);
		lcl.setMinutes(m);
		let localOffset = -1 * lcl.getTimezoneOffset() * 60 * 1000;
		// let baseOffset = getTimezoneOffset("America/Los_Angeles");
        let baseOffset = getTimezoneOffset(props.currentLocation.time_zone);
		let adjusted = new Date(lcl.valueOf() - (baseOffset - localOffset));
		return adjusted;
	};

	const awsDownload = async () => {
		setCanDownload(false);
		// Organize download params
		let startDate = formatAWSDate(saveDate, startTime[0], startTime[1]);
		let endDate = formatAWSDate(saveDate, endTime[0], endTime[1]);
		let streamName = `${props.currentLocation.id}_${whichCamera}`;

		console.log("Stream", streamName);
		console.log("Start Date", startDate);
		console.log("End Date", endDate);

		// return;

		// Get endpoint
		const endpointObject = await kinesisVideo
			.getDataEndpoint({
				StreamName: streamName,
				APIName: "GET_HLS_STREAMING_SESSION_URL",
			})
			.promise();

		// Define options
		let options = {
			ClipFragmentSelector: {
				FragmentSelectorType: "SERVER_TIMESTAMP",
				TimestampRange: {
					StartTimestamp: startDate,
					EndTimestamp: endDate,
				},
			},
		};

		// return;

		if (endpointObject.DataEndpoint) {
			const kinesisVideoArchivedMedia = new aws.KinesisVideoArchivedMedia({
				region: "us-west-2",
				endpoint: new aws.Endpoint(endpointObject.DataEndpoint),
			});

			kinesisVideoArchivedMedia.getClip(
				{
					StreamName: streamName,
					...options,
				},
				(err, data) => {
					if (err) {
						console.warn(err);
						setCanDownload(true);
						return;
					}
					let blob = new Blob([data.Payload], { type: "video/mp4" });
					let m = saveDate.getMonth() + 1;
					let month = m < 10 ? "0" + m : m;
					let day =
						saveDate.getDate() < 10
							? "0" + saveDate.getDate()
							: saveDate.getDate();
					let fileName = `video-${saveDate.getFullYear()}-${month}-${day}-${
						startTime[0]
					}H${startTime[1]}M.mp4`;
					download(blob, fileName, "video/mp4");
					setCanDownload(true);
				}
			);
		}
	};

	const handleDownload = () => {
		// if (awsLocations.includes(props.currentLocation.id)) {
        if (!nonAwsLocations.includes(props.currentLocation.id)) {
			awsDownload();
			return;
		}

		setCanDownload(false);

		// Gather request data
		let m = saveDate.getMonth() + 1;
		let month = m < 10 ? "0" + m : m;
		let day =
			saveDate.getDate() < 10 ? "0" + saveDate.getDate() : saveDate.getDate();
		let params = {
			date: `${saveDate.getFullYear()}-${month}-${day}`,
			location_id: props.currentLocation.id,
			camera: whichCamera,
			timezone: props.currentLocation.time_zone,
			startTime: formatTime(startTime[0], startTime[1]),
			endTime: formatTime(endTime[0], endTime[1]),
		};

		console.log(params);

		// Make request
		// fetch("http://localhost:5005", {
		fetch(
			"https://us-central1-hellometer.cloudfunctions.net/video_download_test",
			{
				// fetch("https://us-central1-hellometer.cloudfunctions.net/video_download", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "*",
				},
				body: JSON.stringify(params),
			}
		)
			.then((res) => res.json())
			.then((json) => {
				let vidName = `video-${saveDate.getFullYear()}-${month}-${day}-${
					startTime[0]
				}H${startTime[1]}M.mp4`;
				let link = json["link"];

				// Make request to link to get the blob
				fetch(link)
					.then((res) => res.blob())
					.then((blob) => {
						download(blob, vidName, "video/mp4");
						setCanDownload(true);
					})
					.catch((err) => {
						window.alert(
							"There was a problem processing your download, please try again later"
						);
						setCanDownload(true);
					});
			})
			.catch((err) => {
				window.alert(
					"There was a problem processing your download, please try again later"
				);
				setCanDownload(true);
			});
	};

	const updateDate = (newDate) => {
		setSaveDate(newDate);
	};

	const updateTime = (newTime, which) => {
		// Update the time
		let h = newTime.getHours();
		let m = newTime.getMinutes();
		let delta;
		if (which == "start") {
			setStartTime([h, m]);
			delta = getTimeDiff([h, m], endTime);
		} else {
			setEndTime([h, m]);
			delta = getTimeDiff(startTime, [h, m]);
		}
		console.log(delta);
		// Check to see if valid times
		if (delta > 30) {
			console.log("Cannot have more than 30 minutes");
			setErrorMsg("Cannot download more than 30 minutes at time");
		} else if (delta <= 0) {
			console.log("Must have start time before end time");
			setErrorMsg("Start time must be before end time");
		} else {
			console.log("No Error");
			setErrorMsg("");
		}
	};

	/**
	 * Gets the difference in minutes
	 * @param {Time one [h, m]} t1
	 * @param {TIme two [h, m]} t2
	 */
	const getTimeDiff = (t2, t1) => {
		if (t1 == undefined || t2 == undefined) {
			return;
		}
		let d1 = new Date();
		let d2 = new Date();
		d1.setHours(t1[0]);
		d1.setMinutes(t1[1]);
		d2.setHours(t2[0]);
		d2.setMinutes(t2[1]);
		let delta = (d1 - d2) / 1000; // seconds
		let deltaMin = delta / 60;
		return deltaMin;
	};

	const renderCameraSelect = () => {
		let options = [];
		if (props.currentLocation == undefined) {
			return <></>;
		} else {
			// if (props.currentLocation.id == 2 || props.currentLocation.id == 20){
			// if (newLocations.indexOf(props.currentLocation.id) != -1){
			if (oldLocations.indexOf(props.currentLocation.id) == -1) {
				// options = [0, 1, 2, 3];
				let devices = props.currentLocation.devices;
				let cameras = [];
				devices.forEach((device) => {
					let cams = device.cameras;
					cams.forEach((cam) => cameras.push(cam.storage_id));
				});
				options = cameras;
			} else if (props.currentLocation.has_drive_through) {
				options = ["entry", "exit", "output"];
			}
			// else if (props.currentLocation.id == 2){
			// 	options = [0, 1, 2, 3];
			// }
			else {
				options = ["output"];
			}
		}

		return (
			<select
				className="w-full text-xs text-gray-500 border-gray-200 rounded-sm"
				onChange={(e) => setWhichCamera(e.target.value)}>
				{options.map((cam) => {
					return (
						<option value={cam} selected={whichCamera == cam}>
							{cam}
						</option>
					);
				})}
			</select>
		);
	};

	return (
		<>
			<button
				className="btn sm:w-auto w-6/12 border-gray-200 hover:border-gray-300 text-gray-500 hover:text-gray-600"
				onClick={() => setShowModal(true)}>
				<SaveIcon className="mr-2" />
				Download
			</button>
			{showModal ? (
				<>
					<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
						<div className="relative w-auto my-6 mx-auto max-w-2xl">
							{/*content*/}
							<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
								{/*header*/}
								<div className="flex items-start justify-between p-4 border-b border-solid border-blueGray-200 rounded-t">
									<h3 className="text-xl font-semibold">Download Video</h3>
									<button
										className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
										onClick={() => setShowModal(false)}>
										<span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
											×
										</span>
									</button>
								</div>
								{/*body*/}
								<div className="relative p-3 flex-auto content-center">
									<p className="my-4 text-blueGray-500 leading-relaxed">
										Use the below to select the video date and time you would
										like to download for <i>{props.currentLocation.address}</i>.
										You can download at most 30 minutes at a time.
									</p>
								</div>
								<div className="grid grid-cols-1 gap-2">
									<div className="flex justify-center">
										<div className="w-5/12">
											Choose Camera
											<br />
											{renderCameraSelect()}
										</div>
									</div>

									<div className="flex justify-center">
										<div className="w-5/12">
											Pick Video Date
											<br />
											<Flatpickr
												className="form-input pl-9 text-gray-500 hover:text-gray-600 font-medium focus:border-gray-300"
												options={flatpickrOptions}
												onClose={(val) => updateDate(val[0])}
											/>
										</div>
									</div>

									<div className="flex justify-center mt-2">
										<div className="w-5/12">
											Choose Start Time
											<br />
											<Flatpickr
												className="form-input pl-9 text-gray-500 hover:text-gray-600 font-medium focus:border-gray-300"
												options={flatpickrStartTimeOptions}
												onClose={(val) => updateTime(val[0], "start")}
											/>
										</div>
									</div>

									<div className="flex justify-center mt-2 mb-5">
										<div className="w-5/12">
											Choose End Time
											<br />
											<Flatpickr
												className="form-input pl-9 text-gray-500 hover:text-gray-600 font-medium focus:border-gray-300"
												options={flatpickrEndTimeOptions}
												onClose={(val) => updateTime(val[0], "end")}
											/>
										</div>
									</div>

									{errorMsg != "" ? (
										<div className="flex justify-center m-4">
											<div className="bg-red-200 text-red-600 rounded-lg p-4">
												{errorMsg}
											</div>
										</div>
									) : null}

									{!canDownload ? (
										<div className="flex justify-center m-4">
											<div className="bg-green-200 text-green-600 rounded-lg p-4 mx-6">
												Your video is being processed and will download once
												complete, this could take a few minutes
											</div>
										</div>
									) : null}
								</div>
								{/*footer*/}
								<div className="flex items-center justify-end p-3 border-t border-solid border-blueGray-200 rounded-b">
									<button
										className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
										type="button"
										onClick={() => setShowModal(false)}>
										Close
									</button>
									<button
										className={`${
											!canDownload ? "animate-pulse" : ""
										} bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
										type="button"
										disabled={errorMsg == "" || !canDownload ? false : true}
										onClick={handleDownload}>
										Download
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="opacity-40 fixed inset-0 z-40 bg-black"></div>
				</>
			) : null}
		</>
	);
}
