import React, { useState, forwardRef, useEffect} from 'react';
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { DialogContent, DialogTitle, DialogContentText, DialogActions } from '@mui/material';


const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

export default function LocationSelector({
    options,
    selected,
    setSelected
}){
    const [open, setOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [displayLocations, setDisplayLocations] = useState(options);
    const [displaySelected, setDisplaySelected] = useState(selected);
    const MAX_LOCATIONS = 10;

    const handleClose = () => {setOpen(false)};
    const handleClickOpen = () => {setOpen(true)};

    useEffect(() => {
        options.sort((a,b) => a.display_name - b.display_name)
        setDisplayLocations(options);
    }, [options])

    useEffect(() => {
        setDisplaySelected(selected);
    }, [selected])

    /**
     * Based on the filter, determine which locations should be shown
     */
    const filterLocations = (e) => {
        let val = e.target.value.toLowerCase();
        if (val == ''){
            options.sort((a,b) => a.display_name - b.display_name)
            setDisplayLocations(options);
            return;
        }
        let matches = options.filter(l => {
            if ( (l.address !== undefined & l.address !== null)){
                if (l.address.toLowerCase().includes(val) ){
                    return true;
                }
            }
            if ( (l.display_name !== undefined & l.display_name !== null)){
                if (l.display_name.toLowerCase().includes(val)){
                    return true;
                }
            }
            if ( (l.city !== undefined & l.city !== null)){
                if (l.city.toLowerCase().includes(val)){
                    return true;
                }
            }
            if ( (l.zipcode !== undefined & l.zipcode !== null)){
                if (l.zipcode.toLowerCase().includes(val)){
                    return true;
                }
            }
            if ( (l.state !== undefined & l.state !== null)){
                if (l.state.toLowerCase().includes(val)){
                    return true;
                }
            }
        });
        matches.sort((a,b) => a.display_name - b.display_name);
        setDisplayLocations(matches);
    }

    const toggleSelected = (id) => {
        // console.log("toggle", id);
        // Is id already selected?
        let isSelected = displaySelected.map(x => x.id).includes(id);
        let _selected = [...displaySelected];

        if (isSelected){
            // Don't want it anymore
            _selected = _selected.filter(x => x.id != id);
            // console.log(_selected);
        } else {
            // Try to add it
            if (_selected.length >= MAX_LOCATIONS){
                // window.alert(`Can have at most ${MAX_LOCATIONS}, please de-select something else first`);
                setAlertText(`Can have at most ${MAX_LOCATIONS} locations at a time, please de-select something first`);
                setAlertOpen(true);
                return;
            } else {
                // Have room
                _selected.push(options.filter(x => x.id == id)[0]);
            }
        }

        setDisplaySelected(_selected);
    }

    const accept = () => {
        // console.log("Accepting");
        if (displaySelected.length == 0){
            // window.alert("Please select at least one location");
            setAlertText("Please select at least one location");
            setAlertOpen(true);
            return;
        }
        setSelected(displaySelected);
        setOpen(false);
    }

    return (
        <>
            <div>
                <span className='text-xs text-gray-600'>Locations</span>
                <br/>
                <button 
                    onClick={handleClickOpen}
                    className='btn bg-white focus:outline-none border border-gray-200'>
                    {selected.length} Selected
                </button>
            </div>

            <Dialog
                fullWidth={true}
                maxWidth='md'
                open={open}
                TransitionComponent={Transition}
                onClose={handleClose}
                keepMounted={false}>
                <DialogTitle>
                    Select Locations
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You may select up to 10 locations at a time
                    </DialogContentText>
                    <div className='grid grid-cols-6 gap-2'>
                        <input
                            type='text'
                            placeholder='Filter Locations by Address or Nickname'
                            className='col-span-5 p-2 m-y-1 rounded-lg border border-gray-400'
                            onChange={filterLocations}/>
                        <button 
                            onClick={() => setDisplaySelected([])}
                            className='col-span-1 btn border border-purple-500 text-purple-500 hover:bg-purple-500 hover:text-white'>
                            De-Select All
                        </button>
                    </div>
                    
                    <table className='table-auto w-full mt-2'>
                        <thead>
                            <tr className='bg-gray-400 text-white rounded-sm text-left'>
                                <th className='p-1'>Selected</th>
                                <th className='p-1'>Name</th>
                                <th className='p-1'>Address</th>
                                <th className='p-1'>City</th>
                                <th className='p-1'>Zip Code</th>
                                <th className='p-1'>State</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                displayLocations.map(loc => {
                                    const isSelected = displaySelected.map(x => x.id).includes(loc.id);
                                    return (
                                        <tr 
                                            onClick={() => toggleSelected(loc.id)}
                                            className={`even:bg-gray-100 cursor-pointer ${isSelected ? 'hover:bg-red-300' : 'hover:bg-green-300'}`} 
                                            key={`row-id-${loc.id}`}>
                                            <td>{isSelected ? <>&#10003;</> : null}</td>
                                            <td>{loc.display_name}</td>
                                            <td>{loc.address}</td>
                                            <td>{loc.city}</td>
                                            <td>{loc.zipcode}</td>
                                            <td>{loc.state}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </DialogContent>
                <DialogActions>
                    <button 
                        onClick={accept}
                        className='btn border border-green-500 text-green-500 hover:bg-green-500 hover:text-white rounded-sm'>
                        Accept
                    </button>
                    <button
                        onClick={handleClose}
                        className='btn border border-red-500 text-red-500 hover:bg-red-500 hover:text-white rounded-sm'>
                        Cancel
                    </button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={alertOpen}
                onClose={() => setAlertOpen(false)}>
                <DialogTitle>
                    Ooops!
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {alertText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button 
                        onClick={() => setAlertOpen(false)}
                        className='btn border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white'>
                        Close
                    </button>
                </DialogActions>
            </Dialog>
        </>
    )
}