import React, { useState, useEffect } from 'react';

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import SettingsSidebar from '../partials/settings/SettingsSidebar';

import AccountSettings from '../partials/settings/AccountSettings';
import LocationsSettings from '../partials/settings/LocationsSettings';
import { analytics } from '../firebase';
import DriveThruSettings from '../partials/settings/DriveThruSettings';

function Settings() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [activeSetting, setActiveSettings] = useState('account');

  useEffect(() => {
    analytics.logEvent('settings_page');
  }, []);

  const renderSettingsContent = () => {
    if (activeSetting === 'account') {
      return <AccountSettings />;
    } else if (activeSetting === 'locations') {
      return <LocationsSettings />;
    } else if (activeSetting === 'drivethru') {
      return <DriveThruSettings />;
    } else {
      return <AccountSettings />;
    }
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          title="Settings"
        />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Content */}
            <div className="bg-white shadow-lg rounded-sm mb-8">
              <div className="flex flex-col md:flex-row md:-mr-px">
                <SettingsSidebar
                  active={activeSetting}
                  setActive={setActiveSettings}
                />
                <div className="flex-grow">
                  <div className="p-6 space-y-6">{renderSettingsContent()}</div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Settings;
