
import React, { useState, useEffect } from "react";
import { useDataContext } from "../../contexts/DataContext";
import { DateTime } from "luxon";
// import ReactLoading from 'react-loading';
import Loader from "../../utils/Loader";
import app from '../../firebase';
import { dateToString, formatTime } from "./analyticsHelpers";
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	Label,
	ResponsiveContainer,
} from "recharts";
import MultiSelectDropdownMenu from "../actions/MultiSelectDropdown";
import DropdownMenu from "../actions/DropdownMenu";
import { KeyboardReturnOutlined } from "@material-ui/icons";

export default function DayPartComparisonWidget({
	selectedLocations,
	dtToggle,
	period1,
	period2,
	periodToggle,
}){

	// States
	const [graphLocation, setGraphLocation] = useState();
	// const [regionData, setRegionData] = useState();
	const [dayPartData, setDayPartData] = useState();
	const [selectedDayParts, setSelectedDayParts] = useState();
	// const [selectedRegions, setSelectedRegions] = useState();
	const [locationData, setLocationData] = useState();
	const [period1Data, setPeriod1Data] = useState();
	const [period2Data, setPeriod2Data] = useState();
	const [legendDict, setLegendDict] = useState({
		p1: "Period 1",
		p2: "Period 2",
	});

	// Context
	const { routes, getCacheData, setCache } = useDataContext();


	const propsLoaded = () => {
		return (dtToggle != undefined 
			// && graphLocation != undefined
			// && selectedDayParts != undefined
			&& selectedLocations != undefined
			&& dayPartData != undefined
			&& period1 != undefined
			&& period2 != undefined);
	}

	/**
	 * When key data parameters change, querry for the data
	 */
	useEffect(async () => {
		if (!propsLoaded()){return}
		// let p1d = await querryData(period1);
		// let p2d = await querryData(period2);
		let [p1d, p2d] = await Promise.all([querryData(period1), querryData(period2)]);


		setPeriod1Data(p1d);
		setPeriod2Data(p2d);
	// }, [period1, period2, dtToggle, graphLocation, selectedDayParts]);
	}, [period1, period2, dtToggle, selectedLocations, dayPartData]);

	const querryData = async (p) => {
		// Cache stuff
		let key = `comparison-dayparts-graph-${dateToString(p[0])}-${dateToString(p[1])}-${dtToggle}-${graphLocation.id}-${dayPartData.map(x => x.name).join('-')}`;
		// console.log(key);
		let cacheData = getCacheData(key);
		if ((cacheData != undefined)){
			return cacheData;
		}

		let params = {
			start_date: dateToString(p[0]),
			end_date: dateToString(p[1]),
			// locations: [graphLocation.id],
			locations: selectedLocations.map(x => x.id),
			is_drive_through: dtToggle,
			// dayParts: selectedDayParts,
			dayParts: dayPartData.map(x => x.name),
			which: ["day_parts"],
		}

		let url = routes['comparisonData'];
		let data = await fetch(url, {
			method: "POST",
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(params)
		})
		.then(res => res.json())
		.then(data => {
			// console.log(data)
			data = JSON.parse(data['day_parts']);
			// console.log(data);
			setCache(key, data);
			return data;
		})
		.catch((err) => {
			console.warn(err);
			return undefined;
		});

		return data;
	}

	/**
	 * If selected locations change:
	 * - If graphDLocation is undefined (would happen on first load)
	 * 	+ set grpah location to some selected Location
	 * - check to see if graphLocation in new selected regions
	 * 	+ if so, do nothing
	 * - else (i.e., graphLocation is not among possible choices)
	 * 	+ default to a new graph location
	 * 	+ run through data sequence
	 */
	useEffect(() => {
		if (selectedLocations == undefined){return}
		if (graphLocation == undefined){
			setGraphLocation(selectedLocations[0]);
			return;
		}
		if (selectedLocations.map(x => x.id).includes(graphLocation.id)) {
			return;
		}
		else{
			// Update graphLocation
			setGraphLocation(selectedLocations[0]);
		}
	}, [selectedLocations])


	/**
	 * Whenever Graph Location changes we have to get the daypart data, i.e.,
	 * hit firebase
	 */
	useEffect(() => {
		if (graphLocation == undefined){return}
		const db = app.firestore();
		const docRef = db.collection("location").doc(`${graphLocation.id}`);
		docRef.get().then((doc) => {
			let data = doc.data();
			setDayPartData(data['day_part_preferences']);
		});
		
	}, [graphLocation]);


	// Update the Legend Dict
	useEffect(() => {
		let obj = Object.assign({}, legendDict);
		if (period1 != undefined) {
			let d1 = DateTime.fromJSDate(period1[0]);
			let d2 = DateTime.fromJSDate(period1[1]);
			obj["p1"] = `${d1.toLocaleString(
				DateTime.DATE_MED
			)} to ${d2.toLocaleString(DateTime.DATE_MED)}`;
		}
		if (period2 != undefined) {
			let d1 = DateTime.fromJSDate(period2[0]);
			let d2 = DateTime.fromJSDate(period2[1]);
			obj["p2"] = `${d1.toLocaleString(
				DateTime.DATE_MED
			)} to ${d2.toLocaleString(DateTime.DATE_MED)}`;
		}
		setLegendDict(obj);
	}, [period1, period2]);


	/**
	 * When day parts data changes, need to cross check it with the selected day partss
	 * we accept any overlap, and if there is no overlap, we will reset selected
	 * regioons to be all possible new day partss
	 */
	useEffect(() => {
		if (dayPartData == undefined){return}

		// if no selected day parts, default to all
		if (selectedDayParts == undefined || selectedDayParts == []){
			setSelectedDayParts(dayPartData.map(dp => dp.name));
		}
		// else, we have some, so check on overlap
		else{
			// check overlap
			let overlap = selectedDayParts.filter(x => dayPartData.map(x => x.name).includes(x));

			// If there is overlap, all set, set it
			if (overlap.length > 0){
				setSelectedDayParts(overlap);
			}
			// if no overlap, default to all day parts
			else{
				setSelectedDayParts(dayPartData.map(x => x.name));
			}
		}
	}, [dayPartData])

	const setLocationHelper = (loc) => {
		// Find match in selected
		let match = selectedLocations.filter(x => x.display_name == loc)[0];

		// Set graph location with match
		setGraphLocation(match);
	}

	/**
	 * Takes in a region display name and toggles its option in 
	 * selectedRegions
	 * @param {String} reg region display name
	 */
	const dayPartToggleHelper = (dp) => {
		// console.log(dp);

		// if the key is in selected day parts, remove it
		if (selectedDayParts.includes(dp)){
			setSelectedDayParts(selectedDayParts.filter(x => x != dp));
		}
		// If it is not there, add it
		else{
			setSelectedDayParts((selectedDayParts) => [
				...selectedDayParts, 
				dp
			])
		}
	}

	
	const renderGraph = () => {
		if (period1Data == undefined || period2Data == undefined || dayPartData == undefined){
			return <Loader />
		}

		const getVal = (data, dp) => {
			let match = data.filter((x) => x.day_part == dp && x.location_id == graphLocation.id);
			return match.length == 0 ? 0 : match[0].avg;
		};

		// Organize Graph Data
		let plotData = [];
		let order = dayPartData.map(x => x.name);
		selectedDayParts.sort((a,b) => order.indexOf(a) - order.indexOf(b)).forEach(dp => {
			let obj = {};
			obj["name"] = dp;
			obj['dp_key'] = dp;
			obj['p1'] = getVal(period1Data, dp);
			obj['p2'] = getVal(period2Data, dp);
			plotData.push(obj);
		})
		// console.log(plotData);

		// Make the graph
		return (
			<ResponsiveContainer width="100%" height="100%">
				<BarChart
					width={500}
					height={300}
					data={plotData}
					margin={{
						top: 10,
						right: 30,
						left: 20,
						bottom: 10,
					}}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis
						dataKey="name"
						angle={-20}
						textAnchor="end"
						interval={0}
						tick={{ fontSize: "10px" }}
					/>
					<YAxis
						tickFormatter={(v) => formatTime(v)}
						// domain={[0, "dataMax"]}
						style={{ fontSize: "10px" }}
						tickCount={10}>
						<Label
							value={"Average Service Time"}
							position="left"
							angle={-90}
							style={{ textAnchor: "middle", fontSize: "12px" }}
						/>
					</YAxis>
					<Tooltip
						formatter={(val, name, props) => [
							formatTime(val),
							legendDict[name],
						]}
						wrapperStyle={{fontSize:'12px', opacity:'.95'}}
					/>
					<Legend
						formatter={(val, entry, index) => legendDict[val]}
						verticalAlign="top"
						wrapperStyle={{ fontSize: "12px", top:'-.1px' }}
					/>
					{
						periodToggle == 'both' || periodToggle == 'period1' ? 
						<Bar dataKey="p1" fill="#54BFF0" /> : null
					}
					{
						periodToggle == 'both' || periodToggle == 'period2' ? 
						<Bar dataKey="p2" fill="#FFA695" /> : null
					}
					{/* <Bar dataKey="p1" fill="#54BFF0" />
					<Bar dataKey="p2" fill="#FFA695" /> */}
				</BarChart>
			</ResponsiveContainer>
		);


	}


 	return (
		<div className="bg-white rounded-lg shadow-lg p-4 w-full">
			{/* <div className='flex flex-wrap justify-between'> */}
			<div className="sm:flex sm:flex-wrap sm:justify-between grid grid-cols-1 gap-2">
				<div>
					Day Parts
				</div>
				<div>
					<DropdownMenu
						options={selectedLocations == undefined ? [] : selectedLocations.map(x => x.display_name)}
						setOptions={setLocationHelper}
						selected={graphLocation == undefined ? null : graphLocation.display_name}
						/>
				</div>
				<div>
					<MultiSelectDropdownMenu
						options={dayPartData == undefined ? [] : dayPartData.map(x => x.name)}
						toggleOption={dayPartToggleHelper}
						selected={selectedDayParts || []}
						label='Day Parts'
						/>
				</div>
			</div>
			<div className='h-96'>{renderGraph()}</div>
		</div>
	)
}