/**
   ______                                ____        __  _                 
  / ____/___ _____ ___  ___  _________ _/ __ \____  / /_(_)___  ____  _____
 / /   / __ `/ __ `__ \/ _ \/ ___/ __ `/ / / / __ \/ __/ / __ \/ __ \/ ___/
/ /___/ /_/ / / / / / /  __/ /  / /_/ / /_/ / /_/ / /_/ / /_/ / / / (__  ) 
\____/\__,_/_/ /_/ /_/\___/_/   \__,_/\____/ .___/\__/_/\____/_/ /_/____/  
										  /_/                              
 * Partial Component that Handles camera options, selecting location,
 * the date and time, as well as the video download
 */

import React, { useState } from 'react';
import DropdownMenu from '../actions/DropdownMenu';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { DateTime } from 'luxon';
import VideoDownload from './VideoDownloadModal';
import { useDataContext } from '../../contexts/DataContext';

import Flatpickr from 'react-flatpickr';
import confirmDate from 'flatpickr/dist/plugins/confirmDate/confirmDate';

import CamerasPerPage from './CamerasPerPage';
import CameraLocationSelect from './CameraLocationSelect';
import CVToggleButton from './CVToggleButton';

import { useHotkeys } from 'react-hotkeys-hook';

const mobileCheck = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export default function CameraOptions(props) {
  const { oldLocations } = useDataContext();
  const flatpickrOptions = {
    mode: 'single',
    enableTime: true,
    static: true,
    monthSelectorType: 'static',
    dateFormat: 'M j, Y h:i K',
    defaultDate: props.videoDateTime,
    disableMobile: true,
    plugins: [new confirmDate({ confirmText: 'Confirm' })],
    prevArrow:
      '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
    nextArrow:
      '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
  };

  const [showingCalendar, setShowingCalendar] = useState(false);
  const [showCVSelect, setShowCVSelect] = useState(false);

  // I THINK I NEED A USE CALLBACK
  useHotkeys('ctrl+c+v', () => setShowCVSelect(!showCVSelect), [
    showCVSelect,
    setShowCVSelect,
  ]);

  const toggleLive = () => {
    if (oldLocations.indexOf(props.currentLocation.id) === -1) {
      // If this is a new location, just toggle lice
      // Better way for crenshaw
      props.setIsLive(!props.isLive);
      return;
    }

    if (props.isLive) {
      // Go back to yesterday and call it a day
      props.setIsLive(!props.isLive);
      let d = new Date();
      d.setDate(d.getDate() - 1);
      d.setHours(12);
      d.setMinutes(30);
      props.setVideoDateTime(d);
      return;
    }

    // Get time zone of currentl ocation
    let tz = props.currentLocation['time_zone'];
    let ldate = DateTime.now();
    let local = ldate.setZone(tz);

    // HACK FOR DUNKIN LIVE
    if (props.currentLocation.id === 19) {
      local = local.minus({ minutes: 10 });
    } else {
      local = local.minus({ minutes: 5 });
    }

    let iso = local.toISO().split('-').slice(0, 3).join('-');
    let localJS = new Date(iso);

    props.setVideoDateTime(localJS);
    props.setIsLive(!props.isLive);
    return;
  };

  const renderCameraSelect = () => {
    // if one camera, this is moot
    if (props.cameras.length === 1) {
      return <></>;
    }

    // Else make a dropdown menu with the camera options
    let options = ['All Cameras'].concat(
      props.cameras.map((x) => `Camera ${x}`)
    );
    let selected;
    if (props.setSelectedCameras.length > 1) {
      selected = 'All Cameras';
    } else {
      // There is one camera, so decide if desired single view or implicit
      selected = props.singleView
        ? 'Camera ' + props.selectedCameras[0]
        : 'All Cameras';
    }
    return (
      <div className="m-3 flex justify-center">
        <DropdownMenu
          options={options}
          setOptions={internalSetSelectedCameras}
          selected={selected}
        />
      </div>
    );
  };

  const internalSetSelectedCameras = (option) => {
    console.log('Hopefully setting to', option);
    if (option === 'All Cameras') {
      props.setSingleView(false);
      props.setSelectedCameras(props.cameras);
    } else {
      let camNum = parseInt(option.replace('Camera ', ''));
      props.setSelectedCameras([camNum]);
      console.log('This is where we would set a singleView flag');
      props.setSingleView(true);
    }
  };

  const onDateTimeChangeFlatPickr = (newDate) => {
    let d = new Date(newDate[0]);
    props.setVideoDateTime(d);
    props.setIsLive(false);
    setShowingCalendar(false);
  };

  return (
    <>
      <div className="flex flex-wrap flex-col sm:flex-row justify-center sm:justify-start w-full">
        <div className="m-3 flex justify-center">
          <CameraLocationSelect
            allLocations={props.customerLocations}
            currentLocation={props.currentLocation}
            setCurrentLocation={props.setCurrentLocation}
          />
        </div>

        {renderCameraSelect()}

        <div className={mobileCheck() ? 'hidden' : ''}>
          <CamerasPerPage
            numSlots={props.numSlots}
            setNumSlots={props.setNumSlots}
            allCameras={props.cameras}
          />
        </div>

        <div className="m-3 flex justify-center">
          <div className="relative">
            <Flatpickr
              className="form-input pl-9 text-gray-500 hover:text-gray-600 font-medium focus:border-gray-300"
              options={flatpickrOptions}
              onClose={onDateTimeChangeFlatPickr}
              onOpen={() => setShowingCalendar(true)}
              value={props.videoDateTime}
            />
            <div className="absolute inset-0 right-auto flex items-center pointer-events-none">
              <svg
                className="w-4 h-4 fill-current text-gray-500 ml-3"
                viewBox="0 0 16 16"
              >
                <path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
              </svg>
            </div>
            <div
              className={`absolute inset-0 left-auto flex items-center cursor-pointer ${
                showingCalendar ? '' : 'hidden'
              }`}
            >
              <CheckCircleIcon size="small" />
            </div>
          </div>
        </div>

        <div className="m-3 flex justify-center">
          <button
            className={`btn 
							focus:outline-none
							sm:w-auto
							w-6/12
							${props.isLive ? 'text-white' : 'text-red-500'}
							${props.isLive ? 'bg-red-500' : ''}
							border-red-100`}
            onClick={toggleLive}
          >
            Live
          </button>
        </div>

        <div
          className={`m-3 ${mobileCheck() ? 'hidden' : 'flex'} justify-center`}
        >
          <VideoDownload
            customerLocations={props.customerLocations}
            currentLocation={props.currentLocation}
            videoDateTime={props.videoDateTime}
          />
        </div>

        {props.hasCVStream && showCVSelect ? (
          <div className="m-3 justify-center">
            <CVToggleButton
              which="lobby"
              showStream={props.showCVStream}
              setShowStream={props.setShowCVStream}
            />
          </div>
        ) : null}

        {props.hasDTCVStream && showCVSelect ? (
          <div className="m-3 justify-center">
            <CVToggleButton
              which="dt"
              showStream={props.showDTCVStream}
              setShowStream={props.setShowDTCVStream}
            />
          </div>
        ) : null}
      </div>
    </>
  );
}
