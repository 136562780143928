/**
 * This widget takes all the comparison locations, and shows a bar chart
 * for each location (on the x axis) with grouped bars (one bar for each period)
 * for their total average servivce time
 *
 * This widget has no further selections to be made
 */

import React, { useState, useEffect } from "react";
import { useDataContext } from "../../contexts/DataContext";
import { DateTime } from "luxon";
import { dateToString, formatTime } from "./analyticsHelpers";
import Loader from "../../utils/Loader";
import DropdownMenu from "../actions/DropdownMenu";
import app from "../../firebase";
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	Label,
	ResponsiveContainer,
} from "recharts";

export default function DayPartsLocationsWidget({
	selectedLocations,
	dtToggle,
	period1,
	period2,
	periodToggle,
}) {
	const [period1Data, setPeriod1Data] = useState();
	const [period2Data, setPeriod2Data] = useState();
    // const [locationsData, setLocationsData] = useState();
	const [allDayParts, setAllDayParts] = useState();
	const [selectedDayPart, setSelectedDayPart] = useState();
	const [legendDict, setLegendDict] = useState({
		p1: "Period 1",
		p2: "Period 2",
	});
	const [colorDict, setColorDict] = useState({});
	const colors = ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf' ];


	const { routes, getCacheData, setCache } = useDataContext();

    const propsLoaded = () => {
		return (dtToggle != undefined 
			&& selectedLocations != undefined
            && allDayParts != undefined
			// && selectedDayPart != undefined
			&& period1 != undefined
			&& period2 != undefined);
	}

	/**
	 * When key data parameters change, querry for the data
	 */
	useEffect(async () => {
		if (!propsLoaded()){return}
		let [p1d, p2d] = await Promise.all([querryData(period1), querryData(period2)]);


		setPeriod1Data(p1d);
		setPeriod2Data(p2d);
	}, [period1, period2, dtToggle, selectedLocations, allDayParts]);


    // Whenever Locations Change, get new day parts
    useEffect(async () => {
        if (selectedLocations == undefined){return}
        const db = app.firestore();
        let _data = [];
        let e = 0;
        while (e < selectedLocations.length){
            let locationQuery = await db.collection('location')
                .where('location_id', 'in', selectedLocations.slice(e, e+10).map(x => x.id))
                .get();
            locationQuery.forEach(doc => _data.push(doc.data()));
            e = e + 10;
        }
        // setLocationsData(_data);

        // No snag out just the day parts
        let _allDayParts = _data.reduce((acc, cur) => {
            let cdp = cur.day_part_preferences;
            cdp.forEach(dp => {
				if (acc.indexOf(dp.name) == -1){
					acc.push(dp.name);
				}
			});
			return acc;
        }, []);
		// console.log(_allDayParts);
		// setSelectedDayParts(_allDayParts);
		setAllDayParts(_allDayParts);


		// update colors
		// updateColors();

    }, [selectedLocations])


	/**
	 * When all of the day parts change, make sure selected one updates
	 * if it has to
	 */
	useEffect(() => {
		if (allDayParts == undefined){return}
		if (!allDayParts.includes(selectedDayPart)){
			setSelectedDayPart(allDayParts[0])
		}
	}, [allDayParts])



	const updateColors = () => {
		// check if any of the regions are NOT in the color dict
		let notIn = [];
		selectedLocations.forEach(loc => {
			if (!Object.keys(colorDict).includes(loc.id)){
				notIn.push(loc.id);
			}
		});
		if (notIn.length == 0){return}
		// Add any that are not there
		let newColorDict = Object.assign({}, colorDict);
		notIn.forEach(key => {newColorDict[key] = colors[Object.keys(newColorDict).length + 1 % colors.length]});
		setColorDict(newColorDict);
	}

	// Update the Legend Dict
	useEffect(() => {
		let obj = Object.assign({}, legendDict);
		if (period1 != undefined) {
			let d1 = DateTime.fromJSDate(period1[0]);
			let d2 = DateTime.fromJSDate(period1[1]);
			obj["p1"] = `${d1.toLocaleString(
				DateTime.DATE_MED
			)} to ${d2.toLocaleString(DateTime.DATE_MED)}`;
		}
		if (period2 != undefined) {
			let d1 = DateTime.fromJSDate(period2[0]);
			let d2 = DateTime.fromJSDate(period2[1]);
			obj["p2"] = `${d1.toLocaleString(
				DateTime.DATE_MED
			)} to ${d2.toLocaleString(DateTime.DATE_MED)}`;
		}
		setLegendDict(obj);
	}, [period1, period2]);

	/**
	 * Querry for the appropriate data
	 */
	const querryData = async (p) => {
		// Check cache first
		let key = `comparison-dayPartsAllLocations-graph-${dateToString(p[0])}-${dateToString(p[1])}-${dtToggle}-${selectedLocations.map(x => x.id).join('-')}-${selectedDayPart}`;
		let cacheData = getCacheData(key);
		if ((cacheData != undefined)){
			return cacheData;
		}


		let params = {
			start_date: dateToString(p[0]),
			end_date: dateToString(p[1]),
			locations: selectedLocations.map((x) => x.id),
			is_drive_through: dtToggle,
            day_parts: allDayParts,
			which: ["day_parts"],
		};
		// console.log(params);
		let url = routes["comparisonData"];
		let data = await fetch(url, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(params),
		})
			.then((res) => res.json())
			.then((data) => {
				let dat = JSON.parse(data["day_parts"]);
				// console.log(dat);
				// save to cache
				setCache(key, dat);

				return dat;
			})
			.catch((err) => {
				console.warn(err);
				return undefined;
			});

		return data;
	};

	

	const renderGraph = () => {
		// console.log('p1', period1Data);
		// console.log('p2', period2Data);
		if (period1Data == undefined || period2Data == undefined) {
			return <Loader type='bars'/>
		}

		const getVal = (data, lid, dp) => {
			let match = data.filter((x) => x.location_id == lid && x.day_part == dp);
			return match.length == 0 ? 0 : match[0].avg;
		};

		// Organize Data for Plotting
		let plotData = [];
		selectedLocations.forEach((loc) => {
		// allDayParts.forEach(dp => {
			let obj = {};
			obj["name"] = loc.display_name;
			obj[`p1`] = getVal(period1Data, loc.id, selectedDayPart)
			obj[`p2`] = getVal(period2Data, loc.id, selectedDayPart)

			plotData.push(obj);
		});
		// console.log(plotData);

		// Make the graph
		return (
			<ResponsiveContainer width="100%" height="100%">
				<BarChart
					width={500}
					height={300}
					data={plotData}
					margin={{
						top: 10,
						right: 30,
						left: 20,
						bottom: 10,
					}}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis
						dataKey="name"
						angle={-20}
						textAnchor="end"
						interval={0}
						tick={{ fontSize: "10px" }}
					/>
					<YAxis
						tickFormatter={(v) => formatTime(v)}
						style={{ fontSize: "10px" }}
						tickCount={10}>
						<Label
							value={"Average Service Time"}
							position="left"
							angle={-90}
							style={{ textAnchor: "middle", fontSize: "12px" }}
						/>
					</YAxis>
					<Tooltip
						formatter={(val, name, props) => [
							formatTime(val),
							legendDict[name],
						]}
						wrapperStyle={{fontSize:'12px', opacity:'.95'}}
					/>
					<Legend
						formatter={(val, entry, index) => legendDict[val]}
						verticalAlign="top"
						wrapperStyle={{ fontSize: "12px", top:'-.1px' }}
					/>
					{
						periodToggle == 'both' || periodToggle == 'period1' ? 
						<Bar dataKey="p1" fill="#54BFF0" /> : null
					}
					{
						periodToggle == 'both' || periodToggle == 'period2' ? 
						<Bar dataKey="p2" fill="#FFA695" /> : null
					}
					{/* <Bar dataKey="p1" fill="#54BFF0" />
					<Bar dataKey="p2" fill="#FFA695" /> */}
				</BarChart>
			</ResponsiveContainer>
		);
	};


	return (
		<div className="bg-white rounded-lg shadow-lg p-4 w-full">
			<div className='flex flex-wrap justify-between'>
				<div>
					Day Parts Across Locations
				</div>
				<div>
					<DropdownMenu
						options={allDayParts == undefined ? [] : allDayParts}
						setOptions={(dp) => setSelectedDayPart(dp)}
						selected={selectedDayPart == undefined ? null : selectedDayPart}
						/>
				</div>
			</div>
			<div className='h-96'>{renderGraph()}</div>
		</div>
	);
}
