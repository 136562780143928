import React from 'react';
import DropdownMenu from '../actions/DropdownMenu';

export default function FranchiseSelector({franchises, franchise, setFranchise}){


    /**
     * Helper to set the franchise, finds the franchise object that matches
     * the given  name then sets it with the set prop
     * @param {String} name 
     */
    const setHelper = (name) => {
        // Find the match
        let match = franchises.filter(x => x.name == name)[0];

        // Set it
        setFranchise(match);
    }

    if ( (franchises == undefined) || (franchises.length == 1) || (franchise == undefined)){
        return null;
    }
    // Else we have franchises to choose from
    return(
        <div>
            <span className='text-xs text-gray-600'>Franchise</span><br/>
            <DropdownMenu
                options={franchises.map(x => x.name)}
                setOptions={setHelper}
                selected={franchise.name}
                />
        </div>
    )
}