

const getCustomerLocations = async ({queryKey}) => {
    // console.log(queryKey);
    const [_key, uid, url] = queryKey;
    let params = {
        'uid': uid
    }

    const response = await fetch(url, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(params)
    });
    if (!response.ok){
        throw new Error("Unable to get customer locations");
    }
    let data = response.json();
    return data;
}

export {
    getCustomerLocations as getCustomerLocations
}