/**
    ___         __  __    ______            __            __ 
   /   | __  __/ /_/ /_  / ____/___  ____  / /____  _  __/ /_
  / /| |/ / / / __/ __ \/ /   / __ \/ __ \/ __/ _ \| |/_/ __/
 / ___ / /_/ / /_/ / / / /___/ /_/ / / / / /_/  __/>  </ /_  
/_/  |_\__,_/\__/_/ /_/\____/\____/_/ /_/\__/\___/_/|_|\__/  
                                                             
 * 
 * Kenneth Lipke
 * Context for Authorization, relies heavily on a great youtube tutorial: 
 * 	https://www.youtube.com/watch?v=PKwu15ldZ7k
 * This holds all the helper functions to login, logout, signup
 * change password, change email, etc. 
 * Everywhere, except the white list, it leans totally on firebase sdk functions
 */

import React, { useContext, useState, useEffect } from 'react';
import app, { auth } from '../firebase';

const AuthContext = React.createContext();

// Needed for class components
export default AuthContext;

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    return auth.signOut();
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  async function checkWhiteList(email) {
    let whitelistUrl =
      'https://hellometer.appspot.com/whitelist/check_email_' + email;
    let res = await fetch(whitelistUrl);
    let data = await res.json();
    return data;
  }

  /**
   * Creates a Firestore entry in the customer colletion for the given user id
   * if they do not already have one
   */
  function createFirestoreCustomerEntry(uid) {
    console.log(uid);
    const db = app.firestore();
    let docRef = db.collection('customer').doc(uid);

    docRef.get().then((docSnapshot) => {
      if (docSnapshot.exists) {
        return;
      } else {
        docRef
          .set({
            locations: [],
            lookback: 'yesterday',
            lookbrack_from: null,
            lookback_to: null,
            primary_location: null,
            token_id: null,
            unit: 60,
          })
          .then(() => console.log('User Created'))
          .catch((error) => console.warn(error));
      }
    });
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      await setLoading(false); // have user done loading
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    signup,
    login,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    checkWhiteList,
    createFirestoreCustomerEntry,
  };

  // Only if not loading do we want to render the chidlren
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
