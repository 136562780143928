import React, { useState, useEffect } from 'react';
import { useDataContext } from "../../contexts/DataContext";
import { DateTime } from "luxon";
// import ReactLoading from 'react-loading';
import Loader from "../../utils/Loader";
import app from '../../firebase';
import { dateToString, formatTime } from "./analyticsHelpers";

import ReactHTMLTableToExcel from 'react-html-table-to-excel';


export default function ComparisonTableWidget({
	selectedLocations,
	period1,
	period2,
	dtToggle
}){

	// States
	const [regionData, setRegionData] = useState(); // just the region names
	const [dayPartData, setDayPartData] = useState(); // just the day part names
	const [locationsData, setLocationsData] = useState(); // this is the location firebase objects
	const [period1Data, setPeriod1Data] = useState();
	const [period2Data, setPeriod2Data] = useState();

	// Context
	const { routes, getCacheData, setCache } = useDataContext();

	/**
	 * Whenever the locations, DT, periods change we need to request new data
	 * .... do we also need to watch out for regions and day parts, or will
	 * these also be covered in locations and dt?
	 */
	useEffect( async () => {
		if (selectedLocations != undefined 
			&& dtToggle != undefined
			&& period1 != undefined
			&& period2 != undefined
			// && regionData != undefined
			&& dayPartData != undefined){
				setPeriod1Data(undefined);
				setPeriod2Data(undefined);

				// let p1d = await queryData(period1);
				// let p2d = await queryData(period2);

				// maybe try an async call to update regions here?
				let regions = await updateRegions();
				setRegionData(regions);

				let [p1d, p2d] = await Promise.all([queryData(period1, regions), queryData(period2, regions)])
				// console.log("About to set p1 to", p1d);
				// console.log("About to set p2 to", p2d);
				setPeriod1Data(p1d);
				setPeriod2Data(p2d);
			}
	// }, [selectedLocations, dtToggle, period1, period2, regionData, dayPartData])
	}, [selectedLocations, dtToggle, period1, period2, dayPartData])

	/**
	 * When locations change, get new firebase data
	 */
	useEffect(() => {
		// Get firebase data
		updateFBData();
	}, [selectedLocations]);

	/**
	 * Whenever locations firebase data (locationsData) changes, make sure
	 * we update the day parts and regions
	 */
	useEffect(() => {
		// setRegionData(undefined);
		// updateRegions();
		updateDayParts();
	}, [locationsData])

	/**
	 * When dt change, no need to call fb for new data, but 
	 * make sure the regions get updated
	 */
	// useEffect(() => {
	// 	setRegionData(undefined);
	// 	updateRegions();
	// }, [dtToggle])


	/**
	 * Looks at locationsData (firebase) and updates selectedRegions
	 * to reflect regions according to the data and dtToggle
	 */
	const updateRegions = () => {
		if (dtToggle == undefined || locationsData == undefined){return}
		// Get ALL regions
		let whichKey = dtToggle ? 'drive_thru_regions2' : 'lobby_regions2';
		let _regions = locationsData.reduce((acc, cur) => {
			let regions = cur[whichKey];
			if (regions == undefined){return acc}
			regions.forEach(reg => {
				if (!acc.map(x => x.key).includes(reg.key)){
					acc.push(reg)
				}
			});
			return acc
		}, []);


		return _regions;
		// save selected regions
		// setRegionData(_regions);

		// return 'yes';
	}

	const updateDayParts = () => {
		if (locationsData == undefined){return}
		// Get ALL day parts
		let _dayParts = locationsData.reduce((acc, cur) => {
			// console.log(cur);
			let dps = cur.day_part_preferences;
			if (dps == undefined){return acc}
			dps.forEach(dp => {
				if (!acc.map(x => x.name).includes(dp.name)){
					acc.push(dp)
				}
			});
			return acc
		}, []);
		
		// save selected _dayParts
		setDayPartData(_dayParts);
	}
	

	const updateFBData = async () => {
		if (selectedLocations == undefined){return}
		const db = app.firestore();
		let _data = [];
		let e = 0;
		while (e < selectedLocations.length){
			let locationQuery = await db.collection('location')
				.where('location_id', 'in', selectedLocations.slice(e, e+10).map(x => x.id))
				.get();
			locationQuery.forEach(doc => _data.push(doc.data()));
			e = e + 10;
		}
		setLocationsData(_data);
	}

	const queryData = async (p, regions) => {
		// cache stuff
		// let key = `comparison-table-${dateToString(p[0])}-${dateToString(p[1])}-${dtToggle}-${selectedLocations.map(x => x.id).join('-')}-${regionData.map(x => x.key).join('-')}-${dayPartData.map(x => x.name).join('-')}-${selectedLocations.map(x => x.id).join('-')}`;
		let key = `comparison-table-${dateToString(p[0])}-${dateToString(p[1])}-${dtToggle}-${selectedLocations.map(x => x.id).join('-')}-${regions.map(x => x.key).join('-')}-${dayPartData.map(x => x.name).join('-')}-${selectedLocations.map(x => x.id).join('-')}`;
		// console.log("key", key);
		let cacheData = getCacheData(key);
		if (cacheData != undefined){
			return cacheData;
		}

		// if no regions, or no day parts, nothing to show
		// if (regionData.length == 0 || dayPartData.length == 0){
		// 	return [];
		// }
		if (regions.length == 0 || dayPartData.length == 0){
			return [];
		}

		// Setup Params
		let params = {
			start_date: dateToString(p[0]),
			end_date: dateToString(p[1]),
			locations: selectedLocations.map(x => x.id),
			is_drive_through: dtToggle,
			// regions: regionData.map(x => x.key),
			regions: regions.map(x => x.key),
			day_parts: dayPartData.map(x => x.name),
			which: ["full"],
		}

		// console.log(params);

		let url = routes['comparisonData'];
		let data = await fetch(url, {
			method: "POST",
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(params)
		})
		.then(res => res.json())
		.then(data => {
			data = JSON.parse(data['full']);
			// console.log(data);
			setCache(key, data);
			return data;
		})
		.catch((err) => {
			console.warn(err);
			return undefined;
		});

		return data;
	}


	const renderTable = () => {
		
		if (period1Data == undefined || period2Data == undefined || locationsData == undefined 
			|| regionData == undefined || dayPartData == undefined){
			return (
				<div className='h-60 items-center'
					key={'table-loader'}>
					<Loader />
				</div>
			)
		}
		// console.log("Period1 Data", period1Data);
		// console.log("Period2 Data", period2Data);
		// console.log("Locations Data", locationsData);
		// console.log("regionData", regionData);
		// console.log("dayPartData", dayPartData);

		const getRowData = (lid, region) => {
			let dataArray = [period1Data, period2Data]
			const getVal = (data, lid, region, day_part) => {
				let match = data.filter(x => x.location_id == lid && x.region == region.key && x.day_part == day_part);
				
				if (match.length == 0){
					// no match
					return "--"
				}
				else{
					return formatTime(match[0].avg);
				}
			}
			return (
				<>
				<td key={`${lid}-${region.key}`}><i>{region.display_name}</i></td>
				{
				dataArray.map((data, i) => {
					return (
						<>
							{
								dayPartData.map(dp => {
									return (
										<td key={`${lid}-${region}-${dp.name}`}>
											{getVal(data, lid, region, dp.name)}
										</td>
									)
								})
							}
						<td key={`empty-cell-${i}`}></td>
						</>
					)
				})
				}
				</>
			)
		}


		// Make this monster table
		return (
			<>
			<div className='flex justify-end'>
				{/* <div>
					Data
				</div> */}
				<ReactHTMLTableToExcel
					id='table-to-xlsx-button'
					className='btn text-xs  p-1 text-gray-400 hover:border-gray-400'
					table='full-data-table'
					filename='comparison_data'
					sheet='data'
					buttonText='Download'
					/>
			</div>
			<table id='full-data-table' className='w-full text-xs border-collapse min-w-101'>
				{/* <colgroup>
					<col />
					<col span={dayPartData.length} style={{'border': '2px solid red'}} />
					<col />
					<col span={dayPartData.length} style={{'border': '2px solid green'}} />
				</colgroup> */}
				<thead>
					<tr>
						<th>
					
						</th>
						<th></th>
						<th colSpan={dayPartData.length}>
							Period 1
						</th>
						<th></th>
						<th colSpan={dayPartData.length}>
							Period 2
						</th>
					</tr>
					<tr className='text-left'>
						<th></th>
						<th></th>
						{
							dayPartData.map(dp => <th key={`th-${dp.name}-p1`}>{dp.name}</th>)
						}
						<th><div className='w-7'></div></th>
						{
							dayPartData.map(dp => <th key={`th-${dp.name}-p2`}>{dp.name}</th>)
						}
					</tr>
				</thead>
				<tbody>
					{
						selectedLocations.map((loc, i) => {
							// const locationFBData = locationsData.filter(x => x.location_id == loc.id)[0];
							let locMatch = locationsData.filter(x => x.location_id == loc.id);
							if (locMatch.length == 0){return null}
							let locationFBData = locMatch[0];
							const regions = locationFBData[dtToggle ? 'drive_thru_regions2' : 'lobby_regions2'];
							if (regions == undefined){return null}
							return (
								<>
								<tr
									className={`${i % 2 == 0 ? 'bg-gray-100' : ''} hover:bg-gray-200`}
									key={`row-${loc.id}`}>
									<th
										rowSpan={regions.length}>
										{loc.display_name}
									</th>
									{getRowData(loc.id, regions[0])}
								</tr>
								{
									regions.slice(1).map(reg => {
										return (
											<tr
												className={`${i % 2 == 0 ? 'bg-gray-100' : ''} hover:bg-gray-200`}
												key={`row-${loc.id}-${reg.key}`}>
												{getRowData(loc.id, reg)}
											</tr>
										)
									})
								}
								</>
							)
						})
					}
				</tbody>

			</table>
			</>
		)
	}


	return (
		<div className='w-full p-4 bg-white rounded-lg shadow-sm'>
			<div className='overflow-auto'>{renderTable()}</div>
		</div>
	)
}