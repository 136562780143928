/**
 * Presentational modal component.
 */

import { Modal, Card } from '@mui/material';

export default function CustomModal({
  children,
  isOpen,
  onClose,
}: {
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  isOpen: boolean;
  onClose: () => void;
}) {
  return (
    <Modal open={isOpen} onClose={onClose} className="grid place-items-center">
      <div className="max-h-96 min-w-lg bg-white shadow-lg rounded-lg border border-gray-200 mb-8 p-8">
        {children}
      </div>
    </Modal>
  );
}
