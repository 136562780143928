
import app from '../firebase';

const getLocationPreferences = async ({queryKey}) => {
    const [_key, location] = queryKey;
    const db = app.firestore();
    let doc = await db.collection("location").doc(location.id.toString()).get();
    let prom
    if (doc.exists){
        let prefs = doc.data();
        prom = new Promise((resolve, reject) => {
            resolve(prefs);
        });
    }
    else{
        throw new Error("Could not reach firestore for location preferences");
    }
    return prom;
}

export {
    getLocationPreferences as getLocationPreferences
}