import React from 'react';
import { useDataContext } from '../../contexts/DataContext';
import { useQuery } from '@tanstack/react-query';
import { getDaysOrders } from '../../api/orders';
import Loader from '../../utils/Loader';
import CountAnimation from './CountAnimation';
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	Tooltip,
	ResponsiveContainer,
} from "recharts";


export default function Orders24Graph({customerLocationData, franchise, location, date}){

    const { routes } = useDataContext();

    const {data: orderData, refetch, isLoading, isFetching} = useQuery(
		['orders', date, true, location, routes['ordersTable']], 
		getDaysOrders, 
		{
			enabled: !!(!!franchise & !!customerLocationData & !!location),
			retry: 0,
			refetchOnWindowFocus: false,
		}
	);


    const renderGraph = () => {
        // Aggregate data by hour
        let hourDict = {};
        for (let i = 0; i<24; i++){
            hourDict[i] = 0;
        }
        orderData.forEach(x => {
            let hour = (new Date(x.timestamp)).getHours();
            hourDict[hour] = hourDict[hour] + x.total;
        });

        // Convert to array 
        let graphData = [];
        Object.keys(hourDict).forEach(h => {
            let s = `${h % 12}${(h < 12 ? 'am' : 'pm')}`;
            if (h == 0){s = '12am'}
            if (h == 12){s = '12pm'}
            let d = {'hour': s, 'val': hourDict[h]};
            graphData.push(d);
        });

        return (
            <ResponsiveContainer width='100%' height='100%'>
                <BarChart
                    width='100%'
                    height='100%'
                    layout='vertical'
                    data={graphData}
                    >
                    <XAxis type='number' hide={true}/>
                    <YAxis 
                        type='category' 
                        dataKey='hour'
                        tick={{'fontSize': '8px'}}/>
                    <Tooltip
                        formatter={(val, name, props) => [
                            `$${val.toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2})}`,
                            'Total'
                        ]}
                        wrapperStyle={{fontSize:'10px', opacity:'.9'}}
                    />
                    <Bar dataKey='val' fill="#54BFF0" layout='vertical'/>
                </BarChart>
            </ResponsiveContainer>
        )
    }

    const renderData = () => {
        if (isLoading || isFetching || !orderData){
            return <Loader />
        }

        // console.log(orderData);
        let total = orderData.reduce((agg, cur) => agg + cur.total, 0);
        orderData.sort((a,b) => b.total - a.total)
        let topOrders = orderData.slice(0, 5);
        return (
            <>
                <h1 className='text-3xl font-extrabold w-full text-center'>
                    {/* ${total.toLocaleString()} */}
                    <CountAnimation value={total} />
                </h1>
                <div className='w-full h-56'>
                    {renderGraph()}
                </div>
            </>
        )
    }


    return (
        <div className="flex flex-col col-span-full sm:col-span-4 bg-white shadow-lg rounded-lg border border-gray-200 mb-8">
            <header className="px-5 py-4 border-b border-gray-100">
                <div>
                    <h2 className='font-semibold text-gray-800 inline'>
                        Today's Take 
                    </h2>
                    
                    <div className='flex flex-wrap items-center justify-center float-right'>
                        {/* {renderDropdown()} */}
                        <button
                            className="button bg-transparent hover:bg-blue-400
                            text-blue-400 hover:text-white rounded-full
                            border border-blue-400 py-1 px-2 text-xs ml-2"
                            style={{'maxHeight': '2.5em'}}
                            onClick={refetch}>
                            Refresh
                        </button>
                    </div>
                </div>
            </header>
            <div className='p-3'>
                <div className='overflow-x-auto'>
                    {renderData()}
                </div>
            </div>
        </div>
    )
}