import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  useLocation,
  BrowserRouter as Router,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './css/style.scss';

import { focusHandling } from 'cruip-js-toolkit';
import { AuthProvider } from './contexts/AuthContext';
import { DataProvider } from './contexts/DataContext';
import { LocationProvider } from './contexts/LocationContext';

// Import pages
import Dashboard from './pages/Dashboard';
import Applications from './pages/Cameras';
import Settings from './pages/Settings';
import Signin from './pages/Signin';
import Signup from './pages/Signup';
import ResetPassword from './pages/ResetPassword';
import PrivateRoute from './PrivateRoute';
import Hidden from './pages/Hidden';
import Analytics from './pages/Analytics';
import POS from './pages/POS';
import Analytics2 from './pages/Analytics2';

function App() {
  const location = useLocation();
  const queryClient = new QueryClient();

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto';
    window.scroll({ top: 0 });
    document.querySelector('html').style.scrollBehavior = '';
    focusHandling('outline');
  }, [location.pathname]); // triggered on route change

  return (
    <Router>
      <AuthProvider>
        <LocationProvider>
          <QueryClientProvider client={queryClient}>
            <DataProvider>
              <Switch>
                <PrivateRoute exact path="/" component={Dashboard} />
                <PrivateRoute
                  exact
                  path="/applications"
                  component={Applications}
                />
                <PrivateRoute exact path="/analytics" component={Analytics} />
                <PrivateRoute exact path="/analytics2" component={Analytics2} />
                <PrivateRoute exact path="/settings" component={Settings} />
                <PrivateRoute exact path="/boxes" component={Hidden} />
                <PrivateRoute exact path="/orders" component={POS} />
                <Route exact path="/signin">
                  <Signin />
                </Route>
                <Route exact path="/signup">
                  <Signup />
                </Route>
                <Route exact path="/reset-password">
                  <ResetPassword />
                </Route>
              </Switch>
            </DataProvider>
          </QueryClientProvider>
        </LocationProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
