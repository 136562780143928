import React, { useState, useEffect } from 'react';
import { useDataContext } from "../../contexts/DataContext";
import {dateToString} from "./analyticsHelpers";
import { useQuery } from '@tanstack/react-query';
import Loader from '../../utils/Loader';
import { getDayPartData, getRegionData } from '../../api/analytics';
import DayPartGraph from './DayPartGraph';


export default function DayPartWidget({
    selectedLocations,
    dtToggle,
    dates,
    colors
}){

    const { routes} = useDataContext();
    const [showTimes, setShowTimes] = useState(true); // if false implies show Volumes


    const {data, refetch, isLoading, isFetching} = useQuery(
        [
            'daypart_trend', 
            selectedLocations.map(x => x.id).join(','),
            dtToggle, 
            dates.map(x => dateToString(x)).join("T"), 
            routes['analyticsDayPartTrends']
        ],
        getDayPartData,
        {
            enabled: !!(!!selectedLocations & (dtToggle != undefined) & !!dates),
            retry: 0,
            refetchOnWindowFocus: false,
            cacheTime: 1000 * 60 * 15,
        }
    );


    return (
        <div className='bg-white rounded-lg shadow-lg p-4 w-full'>
            <div className='w-full'>
                Day Parts
                <span className='float-right'>
                    <button 
                        className={`btn ${showTimes ? "bg-white" : "bg-gray-100"} focus:outline-none border border-gray-200`}
                        onClick={() => setShowTimes(true)}>
                        Times
                    </button>
                    <button 
                        className={`btn ${!showTimes ? "bg-white" : "bg-gray-100"} focus:outline-none border border-gray-200`}
                        onClick={() => setShowTimes(false)}>
                        Volumes
                    </button>
                </span>
            </div>
            {
                isLoading || isFetching ? (
                    <Loader />
                ) : (
                    <DayPartGraph 
                        data={data}
                        colors={colors}
                        showTimes={showTimes}/>
                )
            }
        </div>
    )
}