/**
    ______                       __  ____                                          __
   / ____/___  _________ _____  / /_/ __ \____ ____________      ______  _________/ /
  / /_  / __ \/ ___/ __ `/ __ \/ __/ /_/ / __ `/ ___/ ___/ | /| / / __ \/ ___/ __  / 
 / __/ / /_/ / /  / /_/ / /_/ / /_/ ____/ /_/ (__  |__  )| |/ |/ / /_/ / /  / /_/ /  
/_/    \____/_/   \__, /\____/\__/_/    \__,_/____/____/ |__/|__/\____/_/   \__,_/   
                 /____/                                                              
 * Kenneth Lipke
 * ForgotPassword component used to send reset email
 * to users who forgot their password
 */

import React, { useRef, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import AuthImage from '../assets/hellometer_cover_horiz.png';
import AVSImage from '../assets/avs/avs_cover.png';
import { Link } from 'react-router-dom';

function ResetPassword() {
  const emailRef = useRef<HTMLInputElement>();
  const { resetPassword } = useAuth();
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  /**
   * Handles form submit for Forgot password page
   * takes the email and passes it to AuthContext Firebase helpers
   * to send password reset email
   * @param {Event} e
   */
  async function handleSubmit(e) {
    e.preventDefault(); // prevent form from refreshing

    try {
      setError('');
      setMessage('');
      setLoading(true);
      await resetPassword(emailRef.current.value);

      // Send a success message
      setMessage('Check inbox for further instructions');
    } catch {
      setError(
        'Failed to send reset email, please verify the email is correct'
      );
    }

    setLoading(false);
  }

  const getAuthImage = () => {
    if (window.location.href.includes('avs.hellometer.io')) {
      return AVSImage;
    } else {
      return AuthImage;
    }
  };

  return (
    <main className="bg-white">
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-1/2">
          <div className="max-w-sm mx-auto min-h-screen flex flex-col justify-center px-4 py-8">
            <div className="w-full">
              <h1 className="text-3xl text-gray-800 font-bold mb-6">
                Reset your Password ✨
              </h1>
              {/* Form */}
              {error && (
                <div className="mt-5">
                  <div className="bg-red-100 text-red-600 px-3 py-2 rounded">
                    <svg
                      className="inline w-3 h-3 flex-shrink-0 fill-current mr-2"
                      viewBox="0 0 12 12"
                    >
                      <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                    </svg>
                    <span className="text-sm">{error}</span>
                  </div>
                </div>
              )}
              {message && (
                <div className="mt-5">
                  <div className="bg-green-100 text-green-600 px-3 py-2 rounded">
                    <svg
                      className="inline w-3 h-3 flex-shrink-0 fill-current mr-2"
                      viewBox="0 0 12 12"
                    >
                      <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                    </svg>
                    <span className="text-sm">{message}</span>
                  </div>
                </div>
              )}
              <form onSubmit={handleSubmit}>
                <div className="space-y-4">
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="email"
                    >
                      Email Address <span className="text-red-500">*</span>
                    </label>
                    <input
                      id="email"
                      ref={emailRef}
                      className="form-input w-full"
                      type="email"
                    />
                  </div>
                </div>
                <div className="flex justify-end mt-6">
                  <button
                    disabled={loading}
                    type="submit"
                    className="btn bg-blue-400 hover:bg-blue-600 text-white whitespace-nowrap"
                  >
                    Send Reset Link
                  </button>
                </div>
              </form>
            </div>
            <div className="pt-5 mt-6 border-t border-gray-200">
              <div className="text-sm">
                Go back to{' '}
                <Link
                  className="font-medium text-indigo-500 hover:text-indigo-600"
                  to="/signin"
                >
                  Log In
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Image */}
        <div
          className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
          aria-hidden="true"
        >
          <img
            className="object-cover object-center w-full h-full"
            src={getAuthImage()}
            width="760"
            height="1024"
            alt="Authentication"
          />
        </div>
      </div>
    </main>
  );
}

export default ResetPassword;
