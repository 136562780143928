/**
 * Functional component for downloading a CSV file.
 */
import { useState } from 'react';
import dateFormat from 'dateformat';

import Modal from './Modal';
import DatePicker from '../ui/DatePicker';
import ConfirmButton from '../buttons/ConfirmButton';
import DropDownFilter from '../ui/DropDownFilter';
import { LocationType } from '../../types';
import { constructCsv } from '../../helpers/csv';
import {
  getDetectionData,
  filterDetections,
  filterRegions,
  joinDetectionAndRegions,
} from '../../helpers/detections';
import { getLocationPreferences } from '../../helpers/location';
import { formatDateForRequest, utcStringToLocalDate } from '../../helpers/time';

export default function DownloadCsvModal({
  isOpen,
  onClose,
  customerLocations,
}: {
  isOpen: boolean;
  onClose: () => void;
  customerLocations: LocationType[];
}) {
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    new Date(),
    new Date(),
  ]);
  const [selectedLocations, setSelectedLocations] = useState<LocationType[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);

  // Default columns
  const DEFAULT_DT_COLUMNS = [
    {
      label: 'Arrival Time',
      key: 'first_seen_datetime_utc',
      type: 'date',
    },
    {
      label: 'Arrival Time (Local)',
      key: 'first_seen_datetime_local',
      type: 'date',
    },
    {
      label: 'Order Time',
      key: 'order',
      type: 'seconds',
    },
    {
      label: 'Pickup Time',
      key: 'leave',
      type: 'seconds',
    },
    {
      label: 'Total Time',
      key: 'time_spent',
      type: 'seconds',
    },
    {
      label: 'Valid Time',
      key: 'valid_time_spent',
      type: 'seconds',
    },
  ];

  // Handle downloading the CSV file
  const handleDownload = async () => {
    setIsLoading(true);
    for (const location of selectedLocations) {
      const data = [];
      const detectionData = await getDetectionData(
        location.id,
        formatDateForRequest(dateRange[0]),
        formatDateForRequest(dateRange[1])
      );
      const filteredDetections = filterDetections(
        detectionData.detection,
        location.has_drive_through
      );
      const filteredRegions = filterRegions(detectionData.regions);
      const joinedData = await joinDetectionAndRegions(
        filteredDetections,
        filteredRegions
      );
      const columns = await getColumns(location, location.has_drive_through);
      joinedData.forEach((row) => {
        const newRow = [
          row.first_seen_datetime_utc,
          dateFormat(
            new Date(
              utcStringToLocalDate(
                row.first_seen_datetime_utc,
                location.time_zone
              )
            ).valueOf(),
            'yyyy-mm-dd HH:MM:ss'
          ),
        ];
        columns.forEach((column) => {
          if (
            column.key !== 'time_spent' &&
            column.key !== 'first_seen_datetime_utc' &&
            column.key !== 'first_seen_datetime_local' &&
            column.key !== 'display_name' &&
            column.key !== 'valid_time_spent' &&
            column.type === 'seconds'
          ) {
            newRow.push(row[column.key]);
          }
        });
        newRow.push(row.time_spent);
        newRow.push(row.valid_time_spent);
        data.push(newRow);
      });
      constructCsv(data, columns);
    }
    onClose();
    setIsLoading(false);
  };

  // Handle getting the columns for the CSV file
  const getColumns = async (
    selectedLocation: LocationType,
    isDriveThru: boolean
  ) => {
    const res = await getLocationPreferences(selectedLocation.id, isDriveThru);
    // Handle custom region display names.
    if (res['regionDisplays'] && res['regionPreferences']) {
      console.log('getColumns case 1');
      const regionDisplays = res['regionDisplays'];
      const regionPreferences = res['regionPreferences'];
      const intersection = regionDisplays.map((regionDisplay) => {
        const match = regionPreferences.find(
          (locationDisplay) => locationDisplay.key === regionDisplay
        );
        if (match) {
          return match;
        }
        return null;
      });

      console.log('intersection', intersection);

      const newColumns = [
        DEFAULT_DT_COLUMNS[0],
        ...intersection.map((col) => {
          return {
            label: col.display_name,
            key: col.key,
            type: 'seconds',
          };
        }),
        // Time spent column
        DEFAULT_DT_COLUMNS[DEFAULT_DT_COLUMNS.length - 2],
        // Valid time spent column
        DEFAULT_DT_COLUMNS[DEFAULT_DT_COLUMNS.length - 1],
      ];
      console.log('Returning columns 1: ', newColumns);
      return newColumns;
      // Programatically determine what columns to display based on the database.
    } else if (res['regionPreferences']) {
      console.log('getColumns case 2');
      const regionPreferences = res['regionPreferences'];
      console.log('regionPreferences', regionPreferences);
      const newColumns = [
        DEFAULT_DT_COLUMNS[0],
        ...regionPreferences.map((col) => {
          return {
            label: col.display_name,
            key: col.key,
            type: 'seconds',
          };
        }),
        // Time spent column
        DEFAULT_DT_COLUMNS[DEFAULT_DT_COLUMNS.length - 2],
        // Valid time spent column
        DEFAULT_DT_COLUMNS[DEFAULT_DT_COLUMNS.length - 1],
      ];
      // TODO: Fix this to be the appropriate columns.
      setIsLoading(true);
      console.log('Returning columns 2: ', newColumns);
      if (isDriveThru) {
        return DEFAULT_DT_COLUMNS;
      } else {
        return newColumns;
      }
      // Default columns.
    } else {
      console.log('getColumns case 3');
      setIsLoading(true);
      console.log('Returning columns 3: ', DEFAULT_DT_COLUMNS);
      return DEFAULT_DT_COLUMNS;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col gap-4 min-w-96">
        <h2 className="divide-y divide-gray-200 font-bold text-lg">
          Download CSV
        </h2>
        {/* Date Selector */}
        <div className="flex flex-col">
          <div className="mb-2">Select Date Range</div>
          <DatePicker
            dateRange={dateRange}
            onChange={(range) => {
              if (range.length === 2) {
                setDateRange(range);
              }
            }}
            mode="range"
          />
        </div>
        {/* Location Selector */}
        <div className="flex flex-col">
          <div className="mb-2">Select Locations</div>
          <DropDownFilter
            options={customerLocations}
            selected={selectedLocations}
            setSelected={setSelectedLocations}
          />
        </div>
        {/* Confirm Button */}
        <ConfirmButton
          text="Download CSV"
          onClick={handleDownload}
          isLoading={isLoading}
          loadingText="Downloading..."
        />
      </div>
    </Modal>
  );
}
