import React, { useEffect, useState, useRef } from "react";
import Transition from "../../utils/Transition.js";

export default function DropDownFilter({
	options,
	selected,
	setSelected,
    filterFunc,
    selectedDisplayFunc,
    optionKey,
    maxSelected=undefined,
}) {

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const trigger = useRef(null);
    const dropdown = useRef(null);
    const inputRef = useRef(null);

    // const [displayOptions, setDisplayOptions] = useState(options);


    useEffect(() => {
		const clickHandler = ({ target }) => {
		if (!dropdown.current) return;
		if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
		setDropdownOpen(false);
		};
		document.addEventListener('click', clickHandler);
		return () => document.removeEventListener('click', clickHandler);
	});

	// close if the esc key is pressed
	useEffect(() => {
		const keyHandler = ({ keyCode }) => {
		if (!dropdownOpen || keyCode !== 27) return;
		setDropdownOpen(false);
		};
		document.addEventListener('keydown', keyHandler);
		return () => document.removeEventListener('keydown', keyHandler);
	});

    // useEffect(() => {
    //     setDisplayOptions(options);
    // }, [options])

    const applyFilter = (event) => {
        // let val = event.target.value.toLowerCase();
        let val = inputRef.current.value.toLowerCase();
        // console.log(val);
        if (val === ''){
            setSelected(options);
        }
        // Filter on address, display_name, and franchise.name
        let matches = options.filter(opt => filterFunc(opt, val));

        if (maxSelected != undefined && matches.length > maxSelected) {
            window.alert(`Only ${maxSelected} can be selected at once`);
            setSelected(matches.slice(0, maxSelected));
            return;
        }

        setSelected(matches);
    }

    // If we don't have a current location or all locations, just have a loader
    if ( (options == undefined) || (options.length == 0) ){
        return (
            <button className='btn justify-between min-w-56 bg-white border-gray-200 hover:border-gray-300 text-gray-500 hover:text-gray-600 focus:outline-none'>
                <div className='h-1 bg-gray-400 animate-pulse rounded w-full'></div>
            </button>
        )
    }

    const isSelected = (opt) => {
        // If no filtering at all, then bingo, we got it
        if (selected == undefined){
            return true;
        }
        return selected.map(x => x[optionKey]).includes(opt[optionKey]);
    }

    const toggleSingleSelected = (opt) => {
        // If nothing selected, select everything, then drop this one
        let toSelect;
        if (selected === undefined){
            toSelect = options.filter(x => x[optionKey] != opt[optionKey])
            if (maxSelected != undefined && toSelect.length > maxSelected) {
                window.alert(`Only ${maxSelected} can be selected at once, please de-select an option before selecting a new one`);
                return;
            }
            setSelected(toSelect);
            return;
        }

        if (selected.length == 0){
            // So not undefined, but everything was unselected, then add just this one
            toSelect = [opt];
            if (maxSelected != undefined && toSelect.length > maxSelected) {
                window.alert(`Only ${maxSelected} can be selected at once, please de-select an option before selecting a new one`);
                return;
            }
            setSelected(toSelect);
            return;
        }

        // If selected has something, then if opt is in it remove, 
        if (selected.map(x => x[optionKey]).includes(opt[optionKey])){
            toSelect = selected.filter(x => x[optionKey] != opt[optionKey]);
        }
        else{
            // if opt not in it, add it
            toSelect = [...selected];
            toSelect.push(opt);
        }
        if (maxSelected != undefined && toSelect.length > maxSelected) {
            window.alert(`Only ${maxSelected} can be selected at once, please de-select an option before selecting a new one`);
            return;
        }
        setSelected(toSelect);
    }


	return (
		<div className="relative">
			<button
				ref={trigger}
				className="btn justify-between min-w-56 bg-white border-gray-200 hover:border-gray-300 text-gray-500 hover:text-gray-600 focus:outline-none"
				aria-label="Select date range"
				aria-haspopup="true"
				onClick={() => setDropdownOpen(!dropdownOpen)}
				aria-expanded={dropdownOpen}>
				<span className="flex items-center">
					{/* <svg className="w-4 h-4 fill-current text-gray-500 flex-shrink-0 mr-2" viewBox="0 0 16 16">
					<path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
				</svg> */}
					<span className="w-4"></span>
					{/* <span>{selectedDisplayFunc(selected)}</span> */}
                    <span>{!selected ? null : selected.length} Selected</span>
				</span>
				<svg
					className="flex-shrink-0 ml-1 fill-current text-gray-400"
					width="11"
					height="7"
					viewBox="0 0 11 7">
					<path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
				</svg>
			</button>
			<Transition
				show={dropdownOpen}
				tag="div"
				className="z-10 absolute top-full right-0 w-full bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
				enter="transition ease-out duration-100 transform"
				enterStart="opacity-0 -translate-y-2"
				enterEnd="opacity-100 translate-y-0"
				leave="transition ease-out duration-100"
				leaveStart="opacity-100"
				leaveEnd="opacity-0">
				<div
					ref={dropdown}
					className="font-medium text-sm text-gray-600 max-h-96 overflow-y-auto"
					onFocus={() => setDropdownOpen(true)}
					onBlur={() => setDropdownOpen(false)}>
                    <form onSubmit={(e) => {e.preventDefault(); applyFilter();}}
                        className='sticky top-0'>    
                        <div className='flex'>
                            <input
                                ref={inputRef}
                                type='text'
                                placeholder='Filter...'
                                className='w-full border-b border-t-0 border-l-0 border-r-0 pb-0.5 border-gray-200 sticky top-0'
                                // onChange={applyFilter}
                                onSubmit={applyFilter}/>
                            <input
                                type='submit'
                                className='btn cursor-pointer hover:bg-green-100'
                                value='Go'
                                />
                        </div>
                    </form>

					{options.map((opt) => {
						return (
							<button
								key={opt.id}
								tabIndex="0"
								className={`flex items-center w-full hover:bg-gray-50 py-1 px-3 cursor-pointer ${
									isSelected(opt) && "text-indigo-500"
								}`}
								onClick={() => {
									// setSelected(option);
                                    // setSelected(opt);
									// setDropdownOpen(false);
                                    // console.log("Clicked", selectedDisplayFunc(opt))
                                    toggleSingleSelected(opt);
								}}>
								<svg
									className={`flex-shrink-0 mr-2 fill-current text-indigo-500 ${
										!isSelected(opt) && "invisible"
									}`}
									width="12"
									height="9"
									viewBox="0 0 12 9">
									<path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
								</svg>
								<span>{selectedDisplayFunc(opt)}</span>
							</button>
						);
					})}
				</div>
			</Transition>
		</div>
	);
}
