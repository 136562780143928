import React, { useState, useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getDaysOrders, getFullDetectionData } from '../../api/orders';
import { getLocationPreferences } from '../../api/firestore';
import { useDataContext } from '../../contexts/DataContext';
import TableLoader from '../dashboard/TableLoader';
import dateFormat from 'dateformat';
import { Link } from 'react-router-dom';
import DropdownMenu from '../actions/DropdownMenu';
import { formatSeconds } from '../../helpers/formatting';
import DetectionOrderRow from './DetectionOrderRow';
import DetectionOrderTable from './DetectionOrderTable';

export default function DetectionOrderWidget({
  customerLocationData,
  franchise,
  location,
  date,
  isDriveThru,
}) {
  // const [location, setLocation] = useState();
  const [matchedData, setMatchedData] = useState();
  const [sortColumn, setSortColumn] = useState('Checkout');
  const [sortDirection, setSortDirection] = useState(1);

  const { routes } = useDataContext();

  const {
    data: orderData,
    refetch,
    isLoading,
    isFetching,
  } = useQuery(
    ['orders', date, isDriveThru, location, routes['ordersTable']],
    getDaysOrders,
    {
      enabled: !!(!!franchise & !!customerLocationData & !!location),
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );

  // const {data: detectionData, refetchDetection, detectionIsLoading, detectionIsFetching} =
  const {
    data: detectionData,
    refetch: refetchDetection,
    isLoading: detectionIsLoading,
    isFetching: detectionIsFetching,
  } = useQuery(
    [
      'fullDetection',
      date.toLocaleDateString('en-GB'),
      isDriveThru,
      location,
      routes['fullDetectionData'],
    ],
    getFullDetectionData,
    {
      enabled: !!(!!franchise & !!customerLocationData & !!location),
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: locationPrefs,
    refetch: refetchLocationPrefs,
    isLoading: prefsIsLoading,
    isFetching: prefsIsFetching,
  } = useQuery(['locationPrefs', location], getLocationPreferences, {
    enabled: !!location,
    retry: 0,
    refetchOnWindowFocus: false,
  });

  const UTCStringToLocalDate = (s, tz_string) => {
    // Split
    let dt = s.split(/[: T-]/).map(parseFloat);

    let d = Date.UTC(dt[0], dt[1] - 1, dt[2], dt[3], dt[4], dt[5]);
    let localString = new Date(d).toLocaleString('en-US', {
      timeZone: tz_string,
    });

    // Now back to a datetimeobject that is tz wrong, but all else good
    let nd = new Date(localString);
    return nd;
  };

  const renderTimeLink = (utcTime) => {
    // REnder the time link
    // let d = UTCStringToLocalDate(utcTime, location.time_zone);
    let d = UTCStringToLocalDate(utcTime, location.time_zone);
    // console.log("Hard coded TIMEZONE TODO")
    let d2 = new Date(d.valueOf()); //1000 = ms/s
    let formatted = dateFormat(d, 'mediumTime');
    let params = {
      dt: dateFormat(d2, 'yyyy-mm-dd') + 'T' + dateFormat(d2, 'HH:MM'),
      lid: 24,
    };
    // console.log("HARD CODED LOCATION ID")

    let u = new URLSearchParams(params);
    let to = '/applications?' + u.toString();
    return (
      <Link
        className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
        to={to}
      >
        {formatted}
      </Link>
    );
  };

  /**
   * Match the deteciton and order data, as well as format the region names niceness
   */
  const matchData = () => {
    // console.log("TODO: Move match key to firebase");
    // console.log(locationPrefs);
    let DT_CHECKOUT_REGION = 'leave'; // probably put this in locationPrefs (on fb)
    let LOBBY_CHECKOUT_REGION = 4;
    if (Object.keys(locationPrefs).includes('dt_checkout_region')) {
      DT_CHECKOUT_REGION = locationPrefs['dt_checkout_region'];
    }
    if (Object.keys(locationPrefs).includes('lobby_checkout_region')) {
      LOBBY_CHECKOUT_REGION = locationPrefs['lobby_checkout_region'];
    }
    // Iterate through each order, and find the detection id with
    // the CHECKOUT_REGION region key

    let detections = detectionData['detections'];
    let regions = detectionData['regions'];
    let combindedData = detections.reduce((agg, cur) => {
      let did = cur.id;
      let matches = regions.filter((x) => x.detection_id == did);
      let dat = {
        ...cur,
        regions: matches,
      };
      agg.push(dat);
      return agg;
    }, []);

    // Now match orders to the detection combined data
    let matchedData = orderData.reduce((agg, cur) => {
      let checkoutTime = new Date(cur.timestamp);
      let match = combindedData.filter((det) => {
        // get desired checkout region
        let regionOfInterest = det.is_drive_through
          ? DT_CHECKOUT_REGION
          : LOBBY_CHECKOUT_REGION;
        // let reg = det.regions.filter(x => x.region == CHECKOUT_REGION);
        let reg = det.regions.filter((x) => x.region == regionOfInterest);

        // if none, no match
        if (reg.length == 0) {
          return false;
        } else {
          reg = reg[0];
        }

        // check times
        let regEnter = new Date(reg.first_seen_datetime_utc);
        let regExit = new Date(reg.first_seen_datetime_utc);
        regExit.setSeconds(regExit.getSeconds() + reg.time_spent);
        // console.log(checkoutTime, regEnter, regExit);
        if (
          regEnter <= checkoutTime &&
          regExit > checkoutTime &&
          det.is_drive_through == cur.is_drive_through
        ) {
          return true;
        } else {
          return false;
        }
      });
      // console.log(match.length);
      let dat = { ...cur, detection: match };
      agg.push(dat);
      return agg;
    }, []);

    return matchedData;
  };

  const columns = {
    Checkout: { key: 'timestamp', type: 'custom' },
    Where: { key: 'order_dest', type: 'text' },
    'Order No.': { key: 'order_no', type: 'text' },
    Cashier: { key: 'cashier', type: 'text' },
    'Payment Type': { key: 'payment_type', type: 'text' },
    'Sub Total': { key: 'sub_total', type: 'dollar' },
    Tax: { key: 'tax_amt', type: 'dollar' },
    Tender: { key: 'tendered_amt', type: 'dollar' },
    Change: { key: 'change_amt', type: 'dollar' },
    Total: { key: 'total', type: 'dollar' },
    'Service Time': { key: 'time_spent', type: 'time' },
  };

  useEffect(() => {
    let hasOrderData = !(isLoading || isFetching || !orderData);
    let hasDetectionData = !(
      detectionIsLoading ||
      detectionIsFetching ||
      !detectionData
    );
    let hasPrefs = !(prefsIsLoading || prefsIsFetching || !locationPrefs);
    if (hasOrderData && hasDetectionData && hasPrefs) {
      setMatchedData(matchData());
    }
  }, [
    isLoading,
    isFetching,
    orderData,
    detectionIsLoading,
    detectionIsFetching,
    detectionData,
    prefsIsLoading,
    prefsIsFetching,
    locationPrefs,
  ]);

  const renderTable = () => {
    // TODO: might play with putting this and the match in a useEffect
    let hasOrderData = !(isLoading || isFetching || !orderData);
    let hasDetectionData = !(
      detectionIsLoading ||
      detectionIsFetching ||
      !detectionData
    );
    let hasPrefs = !(prefsIsLoading || prefsIsFetching || !locationPrefs);

    if (!hasOrderData || !hasDetectionData || !hasPrefs || !matchedData) {
      return <TableLoader columns={Object.keys(columns)} />;
    }

    // let matchedData = matchData();
    // console.log(matchedData);
    return (
      <DetectionOrderTable
        columns={columns}
        orderData={orderData}
        detectionData={detectionData}
        locationPrefs={locationPrefs}
        matchedData={matchedData}
        location={location}
        sortColumn={sortColumn}
        setSortColumn={setSortColumn}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
      />
    );
  };

  let total;
  if (!isLoading & !isFetching & !!orderData) {
    total = orderData.reduce((acc, cur) => acc + cur.total, 0);
    total = parseFloat(total.toFixed(2));
  }

  return (
    <>
      <div className="flex flex-col col-span-full bg-white shadow-lg rounded-lg border border-gray-200 mb-8">
        <header className="px-5 py-4 border-b border-gray-100">
          <div>
            <h2 className="font-semibold text-gray-800 inline">
              Orders
              {/* {
								!!total ? (
									<span className=' text-gray-500'>&nbsp;(Total: ${total.toLocaleString()})</span>
								) : null
							} */}
            </h2>

            <div className="flex flex-wrap items-center justify-center float-right">
              {/* {renderDropdown()} */}
              <button
                className="button bg-transparent hover:bg-blue-400
								text-blue-400 hover:text-white rounded-full
								border border-blue-400 py-1 px-2 text-xs ml-2"
                style={{ maxHeight: '2.5em' }}
                onClick={() => {
                  refetch();
                  refetchDetection();
                  refetchLocationPrefs();
                }}
              >
                Refresh
              </button>
            </div>
          </div>
        </header>
        <div className="p-3">
          <div className="overflow-x-auto">{renderTable()}</div>
        </div>
      </div>
    </>
  );
}
