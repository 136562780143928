import React from "react";

function SettingsSidebar({ active, setActive }) {
	return (
		<div className="flex flex-nowrap overflow-x-scroll no-scrollbar md:block md:overflow-auto px-3 py-6 border-b md:border-b-0 md:border-r border-gray-200 md:w-60 md:space-y-3">
			{/* Group 1 */}
			<div>
				<div className="text-xs font-semibold text-gray-400 uppercase mb-3">
					Business settings
				</div>
				<ul className="flex flex-nowrap md:block mr-3 md:mr-0">
					<li className="mr-0.5 md:mr-0 md:mb-0.5">
						<a
							className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${
								active == "account" ? "bg-blue-50" : ""
							}`}
							href="#0"
							onClick={() => setActive("account")}>
							<svg
								className={`w-4 h-4 flex-shrink-0 fill-current ${
									active == "account" ? "text-blue-500" : "text-gray-500"
								} mr-2`}
								viewBox="0 0 16 16">
								<path d="M12.311 9.527c-1.161-.393-1.85-.825-2.143-1.175A3.991 3.991 0 0012 5V4c0-2.206-1.794-4-4-4S4 1.794 4 4v1c0 1.406.732 2.639 1.832 3.352-.292.35-.981.782-2.142 1.175A3.942 3.942 0 001 13.26V16h14v-2.74c0-1.69-1.081-3.19-2.689-3.733zM6 4c0-1.103.897-2 2-2s2 .897 2 2v1c0 1.103-.897 2-2 2s-2-.897-2-2V4zm7 10H3v-.74c0-.831.534-1.569 1.33-1.838 1.845-.624 3-1.436 3.452-2.422h.436c.452.986 1.607 1.798 3.453 2.422A1.943 1.943 0 0113 13.26V14z" />
							</svg>
							<span
								className={`text-sm font-medium ${
									active == "account" ? "text-blue-500" : "text-gray-500"
								}`}>
								My account
							</span>
						</a>
					</li>

					<li className="mr-0.5 md:mr-0 md:mb-0.5">
						<a
							className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${
								active == "locations" ? "bg-blue-50" : ""
							}`}
							href="#0"
							onClick={() => setActive("locations")}>



							<svg xmlns="http://www.w3.org/2000/svg" className="mr-2 icon icon-tabler icon-tabler-map-2" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke={`${active === 'locations' ? '#3B82F6' : '#6B7280'}`} fill="none" stroke-linecap="round" stroke-linejoin="round">
								<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
								<line x1="18" y1="6" x2="18" y2="6.01" />
								<path d="M18 13l-3.5 -5a4 4 0 1 1 7 0l-3.5 5" />
								<polyline points="10.5 4.75 9 4 3 7 3 20 9 17 15 20 21 17 21 15" />
								<line x1="9" y1="4" x2="9" y2="17" />
								<line x1="15" y1="15" x2="15" y2="20" />
							</svg>


							<span
								className={`text-sm font-medium ${
									active == "locations" ? "text-blue-500" : "text-gray-600"
								} hover:text-gray-700`}>
								Locations
							</span>
						</a>
					</li>
					<li className="mr-0.5 md:mr-0 md:mb-0.5">
						<a
							className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${
								active == "drivethru" ? "bg-blue-50" : ""
							}`}
							href="#0"
							onClick={() => setActive("drivethru")}>
							{/* <svg xmlns="http://www.w3.org/2000/svg" className="mr-2 icon icon-tabler icon-tabler-car" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2" stroke={`${active === 'drivethru' ? '#3B82F6' : '#6B7280'}`} fill="none" strokeLinecap="round" strokeLinejoin="round">
								<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
								<circle cx="7" cy="17" r="2" />
								<circle cx="17" cy="17" r="2" />
								<path d="M5 17h-2v-6l2 -5h9l4 5h1a2 2 0 0 1 2 2v4h-2m-4 0h-6m-6 -6h15m-6 0v-5" />
							</svg> */}
							<svg xmlns="http://www.w3.org/2000/svg" className="mr-2 icon icon-tabler icon-tabler-device-desktop" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2" stroke={`${active === 'drivethru' ? '#3B82F6' : '#6B7280'}`} fill="none" strokeLinecap="round" strokeLinejoin="round">
								<path stroke="none" d="M0 0h24v24H0z" fill="none"/>
								<rect x="3" y="4" width="18" height="12" rx="1" />
								<line x1="7" y1="20" x2="17" y2="20" />
								<line x1="9" y1="16" x2="9" y2="20" />
								<line x1="15" y1="16" x2="15" y2="20" />
							</svg>
							<span
								className={`text-sm font-medium ${
									active == "drivethru" ? "text-blue-500" : "text-gray-500"
								}`}>
								Monitors
							</span>
						</a>
					</li>
				</ul>
			</div>
		</div>
	);
}

export default SettingsSidebar;
